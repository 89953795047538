/**
 * Defines the available subscription tiers in the application
 * @public
 */
export const SUBSCRIPTION_TIERS = {
  FREE: 'free',
  PRO: 'pro',
  ENTERPRISE: 'enterprise',
} as const;

/**
 * Type representing a valid subscription tier
 * @public
 */
export type SubscriptionTier = typeof SUBSCRIPTION_TIERS[keyof typeof SUBSCRIPTION_TIERS];

/**
 * Type guard to check if a value is a valid subscription tier
 * @param value - The value to check
 * @returns True if the value is a valid subscription tier
 * @public
 */
export function isSubscriptionTier(value: unknown): value is SubscriptionTier {
  return typeof value === 'string' && Object.values(SUBSCRIPTION_TIERS).includes(value as SubscriptionTier);
} 