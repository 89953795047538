import { SUBSCRIPTION_TIERS, type SubscriptionTier } from '@attrove/shared-types';

export const DB = {
  // Auth and Users
  USERS: 'users',
  USER_PROFILES: 'user_profiles',
  USER_GOALS: 'user_goals',
  
  // Organizations and Teams
  ORGANIZATIONS: 'organizations',
  ORGANIZATION_MEMBERS: 'organization_members',
  TEAMS: 'teams',
  TEAM_MEMBERS: 'team_members',
  
  // Billing and Subscriptions
  CUSTOMERS: 'customers',
  PRODUCTS: 'products',
  PRICES: 'prices',
  SUBSCRIPTIONS: 'subscriptions',
  
  // Integrations
  INTEGRATIONS: 'integrations',
  INTEGRATIONS_CREDENTIALS: 'integrations_credentials',
  
  // Communication
  MESSAGES: 'messages',
  CONVERSATIONS: 'conversations',
  THREADS: 'threads',
  REPORTS: 'reports',
  TAGS: 'tag',

  // Calendar and Events
  CALENDARS: 'calendars',
  EVENTS: 'events',
  MEETINGS: 'meetings',
  
  // Entity Management
  ENTITY: 'entity',
  ENTITY_IDENTITY: 'entity_identity',
  ENTITY_RELATION: 'entity_relation',
  
  // Features and Settings
  FEATURE_FLAGS: 'feature_flags',
  
  // Notifications
  NOTIFICATIONS: 'notifications',
  NOTIFICATION_DELIVERIES: 'notification_deliveries',
  USER_NOTIFICATION_PREFERENCES: 'user_notification_preferences',
  NOTIFICATION_CHANNELS: 'notification_channels',
  NOTIFICATION_TYPES: 'notification_types',
} as const;

// RPC function names
export const RPC = {
  // Existing RPCs
  INSERT_INTEGRATION: 'insert_integration',
  
  // New RPCs
  CHECK_TEAM_MEMBER_LIMIT: 'check_team_member_limit',
  CHECK_INTEGRATION_LIMIT: 'check_integration_limit',
} as const;

// Feature flag names (must match database values)
export const FEATURES = {
  VOICE_ASSISTANT: 'voice_assistant',
  OUTPUT_GENERATION: 'output_generation',
  TEAM_FEATURES: 'team_features',
  PRIORITY_SUPPORT: 'priority_support',
  CUSTOM_INTEGRATIONS: 'custom_integrations',
  DEDICATED_SUPPORT: 'dedicated_support',
  CUSTOM_SLA: 'custom_sla',
} as const;

// Team roles
export const TEAM_ROLES = {
  OWNER: 'owner',
  VIEWER: 'viewer',
  MEMBER: 'member',
} as const;

// Organization roles
export const ORGANIZATION_ROLES = {
  ADMIN: 'admin',
  MEMBER: 'member',
} as const;

// Note: Subscription tier is determined by:
// 1. If no subscription exists -> FREE tier
// 2. If subscription exists -> Check associated price.metadata.tier
// This is handled in the subscription service logic

// Integration types
export const INTEGRATION_TYPES = {
  BASIC: 'basic',
  ADVANCED: 'advanced',
  CUSTOM: 'custom',
} as const; 