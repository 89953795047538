import React, { useState } from 'react';
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import {
  GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY,
  getIntegrationsWithCredById,
  GET_MEETINGS_BY_INTEGRATION_ID_QUERY_KEY,
  getMeetingsByIntegrationId,
} from "@attrove/service-supabase";
import { Icons, Card, Skeleton, Button, ScrollArea } from "@attrove/ui-shadcn";
import { useQuery } from "@tanstack/react-query";
import { 
  Loader2, Calendar, Clock, Video, ChevronLeft, ChevronRight, 
  Users, ExternalLink, MessageSquare 
} from "lucide-react";
import { IntegrationActions } from "../integration-actions";

type Attendee = {
  id: number | null;
  name: string | null;
  email: string | null;
  organizer: boolean | null;
  response_status: string | null;
  comment: string | null;
};

interface Meeting {
  id: number;
  file_id_3p: string;
  integration_id: number;
  name: string;
  mime_type: string;
  created_at: string;
  modified_at: string;
  content: string | null;
  drive_url: string;
  event_id_3p: string | null;
  event_id: number | null;
  attendees: Attendee[] | null;
  report_id: number | null;
  rundown: string | null;
  created_at_system: string;
  updated_at: string;
}

const MeetingItem: React.FC<{ meeting: Meeting }> = ({ meeting }) => {
  const meetingDate = new Date(meeting.modified_at);
  const hasTranscript = meeting.content !== null;
  
  return (
    <div className="group grid grid-cols-[1fr_auto] gap-4 items-start p-4 bg-card rounded-lg border transition-all duration-200 hover:bg-accent/5 hover:shadow-sm">
      <div className="min-w-0">
        <div className="flex items-center gap-3 mb-2">
          <div className="flex-shrink-0 p-2 rounded-full bg-primary/10">
            <Video className="w-4 h-4 text-primary" />
          </div>
          <div className="min-w-0">
            <h3 className="font-semibold text-foreground group-hover:text-primary transition-colors truncate">
              {meeting.name || 'Untitled Meeting'}
            </h3>
            <div className="flex flex-wrap items-center gap-3 mt-1 text-sm text-muted-foreground">
              <div className="flex items-center">
                <Calendar className="w-3.5 h-3.5 mr-1.5" />
                <span>{meetingDate.toLocaleDateString(undefined, { 
                  month: 'short', 
                  day: 'numeric', 
                  year: 'numeric' 
                })}</span>
              </div>
              <div className="flex items-center">
                <Clock className="w-3.5 h-3.5 mr-1.5" />
                <span>{meetingDate.toLocaleTimeString(undefined, { 
                  hour: 'numeric', 
                  minute: '2-digit'
                })}</span>
              </div>
              {meeting.attendees && meeting.attendees.length > 0 && (
                <div className="flex items-center">
                  <Users className="w-3.5 h-3.5 mr-1.5" />
                  <span>{meeting.attendees.length} participants</span>
                </div>
              )}
              {hasTranscript && (
                <div className="flex items-center">
                  <MessageSquare className="w-3.5 h-3.5 mr-1.5" />
                  <span>Transcript available</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <a 
        href={meeting.drive_url} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="flex items-center gap-2 px-3 py-1.5 text-sm bg-primary text-primary-foreground rounded-md hover:bg-primary/90 transition-colors duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
      >
        <ExternalLink className="w-3.5 h-3.5" />
        View Recording
      </a>
    </div>
  );
};

const PaginationControls: React.FC<{
  currentPage: number;
  totalPages: number;
  startIndex: number;
  endIndex: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}> = ({ currentPage, totalPages, startIndex, endIndex, totalItems, onPageChange }) => (
  <div className="flex items-center justify-between px-4 py-3 border-t bg-card/50">
    <div className="flex-1 text-sm text-muted-foreground">
      Showing <span className="font-medium">{startIndex + 1}</span> to{" "}
      <span className="font-medium">{Math.min(endIndex, totalItems)}</span> of{" "}
      <span className="font-medium">{totalItems}</span> recordings
    </div>
    <div className="flex items-center gap-2">
      <Button
        variant="outline"
        size="icon"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="h-8 w-8"
      >
        <ChevronLeft className="h-4 w-4" />
      </Button>
      <div className="text-sm text-muted-foreground px-2">
        Page {currentPage} of {totalPages}
      </div>
      <Button
        variant="outline"
        size="icon"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="h-8 w-8"
      >
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  </div>
);

export const MeetingDetail: React.FC<{ integrationId: number }> = ({ integrationId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const { data: integration, isLoading: integrationLoading } = useQuery({
    queryKey: [GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY, integrationId],
    queryFn: () => getIntegrationsWithCredById(supabase, Number(integrationId)),
  });

  const { data: meetings, isLoading: meetingsLoading } = useQuery({
    queryKey: [GET_MEETINGS_BY_INTEGRATION_ID_QUERY_KEY, integrationId],
    queryFn: () => getMeetingsByIntegrationId(supabase, integrationId),
  });

  if (integrationLoading || meetingsLoading) {
    return (
      <div className="pt-6">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <Skeleton className="h-8 w-48 mb-4" />
          <div className="space-y-4">
            <Skeleton className="h-32 w-full" />
            <Skeleton className="h-24 w-full" />
            <Skeleton className="h-24 w-full" />
          </div>
        </div>
      </div>
    );
  }

  if (!integration) return null;

  const GoogleMeet = Icons["google_meet"];
  const sortedMeetings = meetings ? 
    [...meetings].sort((a, b) => new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime()) 
    : [];

  // Pagination calculations
  const totalPages = Math.max(1, Math.ceil((sortedMeetings?.length || 0) / ITEMS_PER_PAGE));
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentMeetings = sortedMeetings.slice(startIndex, endIndex);

  const completedTranscripts = sortedMeetings.filter(m => m.content !== null).length;
  const pendingTranscripts = sortedMeetings.filter(m => m.content === null).length;

  return (
    <div className="pt-6">
      {/* Header Section */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 mb-8">
        <div className="flex items-center gap-4 mb-6">
          <div className="h-12 w-12 rounded-lg bg-primary/5 flex items-center justify-center">
            <GoogleMeet className="w-8 h-8 text-primary" />
          </div>
          <div>
            <h1 className="text-2xl font-semibold">Google Meet Integration</h1>
            <p className="text-muted-foreground">
              Connected as {integration.name}
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="grid grid-cols-1 gap-6">
          {/* Integration Status Card */}
          <Card className="p-6">
            <div className="flex justify-between items-start mb-6">
              <h2 className="text-lg font-medium">Integration Status</h2>
              <div className={`px-2 py-1 rounded-full text-xs ${integration.is_active ? 'bg-green-500/10 text-green-500' : 'bg-yellow-500/10 text-yellow-500'}`}>
                {integration.is_active ? 'Active' : 'Inactive'}
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center gap-2 text-muted-foreground">
                <Users className="w-4 h-4" />
                <span>Account: {integration.name}</span>
              </div>
              <div className="flex items-center gap-2 text-muted-foreground">
                <Video className="w-4 h-4" />
                <span>{sortedMeetings.length.toLocaleString()} total recordings</span>
              </div>
              <div className="flex items-center gap-2 text-muted-foreground">
                <MessageSquare className="w-4 h-4" />
                <span>{completedTranscripts} transcripts ready{pendingTranscripts > 0 ? `, ${pendingTranscripts} pending` : ''}</span>
              </div>
              
              {integration.last_synced_at && (
                <div className="flex items-center gap-2 text-muted-foreground">
                  {integration.pending_sync ? (
                    <div className="flex items-center gap-2">
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Currently syncing...</span>
                    </div>
                  ) : (
                    <>
                      <Clock className="w-4 h-4" />
                      <span>Last synced {toTimeAgo(integration.last_synced_at)}</span>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="mt-6">
              <IntegrationActions integration={integration} />
            </div>
          </Card>

          {/* Meetings List Card */}
          <Card className="p-6">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-lg font-medium">Meeting Recordings</h2>
              <span className="text-sm text-muted-foreground">
                {sortedMeetings.length} total recordings
              </span>
            </div>

            <ScrollArea className="h-[calc(100vh-460px)]">
              <div className="space-y-3">
                {sortedMeetings.length > 0 ? (
                  currentMeetings.map((meeting) => (
                    <MeetingItem key={meeting.id} meeting={meeting} />
                  ))
                ) : (
                  <div className="flex flex-col items-center justify-center py-12 text-muted-foreground">
                    <div className="bg-muted/30 p-4 rounded-full mb-4">
                      <Video className="w-8 h-8" />
                    </div>
                    <p className="font-medium mb-1">No recordings yet</p>
                    <p className="text-sm">Your meeting recordings will appear here once they're available</p>
                  </div>
                )}
              </div>
            </ScrollArea>

            {sortedMeetings.length > ITEMS_PER_PAGE && (
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                startIndex={startIndex}
                endIndex={endIndex}
                totalItems={sortedMeetings.length}
                onPageChange={setCurrentPage}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};