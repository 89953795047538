import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import {
  GET_CONVERSATIONS_FOR_INTEGRATION_QUERY_KEY,
  getConversationsForIntegration,
  updateConversationPreferences,
  MESSAGES_COUNT_QUERY_KEY,
  getMessageCountForIntegration,
  GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY,
  getIntegrationsWithCredById,
  type Integration
} from "@attrove/service-supabase";
import { Checkbox, Icons, Card, Skeleton } from "@attrove/ui-shadcn";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Loader2, Users, MessageSquare, Building2 } from "lucide-react";
import { IntegrationActions } from "../integration-actions";

// Helper to extract workspace info from integration name
const getWorkspaceInfo = (name: string) => {
  const match = name.match(/^(.+?) \((.+?)\.slack\.com\) - (.+)$/);
  if (match) {
    return {
      workspaceName: match[1],
      workspaceDomain: match[2],
      userName: match[3]
    };
  }
  return null;
};

export const ChatDetail: React.FC<{ integrationId: number }> = ({ integrationId }) => {
    const { data: integration, isLoading } = useQuery({
        queryKey: [GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY, integrationId],
        queryFn: () => getIntegrationsWithCredById(supabase, Number(integrationId)),
    });

    const { data: conversations, isLoading: isConversationsLoading } = useQuery({
        queryKey: [GET_CONVERSATIONS_FOR_INTEGRATION_QUERY_KEY, integrationId],
        queryFn: () => getConversationsForIntegration(supabase, integrationId),
    });

    const { data: messageCount, isLoading: messagesLoading } = useQuery({
        queryKey: [MESSAGES_COUNT_QUERY_KEY, integrationId],
        queryFn: () => getMessageCountForIntegration(supabase, integrationId),
    });

    const toggleImportMessages = useMutation({
        mutationFn: async ({ conversationId, importMessages }: { conversationId: number; importMessages: boolean }) => {
            return await updateConversationPreferences(supabase, [{ id: conversationId, import_messages: importMessages }]);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [GET_CONVERSATIONS_FOR_INTEGRATION_QUERY_KEY, integrationId] });
        },
    });

    if (isLoading || messagesLoading) {
        return (
            <div className="pt-6">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                    <Skeleton className="h-8 w-48 mb-4" />
                    <Skeleton className="h-32 w-full" />
                </div>
            </div>
        );
    }
    
    if (!integration || !messageCount) return null;

    const integrationWithoutConvs = integration;
    const workspaceInfo = getWorkspaceInfo(integration.name);
    const SlackIcon = Icons["slack"];

    return (
        <div className="pt-6">
            {/* Header Section */}
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 mb-8">
                <div className="flex items-center gap-4 mb-6">
                    <div className="h-12 w-12 rounded-lg bg-primary/5 flex items-center justify-center">
                        <SlackIcon className="w-8 h-8 text-primary" />
                    </div>
                    <div>
                        <h1 className="text-2xl font-semibold">Slack Integration</h1>
                        {workspaceInfo && (
                            <p className="text-muted-foreground">
                                Connected to {workspaceInfo.workspaceName}
                            </p>
                        )}
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Integration Status Card */}
                    <Card className="p-6">
                        <div className="flex justify-between items-start mb-6">
                            <h2 className="text-lg font-medium">Integration Status</h2>
                            <div className={`px-2 py-1 rounded-full text-xs ${integration.is_active ? 'bg-green-500/10 text-green-500' : 'bg-yellow-500/10 text-yellow-500'}`}>
                                {integration.is_active ? 'Active' : 'Inactive'}
                            </div>
                        </div>

                        <div className="space-y-4">
                            {workspaceInfo && (
                                <>
                                    <div className="flex items-center gap-2 text-muted-foreground">
                                        <Building2 className="w-4 h-4" />
                                        <span>Workspace: {workspaceInfo.workspaceName}</span>
                                    </div>
                                    <div className="flex items-center gap-2 text-muted-foreground">
                                        <Users className="w-4 h-4" />
                                        <span>Connected as: {workspaceInfo.userName}</span>
                                    </div>
                                </>
                            )}
                            <div className="flex items-center gap-2 text-muted-foreground">
                                <MessageSquare className="w-4 h-4" />
                                <span>{messageCount.toLocaleString()} messages imported</span>
                            </div>
                            
                            {integration.last_synced_at && (
                                <div className="flex items-center gap-2 text-muted-foreground">
                                    {integration.pending_sync ? (
                                        <div className="flex items-center gap-2">
                                            <Loader2 className="w-4 h-4 animate-spin" />
                                            <span>Currently syncing...</span>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="w-4 h-4 flex items-center justify-center">
                                                <div className="w-2 h-2 rounded-full bg-green-500" />
                                            </div>
                                            <span>Last synced {toTimeAgo(integration.last_synced_at)}</span>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="mt-6">
                            <IntegrationActions integration={integrationWithoutConvs} />
                        </div>
                    </Card>

                    {/* Conversations Card */}
                    <Card className="p-6">
                        <h2 className="text-lg font-medium mb-6">Conversation Settings</h2>
                        {isConversationsLoading ? (
                            <div className="space-y-4">
                                <Skeleton className="h-6 w-full" />
                                <Skeleton className="h-6 w-full" />
                                <Skeleton className="h-6 w-full" />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                {conversations?.map((conversation) => (
                                    <div key={conversation.id} className="flex items-start gap-3 p-2 rounded-lg hover:bg-accent/50 transition-colors">
                                        <Checkbox
                                            id={`conversation-${conversation.id}`}
                                            checked={conversation.import_messages}
                                            onCheckedChange={(checked: boolean) => 
                                                toggleImportMessages.mutate({ 
                                                    conversationId: conversation.id, 
                                                    importMessages: checked 
                                                })
                                            }
                                            disabled={conversation.conversation_type === 'channel'}
                                            className="mt-1"
                                        />
                                        <div className="flex-1 min-w-0">
                                            <label 
                                                htmlFor={`conversation-${conversation.id}`}
                                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                            >
                                                {conversation.title}
                                            </label>
                                            <p className="text-sm text-muted-foreground mt-1">
                                                {conversation.conversation_type === 'channel' ? '#' : ''}
                                                {conversation.conversation_type}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Card>
                </div>
            </div>
        </div>
    );
};