import { useNavigate } from "react-router-dom";
import { Button, Alert, AlertDescription } from "@attrove/ui-shadcn";
import { Plus, Loader2, Puzzle } from "lucide-react";
import { IntegrationCard } from "@attrove/attrove-ui/features/integrations/integration-card";
import { useSubscriptionFeatures } from '../../../../context/SubscriptionContext';
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";

const EmptyStateIllustration = ({ icon: Icon }: { icon: React.ElementType }) => (
  <div className="relative">
    <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-primary/5 blur-xl rounded-full" />
    <div className="relative bg-gradient-to-b from-background to-background/80 rounded-full p-4 border shadow-sm">
      <Icon className="w-12 h-12 text-primary" />
    </div>
  </div>
);

export default function Integrations() {
  const navigate = useNavigate();
  const { features } = useSubscriptionFeatures();
  const { maxIntegrations } = features;

  const { data: integrations, isLoading, error } = useQuery({
    queryKey: ['integrations'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('integrations')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (error) throw error;
      return data;
    }
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>
          Failed to load integrations. Please try again later.
        </AlertDescription>
      </Alert>
    );
  }

  const hasUnlimitedIntegrations = maxIntegrations === -1;
  const canAddMoreIntegrations = hasUnlimitedIntegrations || !integrations || integrations.length < maxIntegrations;

  return (
    <div className="container mx-auto py-6 space-y-8">
      <div className="flex flex-col gap-4">
        <div className="flex items-start justify-between">
          <div>
            <h1 className="text-3xl font-bold">Integrations</h1>
            <p className="text-muted-foreground mt-1">
              Manage and monitor your connected apps.
            </p>
          </div>
          <Button 
            onClick={() => navigate('/integrations/new')}
            disabled={!canAddMoreIntegrations}
            title={!canAddMoreIntegrations ? `You've reached your limit of ${maxIntegrations} integrations` : undefined}
            className="shrink-0"
          >
            <Plus className="mr-2 h-4 w-4" /> Add Integration
          </Button>
        </div>

        <div className="h-px bg-gradient-to-r from-transparent via-border to-transparent" />
      </div>

      {integrations?.length === 0 ? (
        <div className="text-center py-16 px-4">
          <div className="flex justify-center mb-6">
            <EmptyStateIllustration icon={Puzzle} />
          </div>
          <h3 className="text-lg font-semibold mb-2">No apps connected yet</h3>
          <p className="text-muted-foreground mb-6 max-w-md mx-auto">
            Connect your work apps to start getting AI-powered insights and save time on your daily tasks.
          </p>
          {canAddMoreIntegrations && (
            <Button onClick={() => navigate('/integrations/new')} size="lg">
              <Plus className="mr-2 h-4 w-4" /> Add Your First Integration
            </Button>
          )}
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {integrations?.map((integration) => (
              <IntegrationCard
                key={integration.id}
                integration={integration}
              />
            ))}
          </div>

          <div className="flex items-center justify-between pt-6 border-t">
            <p className="text-sm text-muted-foreground">
              {hasUnlimitedIntegrations ? (
                `Using ${integrations?.length || 0} integrations (unlimited available)`
              ) : (
                `Using ${integrations?.length || 0} of ${maxIntegrations} available integrations`
              )}
            </p>
          </div>
        </>
      )}
    </div>
  );
}
