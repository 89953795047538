import { createContext, useContext, ReactNode } from 'react';
import { useSubscription } from '../hooks/useSubscription';
import { 
  type SubscriptionTier,
  type FeatureName
} from '@attrove/shared-types';
import { useQuery } from '@tanstack/react-query';
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { FEATURES } from '@attrove/service-supabase';

interface FeatureAccess {
  canAccessTeams: boolean;
  canAccessOrganizations: boolean;
  canAccessAdvancedIntegrations: boolean;
  canAccessCustomIntegrations: boolean;
  maxIntegrations: number;
  maxTeamMembers: number;
  hasCustomSupport: boolean;
  hasAdvancedAnalytics: boolean;
}

interface SubscriptionContextType {
  currentTier: SubscriptionTier;
  isPro: boolean;
  isEnterprise: boolean;
  features: FeatureAccess;
  checkFeatureAccess: (feature: string) => boolean;
  showUpgradeDialog: (feature: string) => void;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export function SubscriptionProvider({ children }: { children: ReactNode }) {
  const axiosClient = useContext(AxiosClientContext);
  const { currentTier, isPro, isEnterprise, isLoading: subscriptionLoading } = useSubscription();

  const { data: featureData, isLoading: featuresLoading } = useQuery({
    queryKey: ['subscription-features'],
    queryFn: async () => {
      const { data } = await axiosClient.get('/api/stripe/features');
      return data as {
        features: FeatureName[];
        limits: {
          maxIntegrations: number;
          maxTeamMembers: number;
          maxMessages: number;
        };
        tier: SubscriptionTier;
      };
    },
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
    refetchOnWindowFocus: false,
  });

  const features: FeatureAccess = {
    canAccessTeams: featureData?.features?.includes(FEATURES.TEAM_FEATURES) ?? true,
    canAccessOrganizations: featureData?.features?.includes(FEATURES.ORGANIZATION_FEATURES) ?? isEnterprise,
    canAccessAdvancedIntegrations: featureData?.features?.includes(FEATURES.ADVANCED_INTEGRATIONS) ?? true,
    canAccessCustomIntegrations: featureData?.features?.includes(FEATURES.CUSTOM_INTEGRATIONS) ?? isEnterprise,
    maxIntegrations: featureData?.limits?.maxIntegrations ?? 5,
    maxTeamMembers: featureData?.limits?.maxTeamMembers ?? 0,
    hasCustomSupport: featureData?.features?.includes(FEATURES.DEDICATED_SUPPORT) ?? isEnterprise,
    hasAdvancedAnalytics: featureData?.features?.includes(FEATURES.ADVANCED_ANALYTICS) ?? (isPro || isEnterprise),
  };

  const checkFeatureAccess = (feature: string): boolean => {
    if (!featureData?.features) return true; // Default to allowing access if data not loaded
    
    const featureMap: Record<string, FeatureName> = {
      'teams': FEATURES.TEAM_FEATURES,
      'organizations': FEATURES.ORGANIZATION_FEATURES,
      'advanced_integrations': FEATURES.ADVANCED_INTEGRATIONS,
      'custom_integrations': FEATURES.CUSTOM_INTEGRATIONS,
      'custom_support': FEATURES.DEDICATED_SUPPORT,
      'advanced_analytics': FEATURES.ADVANCED_ANALYTICS,
    };

    const backendFeature = featureMap[feature];
    if (!backendFeature) return true; // Unknown features default to allowed

    return featureData.features.includes(backendFeature);
  };

  const showUpgradeDialog = (feature: string) => {
    // TODO: Implement upgrade dialog with specific feature messaging
    console.log(`Upgrade needed for feature: ${feature}`);
  };

  if (subscriptionLoading || featuresLoading) {
    return null; // or a loading spinner
  }

  return (
    <SubscriptionContext.Provider 
      value={{
        currentTier,
        isPro,
        isEnterprise,
        features,
        checkFeatureAccess,
        showUpgradeDialog,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}

export const useSubscriptionFeatures = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscriptionFeatures must be used within a SubscriptionProvider');
  }
  return context;
}; 