// import { NewIntegrationCard } from '@/attrove/attrove-ui/components/integrations/new-integration-card';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GmailIntegration } from "@attrove/attrove-ui/components/integrations/gmail";
import { GoogleCalendarIntegration } from "@attrove/attrove-ui/components/integrations/google-calendar";
import { GoogleMeetIntegration } from "@attrove/attrove-ui/components/integrations/google-meet";
import { OutlookIntegration } from "@attrove/attrove-ui/components/integrations/outlook";
import { SlackIntegration } from "@attrove/attrove-ui/components/integrations/slack";
import { useSubscriptionFeatures } from '../../../../context/SubscriptionContext';
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { Alert, AlertDescription } from "@attrove/ui-shadcn";
import { ArrowLeft } from "lucide-react";
import { Button } from "@attrove/ui-shadcn";

export default function NewIntegration() {
  const navigate = useNavigate();
  const { features } = useSubscriptionFeatures();
  const { maxIntegrations } = features;

  const { data: existingIntegrations, isLoading } = useQuery({
    queryKey: ['integrations'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('integrations')
        .select('*');
      
      if (error) throw error;
      return data;
    }
  });

  const canAddMoreIntegrations = maxIntegrations === -1 || !existingIntegrations || existingIntegrations.length < maxIntegrations;

  // Redirect if user has reached their integration limit
  useEffect(() => {
    if (!isLoading && !canAddMoreIntegrations) {
      navigate('/integrations');
    }
  }, [isLoading, canAddMoreIntegrations, navigate]);

  if (!canAddMoreIntegrations) {
    return null; // Will redirect via useEffect
  }

  return (
    <div className="container mx-auto p-6">
      <div className="flex items-center gap-4 mb-6">
        <Button variant="ghost" size="icon" onClick={() => navigate('/integrations')}>
          <ArrowLeft className="h-4 w-4" />
        </Button>
        <div>
          <h1 className="text-2xl font-bold">Add New Integration</h1>
          <p className="text-muted-foreground">
            Connect more apps to capture more of your work context.
          </p>
        </div>
      </div>

      {existingIntegrations?.length === maxIntegrations - 1 && (
        <Alert className="mb-6">
          <AlertDescription>
            You can add one more integration. After this, you'll need to upgrade your plan for additional integrations.
          </AlertDescription>
        </Alert>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <GmailIntegration />
        <SlackIntegration />
        <OutlookIntegration />
        <GoogleCalendarIntegration />
        <GoogleMeetIntegration />
      </div>
    </div>
  );
}
