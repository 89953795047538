import { Card, CardContent, Button } from "@attrove/ui-shadcn";
import { useCallback, useState, memo, useEffect } from "react";
import { CurrentGoalInput } from "@attrove/attrove-ui/components/profile/current-goal";
import { updateUserGoal } from "@attrove/service-supabase";
import { UserWithEmail, UserGoal } from "@attrove/service-supabase";
import { useMutation, useQueryClient, MutationStatus } from "@tanstack/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { Clock, Save, Check } from "lucide-react";

interface GoalSettingsProps {
  user: UserWithEmail;
}

const formatDate = (dateStr: string) => {
  // Split the date string into parts
  const [year, month, day] = dateStr.split('-').map(num => parseInt(num, 10));
  // Create date using local timezone (month is 0-based in JS Date)
  const date = new Date(year, month - 1, day);
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`; // Returns YYYY-MM-DD format
};

export const GoalSettings = memo(({ user }: GoalSettingsProps) => {
  const queryClient = useQueryClient();
  const [goal, setGoal] = useState(user.latest_goal?.goal_text || "");
  const [isDirty, setIsDirty] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const currentDate = getCurrentDate();
  const [optimisticDate, setOptimisticDate] = useState<string | null>(null);

  // Reset local state when user prop changes
  useEffect(() => {
    setGoal(user.latest_goal?.goal_text || "");
    setIsDirty(false);
    setOptimisticDate(null);
  }, [user.latest_goal?.goal_text]);

  const updateGoalMutation = useMutation<string, Error, string>({
    mutationFn: async (newGoal: string) => {
      await updateUserGoal(supabase, user.id, newGoal);
      return newGoal;
    },
    onMutate: (newGoal) => {
      // Set optimistic date
      setOptimisticDate(currentDate);
      
      // Optimistic update
      queryClient.setQueryData(['user', user.id], (oldData: UserWithEmail | undefined) => {
        if (!oldData) return oldData;

        // Create a new goal object with the current date
        const updatedGoal: UserGoal = {
          id: oldData.latest_goal?.id || 0,
          goal_text: newGoal,
          start_date: currentDate,
          user_id: user.id
        };

        return {
          ...oldData,
          latest_goal: updatedGoal
        };
      });
    },
    onError: (error) => {
      setOptimisticDate(null);
      // Revert optimistic update
      queryClient.setQueryData(['user', user.id], (oldData: UserWithEmail | undefined) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          latest_goal: user.latest_goal
        };
      });
      console.error("Failed to update goal:", error);
    },
    onSuccess: () => {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    },
    onSettled: () => {
      setIsDirty(false);
      // Invalidate and refetch to ensure we have the latest data
      queryClient.invalidateQueries({ queryKey: ['user', user.id] });
    }
  });

  const handleGoalChange = useCallback((newGoal: string) => {
    setGoal(newGoal);
    setIsDirty(true);
  }, []);

  const handleGoalSave = useCallback(() => {
    if (!isDirty || !goal || goal === user.latest_goal?.goal_text || updateGoalMutation.status === 'pending') return;
    updateGoalMutation.mutate(goal);
  }, [updateGoalMutation, user.latest_goal?.goal_text, goal, isDirty]);

  // Get the date to display - use optimistic date during mutation, fallback to actual date
  const displayDate = optimisticDate || user.latest_goal?.start_date;

  return (
    <Card className="max-w-2xl border-2 border-primary/20">
      <CardContent className="p-6">
        <div className="space-y-6">
          <div className="flex items-end justify-end">
            {displayDate && (
              <div className="text-xs text-muted-foreground whitespace-nowrap flex items-center gap-1.5">
                <Clock className="h-3 w-3 text-primary flex-shrink-0" />
                Last updated: {formatDate(displayDate)}
              </div>
            )}
          </div>

          <CurrentGoalInput
            value={goal}
            onChange={handleGoalChange}
            disabled={updateGoalMutation.status === 'pending'}
          />
          
          <div className="flex justify-end">
            <Button
              onClick={handleGoalSave}
              disabled={!isDirty || updateGoalMutation.status === 'pending' || !goal || goal === user.latest_goal?.goal_text}
              className="relative min-w-[100px] transition-all duration-200"
              variant={showSuccess ? "outline" : "default"}
            >
              <span className={`flex items-center gap-2 ${showSuccess ? "text-green-500" : ""}`}>
                {showSuccess ? (
                  <>
                    <Check className="h-4 w-4" />
                    Saved
                  </>
                ) : updateGoalMutation.status === 'pending' ? (
                  <>
                    <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary border-t-transparent" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="h-4 w-4" />
                    Save Goal
                  </>
                )}
              </span>
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}); 