// filtering.tsx
import React, { useEffect, useCallback, useMemo } from "react";
import { Table } from "@tanstack/react-table";
import { OptionsType } from "@attrove/attrove-ui/app/pages/dashboard/messages";
import { useSearchParams } from "react-router-dom";
import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@attrove/ui-shadcn";
import { capitalizeFirstLetter } from "@attrove/util-formatting";
import { FilterSelect } from "./filter-select";
import { RenderTags } from "./render-tags";
import { Search } from "lucide-react";

interface MessageFiltersProps<TData> {
  options: OptionsType;
  table: Table<TData>;
  entityData: { [key: string]: { name: string; entity_type: string } };
  queryParams: any;
}

export function MessageFilters<TData>({ options, table, entityData, queryParams }: MessageFiltersProps<TData>) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const filters: { id: string; value: any }[] = [];
    if (queryParams.integrations) filters.push({ id: "integration", value: queryParams.integrations });
    if (queryParams.reports) filters.push({ id: "report", value: queryParams.reports });
    if (queryParams.tags) filters.push({ id: "tags", value: queryParams.tags });
    if (queryParams.message) filters.push({ id: "message", value: queryParams.message });
    if (queryParams.event) filters.push({ id: "event", value: queryParams.event });
    if (queryParams.entity) filters.push({ id: "entity", value: queryParams.entity });

    table.setColumnFilters(filters);
  }, [queryParams, table]);

  const handleSortChange = useCallback(
    (value: string) => {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set("sort", value);
        return newParams;
      });
    },
    [setSearchParams],
  );

  const entityTitle = useMemo(() => {
    if (queryParams.entity && entityData[queryParams.entity]) {
      const entityType = capitalizeFirstLetter(entityData[queryParams.entity].entity_type);
      const entityName = entityData[queryParams.entity].name;
      return `${entityType}: ${entityName}`;
    }
    return '';
  }, [queryParams.entity, entityData]);

  // Create the correct options structure for RenderTags
  const renderTagsOptions = useMemo(() => ({
    integrationOptions: options.integrations,
    reportOptions: options.reports,
    tagOptions: options.tags,
  }), [options]);

  return (
    <div className="p-4 space-y-4">
      <div className="flex items-center justify-between">
        <div className="relative w-[320px]">
          <Search className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Filter messages..."
            value={(table.getColumn("details")?.getFilterValue() as string) ?? ""}
            onChange={(event) => table.getColumn("details")?.setFilterValue(event.target.value)}
            className="pl-9 bg-background"
          />
        </div>
        <Select onValueChange={handleSortChange} defaultValue={searchParams.get("sort") || "time"}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Sort by" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="time">Sort by Time</SelectItem>
            <SelectItem value="priority">Sort by Priority</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="flex flex-wrap gap-2">
        <FilterSelect options={options} title="Integrations" urlParam="integrations" />
        <FilterSelect options={options} title="Reports" urlParam="reports" />
        <FilterSelect options={options} title="Tags" urlParam="tags" />
      </div>
      <div className="flex flex-wrap gap-2">
        <RenderTags options={renderTagsOptions} urlParam="integrations" title="Integration" />
        <RenderTags options={renderTagsOptions} urlParam="reports" title="Report" />
        <RenderTags options={renderTagsOptions} urlParam="tags" title="Tag" />
        {entityTitle && <RenderTags options={renderTagsOptions} urlParam="entity" title={entityTitle} />}
      </div>
    </div>
  );
}