import { ContentLayout } from "@attrove/attrove-ui/components/layouts/content-layout";
import { useSession } from "@attrove/attrove-ui/context/SessionContext";
import { Schedule } from "@attrove/attrove-ui/features/schedule";
import {
  EventRow,
  GET_EVENTS_BY_USER,
  GET_INTEGRATION_BY_USERID_QUERY_KEY,
  getEventsByUser,
  getIntegrationsByUserId,
  Integration,
} from "@attrove/service-supabase";
import { buttonVariants, cn, Card } from "@attrove/ui-shadcn";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { supabase } from "../../supabase";
import { Calendar, CalendarX2, ArrowRight } from "lucide-react";

const EmptyStateIllustration = ({ icon: Icon }: { icon: React.ElementType }) => (
  <div className="relative">
    <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-primary/5 blur-xl rounded-full" />
    <div className="relative bg-gradient-to-b from-background to-background/80 rounded-full p-4 border shadow-sm">
      <Icon className="w-12 h-12 text-primary" />
    </div>
  </div>
);

export default function SchedulePage() {
  const { user } = useSession();
  const { data: events, isLoading } = useQuery<EventRow[]>({
    queryKey: [GET_EVENTS_BY_USER],
    queryFn: () => getEventsByUser(supabase),
  });

  const { data: integrations, isLoading: integrationLoading } = useQuery<Integration[]>({
    queryKey: [GET_INTEGRATION_BY_USERID_QUERY_KEY],
    queryFn: () => getIntegrationsByUserId(supabase, user.id),
  });

  if (isLoading || integrationLoading) {
    return <p>Loading...</p>;
  }

  const hasGenericCalendar = integrations?.some((integration) => integration.type_generic === "calendar");

  if (!events || events.length === 0) {
    if (!hasGenericCalendar) {
      return (
        <div className="flex flex-col w-full justify-center items-center h-full">
          <Card className="w-[400px] p-8">
            <div className="flex flex-col items-center text-center space-y-6">
              <EmptyStateIllustration icon={Calendar} />
              <div className="space-y-2">
                <h2 className="text-2xl font-semibold tracking-tight">No calendars connected</h2>
                <p className="text-sm text-muted-foreground">
                  Connect your calendar to see your upcoming meetings and events all in one place.
                </p>
              </div>
              <Link 
                to="/integrations/new" 
                className={cn(
                  buttonVariants({ size: "lg" }),
                  "gap-2 group"
                )}
              >
                Connect Calendar
                <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </Link>
            </div>
          </Card>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col w-full justify-center items-center h-full">
          <Card className="w-[400px] p-8">
            <div className="flex flex-col items-center text-center space-y-6">
              <EmptyStateIllustration icon={CalendarX2} />
              <div className="space-y-2">
                <h2 className="text-2xl font-semibold tracking-tight">All clear ahead</h2>
                <p className="text-sm text-muted-foreground">
                  You have no upcoming meetings or events scheduled. Enjoy the peace and quiet!
                </p>
              </div>
            </div>
          </Card>
        </div>
      );
    }
  }

  return (
    <ContentLayout>
      <Schedule events={events} />
    </ContentLayout>
  );
}
