import { type AttroveSupabaseClient, calendarsInsertSchema, calendarsRowSchema, DB } from "@attrove/service-supabase";

import { logError, logWarn, logInfo } from "@attrove/util-logs";
import { Logtail } from "@logtail/node";
import { z } from "zod";
import { EventRow } from "./events";

export type CalendarsInsert = Omit<z.infer<typeof calendarsInsertSchema>, "id">;

export type Calendar = z.infer<typeof calendarsRowSchema>;
export type CalendarWithEvents = Calendar & { events: EventRow[] };


export const insertCalendarsMany = async (
  supabaseClient: AttroveSupabaseClient,
  calendarData: CalendarsInsert[],
  logtail?: Logtail,
): Promise<Calendar[] | null> => {
  const { data: calendars, error: insertCalendarsError } = await supabaseClient
    .from(DB.CALENDARS)
    // need to cast to any because of a bug in supabase typings
    .insert(calendarData as any)
    .select();

  if (insertCalendarsError) {
    logError("[insertCalendarsMany] Error inserting calendars", { error: insertCalendarsError }, logtail);
    return null;
  }
  return calendars;
};

type UpdateableCalendarFields = Omit<Calendar, "id">;

export const updateCalendar = async (
  supabaseClient: AttroveSupabaseClient,
  calendarId: number,
  updatedCalendar: Partial<UpdateableCalendarFields>,
): Promise<Calendar> => {
  const { data: calendar, error: updateCalendarError } = await supabaseClient
    .from(DB.CALENDARS)
    .update(updatedCalendar)
    .eq("id", calendarId)
    .select()
    .single();

  if (updateCalendarError) {
    console.error(updateCalendarError.message);
    console.log(updatedCalendar);
    throw updateCalendarError;
  }

  if (!calendar) {
    throw new Error("Calendar not found");
  }

  return calendar;
};

export const getLastSyncCalendarWithID = async (
  supabaseClient: AttroveSupabaseClient,
  calendarId: number,
  logtail?: Logtail,
): Promise<{ last_checked: string | null; calendar_id_3p: string; sync_token: string | null } | null> => {
  const { data: calendar, error: getLastSyncError } = await supabaseClient
    .from(DB.CALENDARS)
    .select("last_checked, calendar_id_3p, sync_token")
    .eq("id", calendarId)
    .single();

  if (getLastSyncError) {
    logError(
      "[getLastSyncCalendarWithID] error getting last checked time",
      {
        error: getLastSyncError,
        calendarId,
      },
      logtail,
    );
    return null;
  }
  return { 
    last_checked: calendar.last_checked, 
    calendar_id_3p: calendar.calendar_id_3p, 
    sync_token: calendar.sync_token 
  };
};

export const updateLastSyncCalendar = async (
  supabaseClient: AttroveSupabaseClient,
  calendarId: number,
  logtail?: Logtail,
): Promise<string | null> => {
  const { data: calendar, error: updateLastSyncError } = await supabaseClient
    .from(DB.CALENDARS)
    .update({ last_checked: new Date().toISOString() })
    .eq("id", calendarId)
    .select()
    .single();

  if (updateLastSyncError) {
    logError(
      "[updateLastSyncCalendar] error updating last checked time",
      {
        error: updateLastSyncError,
        calendarId,
      },
      logtail,
    );
    return null;
  }
  return calendar.last_checked;
};

export const getCalendarIdsForIntegration = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  logtail?: Logtail,
  activeOnly = true,
): Promise<number[] | null> => {
  try {
    const { data: calendars, error } = await supabaseClient
      .from(DB.CALENDARS)
      .select("id")
      .eq("integration_id", integrationId)
      .eq("active", activeOnly);

    if (error) {
      throw error;
    }

    if (!calendars || calendars.length === 0) {
      logWarn("[getCalendarIdsForIntegration] No calendars found for integration", { integrationId }, logtail);
      return null;
    }

    return calendars.map((calendar) => calendar.id);
  } catch (error) {
    logError("[getCalendarIdsForIntegration] Error fetching calendar IDs", { error, integrationId }, logtail);
    return null;
  }
};

export async function getUserCalendars(
  supabaseClient: AttroveSupabaseClient,
  integrationIds: number[],
  logtail?: Logtail
): Promise<number[]> {
  try {
    if (integrationIds.length === 0) {
      logWarn("[getUserCalendars] No integration IDs provided", {}, logtail);
      return [];
    }

    const { data: calendars, error } = await supabaseClient
      .from(DB.CALENDARS)
      .select('id')
      .in('integration_id', integrationIds)
      .eq('active', true);

    if (error) {
      throw error;
    }

    if (!calendars || calendars.length === 0) {
      // logWarn("[getUserCalendars] No active calendars found for the provided integrations", { integrationIds }, logtail);
      return [];
    }

    return calendars.map((calendar: { id: number }) => calendar.id);
  } catch (error) {
    logError(
      "[getUserCalendars] Error fetching user calendars",
      { error: error instanceof Error ? error.message : "Unknown error", integrationIds },
      logtail
    );
    throw error;
  }
}

export const updateCalendarSyncToken = async (
  supabaseClient: AttroveSupabaseClient,
  calendarId: number,
  syncToken: string,
  logtail?: Logtail
): Promise<boolean> => {
  const startTime = Date.now();
  
  try {
    const { data, error } = await supabaseClient
      .from(DB.CALENDARS)
      .update({ 
        sync_token: syncToken,
        last_checked: new Date().toISOString()
      })
      .eq('id', calendarId)
      .select('id')
      .single();

    if (error) {
      throw error;
    }

    const endTime = Date.now();
    // logInfo(
    //   "[updateCalendarSyncToken] Successfully updated calendar sync token",
    //   { 
    //     calendarId, 
    //     executionTime: `${endTime - startTime}ms`
    //   },
    //   logtail
    // );

    return true;
  } catch (error) {
    logError(
      "[updateCalendarSyncToken] Error updating calendar sync token",
      { 
        error: error instanceof Error ? error.message : "Unknown error", 
        calendarId 
      },
      logtail
    );
    return false;
  }
};

export async function deleteEvents(supabase: AttroveSupabaseClient, eventIds: string[], calendarId: number, logtail: Logtail) {
  if (eventIds.length === 0) {
    logInfo("[deleteEvents] No events to delete", { count: 0 }, logtail);
    return 0;
  }

  // logInfo("[deleteEvents] Attempting to delete events", { eventIds, calendarId }, logtail);

  const { error, data } = await supabase
    .from('events')
    .delete()
    .in('event_id_3p', eventIds)
    .eq('calendar_id', calendarId)
    .select();

  if (error) {
    logError("[deleteEvents] Error deleting events:", { error, eventIds, calendarId }, logtail);
    throw error;
  }

  const deletedCount = data?.length || 0;

  if (deletedCount > 0) {
    logInfo("[deleteEvents] Events deleted", { count: deletedCount, eventIds, calendarId }, logtail);
  } else {
    logWarn("[deleteEvents] No events were deleted", { eventIds, calendarId }, logtail);
  }

  return deletedCount;
}

export const getCalendarIdsByExternalIds = async (
  supabaseClient: AttroveSupabaseClient,
  calendarIds3p: string[],
  logtail?: Logtail
): Promise<Map<string, number> | null> => {
  try {
    const { data: calendars, error } = await supabaseClient
      .from(DB.CALENDARS)
      .select('id, calendar_id_3p')
      .in('calendar_id_3p', calendarIds3p);

    if (error) {
      logError("[getCalendarIdsByExternalIds] Error fetching calendar IDs", { error, calendarIds3p }, logtail);
      return null;
    }

    if (!calendars || calendars.length === 0) {
      logWarn("[getCalendarIdsByExternalIds] No calendars found", { calendarIds3p }, logtail);
      return null;
    }

    // Create a map of calendar_id_3p to database id for quick lookup
    return new Map(calendars.map(cal => [cal.calendar_id_3p, cal.id]));
  } catch (error) {
    logError("[getCalendarIdsByExternalIds] Unexpected error", { error, calendarIds3p }, logtail);
    return null;
  }
};