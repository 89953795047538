import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ContentLayout } from "@attrove/attrove-ui/components/layouts/content-layout";
import { MetaTagStats, type TagTrend, getMetaTagStats, getTrendingTags } from "@attrove/util-tags";
import { muteTag, unmuteTag, batchUnmuteTags } from "@attrove/service-supabase";
import { useTimePeriod } from "@attrove/attrove-ui/context/TimeContext";
import { 
  Card, 
  CardContent, 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue, 
  ScrollArea, 
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  Badge,
  Checkbox,
  Input,
  Separator,
} from "@attrove/ui-shadcn";
import { Users, ArrowUp, ArrowDown, Minus, Hash, Search, PlusCircle, EyeOff, Undo2, Settings2, Clock } from "lucide-react";
import { cn } from "@attrove/ui-shadcn";
import { format, subDays } from "date-fns";
import { Link } from "react-router-dom";
import { TagDetailView } from "../../../features/tags/TagDetail";
import { DetailSidePanel } from "../../../components/layouts/detail-side-panel";
import { supabase } from "../../supabase";

const TrendIcon = ({ direction }: { direction: TagTrend['trend_direction'] }) => {
  const icons = {
    up: <ArrowUp className="w-4 h-4 text-green-500" />,
    down: <ArrowDown className="w-4 h-4 text-red-500" />,
    stable: <Minus className="w-4 h-4 text-yellow-500" />,
    new: <PlusCircle className="w-4 h-4 text-blue-500" />
  };
  return icons[direction];
};

const TagCard = ({ 
  tag, 
  onClick, 
  isSelected,
  onMuteToggle 
}: { 
  tag: TagTrend; 
  onClick: () => void; 
  isSelected: boolean;
  onMuteToggle: (e: React.MouseEvent) => void;
}) => (
  <Card 
    className={cn(
      "hover:border-brand-primary hover:shadow-md hover:bg-accent/5",
      "transition-all duration-200 cursor-pointer relative",
      isSelected && "border-brand-primary bg-accent/5",
      tag.is_muted && "opacity-75"
    )}
    onClick={onClick}
  >
    <CardContent className="p-3">
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 flex-1 min-w-0 pr-4">
            <div className="flex-1 min-w-0">
              <span className="text-base font-semibold text-brand-primary truncate block">{tag.name}</span>
            </div>
            <div className="flex items-center gap-1 whitespace-nowrap flex-shrink-0">
              <TrendIcon direction={tag.trend_direction} />
              <span className="text-xs font-medium">
                {tag.trend_direction === 'new' ? 'New' : `${Math.round(tag.trend_percentage)}%`}
              </span>
            </div>
          </div>

          <TooltipProvider delayDuration={300}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className={cn(
                    "relative h-7 transition-all duration-200 border shadow-sm flex-shrink-0 w-[60px]",
                    tag.is_muted 
                      ? "bg-secondary hover:bg-secondary/80 text-muted-foreground hover:text-foreground border-muted" 
                      : "bg-background hover:bg-accent text-foreground hover:text-accent-foreground"
                  )}
                  onClick={onMuteToggle}
                >
                  <span className="text-xs">
                    {tag.is_muted ? "Unmute" : "Mute"}
                  </span>
                </Button>
              </TooltipTrigger>
              <TooltipContent 
                side="bottom" 
                align="end"
                className="max-w-[200px] text-xs z-[100]"
                sideOffset={5}
              >
                {tag.is_muted 
                  ? "Include this tag in reports, summaries, and analytics" 
                  : "Stop including this tag in reports, summaries, and analytics"}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
          
        <div className="flex items-center gap-4 text-xs text-muted-foreground">
          <span className="flex-shrink-0">Mentions: {tag.count}</span>
          <span className="flex-shrink-0">Last: {format(new Date(tag.last_activity), 'MMM d, h:mm a')}</span>
        </div>

        {tag.top_tag_entities.length > 0 && (
          <div className="flex items-center gap-2 text-xs">
            <Users className="h-3.5 w-3.5 text-muted-foreground flex-shrink-0" />
            <div className="flex flex-wrap gap-1 min-w-0">
              {tag.top_tag_entities.map((person, index) => (
                <React.Fragment key={person.id}>
                  {index > 0 && <span className="text-muted-foreground flex-shrink-0">&middot;</span>}
                  <Link
                    to={`/people?entity_name=${encodeURIComponent(person.name)}`}
                    className="text-brand-primary hover:underline truncate max-w-[150px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {person.name}
                  </Link>
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </CardContent>
  </Card>
);

const StatsCard = ({ icon: Icon, title, value, className }: any) => (
  <Card className="overflow-hidden">
    <CardContent className="p-3 flex items-center gap-3">
      <div className={cn("p-1.5 rounded-md shrink-0", className)}>
        <Icon className="w-3.5 h-3.5" />
      </div>
      <div className="min-w-0">
        <p className="text-xs font-medium text-muted-foreground truncate">{title}</p>
        <h3 className="text-xl font-semibold">{value}</h3>
      </div>
    </CardContent>
  </Card>
);

const getStartDate = (days: number) => {
  return subDays(new Date(), days).toISOString();
};

export default function Tags() {
  const { timePeriod, setTimePeriod } = useTimePeriod();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTag, setSelectedTag] = useState<TagTrend | null>(null);
  const [tags, setTags] = useState<TagTrend[]>([]);
  const [metaStats, setMetaStats] = useState<MetaTagStats>({ total_mentions: 0, unique_participants: 0 });
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMutedTags, setShowMutedTags] = useState(false);
  const [mutedTags, setMutedTags] = useState<TagTrend[]>([]);
  const [mutedTagsCount, setMutedTagsCount] = useState(0);

  const fetchMutedTags = useCallback(async () => {
    try {
      const startDate = getStartDate(parseInt(timePeriod));
      const { data, error } = await supabase.rpc('get_muted_tags', {
        p_start_date: startDate,
        p_end_date: new Date().toISOString()
      });
      if (error) throw error;
      
      // Convert to TagTrend format
      const mutedTagTrends = data.map((tag: any) => ({
        id: tag.tag_id,
        name: tag.formatted_name,
        count: tag.count,
        trend_direction: 'stable' as const,
        trend_percentage: 0,
        category: 'normal',
        importance: tag.decayed_importance >= 0.6 ? 'high' : tag.decayed_importance >= 0.53 ? 'medium' : 'low',
        last_activity: tag.last_activity,
        is_muted: true,
        top_tag_entities: []
      }));

      setMutedTags(mutedTagTrends);
      setMutedTagsCount(mutedTagTrends.length);
    } catch (error) {
      console.error('Error fetching muted tags:', error);
    }
  }, [timePeriod]);

  const fetchTags = useCallback(async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const startDate = getStartDate(parseInt(timePeriod));
      const [newTags, stats] = await Promise.all([
        getTrendingTags({
          supabase,
          start_date: startDate,
          max_limit: 50,
        }),
        getMetaTagStats({supabase, start_date: startDate})
      ]);

      setTags(newTags.filter(t => !t.is_muted));
      setMetaStats(stats);
      
      // Fetch muted tags separately
      await fetchMutedTags();
    } catch (error) {
      console.error('Error fetching tag data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [timePeriod, fetchMutedTags]);

  useEffect(() => {
    fetchTags();
  }, [timePeriod]);

  const filteredTags = useMemo(() => {
    const tagsToFilter = showMutedTags ? mutedTags : tags;
    return tagsToFilter.filter(tag => {
      const matchesSearch = searchQuery === "" || 
        tag.name.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesSearch;
    });
  }, [tags, mutedTags, searchQuery, showMutedTags]);

  const displayedTags = useMemo(() => {
    return filteredTags.filter(tag => showMutedTags ? tag.is_muted : !tag.is_muted);
  }, [filteredTags, showMutedTags]);

  const handleTimePeriodChange = (value: string) => {
    setTimePeriod(value);
  };

  const handleTagSelect = (tag: TagTrend) => {
    setSelectedTag(tag);
  };

  const handleDetailClose = () => {
    setSelectedTag(null);
  };

  const handleUnmuteAll = async () => {
    try {
      const success = await batchUnmuteTags(supabase, mutedTags.map(tag => tag.id));
      if (!success) throw new Error('Failed to unmute all tags');

      // Refresh both tag lists
      await Promise.all([fetchTags(), fetchMutedTags()]);
    } catch (error) {
      console.error('Error unmuting all tags:', error);
    }
  };

  const handleMuteToggle = (tagId: string) => async (e: React.MouseEvent) => {
    e.stopPropagation();
    
    try {
      const tag = [...tags, ...mutedTags].find(t => t.id === tagId);
      if (!tag) return;

      const success = await (tag.is_muted ? unmuteTag : muteTag)(supabase, tagId);
      if (!success) throw new Error('Failed to update tag mute status');

      // Refresh both tag lists
      await Promise.all([fetchTags(), fetchMutedTags()]);
    } catch (error) {
      console.error('Error toggling tag mute status:', error);
    }
  };

  return (
    <ContentLayout>
        <div className="max-w-7xl mx-auto px-4 py-6">
          <div className="border-b pb-6">
            {/* Top Row: Title and Time Period */}
            <div className="flex items-center justify-between mb-6">
              <div>
                <h1 className="text-3xl font-bold">Tags & Trends</h1>
                <div className="flex items-center gap-3 mt-2 text-muted-foreground text-sm">
                  <div className="flex items-center gap-1.5">
                    <Hash className="h-4 w-4 text-primary" />
                    <span>{metaStats.total_mentions} Total Mentions</span>
                  </div>
                  {mutedTagsCount > 0 && (
                    <div className="flex items-center gap-1.5">
                      <EyeOff className="h-4 w-4 text-muted-foreground" />
                      <span>{mutedTagsCount} Muted</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-end gap-1.5">
                <Select value={timePeriod} onValueChange={handleTimePeriodChange}>
                  <SelectTrigger className="w-[180px] bg-brand-primary/5 border-brand-primary/20">
                    <Clock className="h-4 w-4 text-brand-primary mr-2" />
                    <SelectValue placeholder="Select time period" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="1">Last 24 hours</SelectItem>
                    <SelectItem value="7">Last 7 days</SelectItem>
                    <SelectItem value="30">Last 30 days</SelectItem>
                    <SelectItem value="90">Last 90 days</SelectItem>
                  </SelectContent>
                </Select>
                <span className="text-xs text-muted-foreground">
                  Showing data for the last {timePeriod} {parseInt(timePeriod) === 1 ? "day" : "days"}
                </span>
              </div>
            </div>

            {/* Bottom Row: Search and Actions */}
            <div className="flex flex-col gap-4">
              <Separator className="bg-border" />
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="relative w-[320px]">
                    <Search className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                      placeholder="Search tags..."
                      className="pl-9 bg-background"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onFocus={() => setIsSearchFocused(true)}
                      onBlur={() => setIsSearchFocused(false)}
                    />
                  </div>
                </div>
                <Drawer>
                  <DrawerTrigger asChild>
                    <Button variant="outline" size="sm" className="gap-2">
                      <EyeOff className="h-4 w-4" />
                      <span>Manage Muted Tags</span>
                      {mutedTagsCount > 0 && (
                        <Badge variant="secondary" className="ml-1">
                          {mutedTagsCount}
                        </Badge>
                      )}
                    </Button>
                  </DrawerTrigger>
                  <DrawerContent>
                    <div className="mx-auto w-full max-w-sm">
                      <DrawerHeader>
                        <DrawerTitle>Muted Tags</DrawerTitle>
                        <DrawerDescription>
                          View and manage your muted tags
                        </DrawerDescription>
                      </DrawerHeader>
                      <div className="p-4">
                        <div className="flex flex-col gap-4">
                          <div className="flex items-center justify-between">
                            <span className="text-sm text-muted-foreground">
                              {mutedTagsCount} muted {mutedTagsCount === 1 ? 'tag' : 'tags'}
                            </span>
                            {mutedTagsCount > 0 && (
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={handleUnmuteAll}
                                className="ml-2"
                              >
                                <Undo2 className="mr-2 h-4 w-4" />
                                Unmute All
                              </Button>
                            )}
                          </div>
                          <Separator />
                          <ScrollArea className="h-[300px] pr-4">
                            <div className="space-y-2">
                              {mutedTags.filter(tag => 
                                searchQuery === "" || tag.name.toLowerCase().includes(searchQuery.toLowerCase())
                              ).map((tag) => (
                                <Card
                                  key={tag.id}
                                  className="flex items-center justify-between p-3 hover:bg-accent/5 transition-colors"
                                >
                                  <div className="flex flex-col gap-1">
                                    <span className="font-medium">{tag.name}</span>
                                    <div className="flex items-center gap-4 text-xs text-muted-foreground">
                                      <span>Mentions: {tag.count}</span>
                                      <span>Last: {format(new Date(tag.last_activity), 'MMM d, h:mm a')}</span>
                                    </div>
                                  </div>
                                  <TooltipProvider delayDuration={300}>
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        <Button
                                          variant="ghost"
                                          size="sm"
                                          onClick={handleMuteToggle(tag.id)}
                                          className="hover:bg-background"
                                        >
                                          <Undo2 className="h-4 w-4" />
                                        </Button>
                                      </TooltipTrigger>
                                      <TooltipContent side="left">
                                        Unmute this tag
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                </Card>
                              ))}
                              {mutedTags.length === 0 && (
                                <div className="flex flex-col items-center justify-center py-8 text-center text-muted-foreground">
                                  <EyeOff className="h-8 w-8 mb-2 opacity-50" />
                                  <p>No muted tags</p>
                                  <p className="text-sm">Tags you mute will appear here</p>
                                </div>
                              )}
                            </div>
                          </ScrollArea>
                        </div>
                      </div>
                    </div>
                  </DrawerContent>
                </Drawer>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-3 py-3">
            <Card className="overflow-hidden">
              <CardContent className="p-3 flex items-center gap-3">
                <div className="p-1.5 rounded-md bg-brand-primary/10">
                  <Hash className="w-3.5 h-3.5 text-brand-primary" />
                </div>
                <div className="min-w-0">
                  <p className="text-xs font-medium text-muted-foreground">Unique Tags</p>
                  <h3 className="text-xl font-semibold">{displayedTags.length}</h3>
                </div>
              </CardContent>
            </Card>
            <Card className="overflow-hidden">
              <CardContent className="p-3 flex items-center gap-3">
                <div className="p-1.5 rounded-md bg-blue-100">
                  <Users className="w-3.5 h-3.5 text-blue-600" />
                </div>
                <div className="min-w-0">
                  <p className="text-xs font-medium text-muted-foreground">Unique Participants</p>
                  <h3 className="text-xl font-semibold">{metaStats.unique_participants}</h3>
                </div>
              </CardContent>
            </Card>
            <Card className="overflow-hidden">
              <CardContent className="p-3 flex items-center gap-3">
                <div className="p-1.5 rounded-md bg-gray-100">
                  <EyeOff className="w-3.5 h-3.5 text-gray-600" />
                </div>
                <div className="min-w-0">
                  <p className="text-xs font-medium text-muted-foreground">Muted Tags</p>
                  <h3 className="text-xl font-semibold">{mutedTagsCount}</h3>
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="flex-1 flex gap-6 min-h-0 h-[calc(100vh-420px)] overflow-hidden">
            <ScrollArea className="w-[450px]">
              <div className="space-y-2 pr-4">
                {displayedTags.map((tag) => (
                  <TagCard
                    key={tag.id}
                    tag={tag}
                    onClick={() => handleTagSelect(tag)}
                    isSelected={selectedTag?.id === tag.id}
                    onMuteToggle={handleMuteToggle(tag.id)}
                  />
                ))}
              </div>
            </ScrollArea>
            <div className="flex-1 border-l pl-6 overflow-y-auto">
              {selectedTag ? (
                <TagDetailView tag={selectedTag} />
              ) : (
                <div className="flex items-center justify-center h-full text-muted-foreground">
                  Select a tag to view details
                </div>
              )}
            </div>
          </div>
        </div>
    </ContentLayout>
  );
} 