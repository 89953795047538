import { Navigate, Outlet, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { AuthLayout } from "../components/layouts/auth-layout";

import DashboardLayout from "../components/layouts/dashboard-layout";
import { SettingsLayout } from "../components/layouts/settings-layout";
import { SessionProvider } from "../context/SessionContext";
import { TimePeriodProvider } from '../context/TimeContext';
import { SubscriptionProvider } from '../context/SubscriptionContext';
import { useSession } from "../context/UnprotectedSessionContext";
import { withFeatureGate } from '../components/feature-gate';
import CompleteAuth from "./pages/auth/complete-auth";
import EmailSignIn from "@attrove/attrove-ui/app/pages/auth/email-signin";
import ForgotPassword from "@attrove/attrove-ui/app/pages/auth/forgot-password";
import PasswordSignIn from "@attrove/attrove-ui/app/pages/auth/password-signin";
import Signup from "@attrove/attrove-ui/app/pages/auth/signup";
import UpdatePassword from "@attrove/attrove-ui/app/pages/auth/update-password";
import Flow from "@attrove/attrove-ui/app/pages/dashboard/flow";
import Generations from "@attrove/attrove-ui/app/pages/dashboard/generations";
import Insights from "@attrove/attrove-ui/app/pages/dashboard/insights";
import Messages from "@attrove/attrove-ui/app/pages/dashboard/messages";
import Reports from "@attrove/attrove-ui/app/pages/dashboard/reports";
import SchedulePage from "@attrove/attrove-ui/app/pages/dashboard/schedule";
import People from "@attrove/attrove-ui/app/pages/dashboard/people";
import Tags from "@attrove/attrove-ui/app/pages/dashboard/tags";
import Billing from "@attrove/attrove-ui/app/pages/dashboard/settings/billing";
import Profile from "@attrove/attrove-ui/app/pages/dashboard/settings/profile";
import Integrations from "./pages/dashboard/integrations";
import IntegrationDetail from "./pages/dashboard/integrations/detail";
import NewIntegration from "./pages/dashboard/integrations/new";
import OutlookCallback from "./pages/dashboard/integrations/outlook-callback";

// Feature gated components
const AdvancedIntegrations = withFeatureGate(Integrations, 'advanced_integrations', { showUpgradePrompt: true });
const AdvancedAnalytics = withFeatureGate(Insights, 'advanced_analytics', { showUpgradePrompt: true });

export default function App() {
  const { session } = useSession();

  // console.log("session", session);
  const ProtectedRoute = () => {
    if (!session) {
      return <Navigate to="/password_signin" replace />;
    }
    return (
      <SessionProvider>
        <SubscriptionProvider>
          <TimePeriodProvider>
            <Outlet />
          </TimePeriodProvider>
        </SubscriptionProvider>
      </SessionProvider>
    );
  };

  // PublicRoute for non-authenticated users
  const PublicRoute = () => {
    if (session) {
      return <Navigate to="/reports" replace />;
    }
    return <Outlet />;
  };

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<AuthLayout />}>
            <Route index element={<Navigate replace to="/password-signin" />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/email_signin" element={<EmailSignIn />} />
            <Route path="/password_signin" element={<PasswordSignIn />} />
            <Route path="/update_password" element={<UpdatePassword />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/complete-auth" element={<CompleteAuth />} />
          <Route path="/" element={<DashboardLayout />}>
            <Route index element={<Navigate to="/reports" replace />} />
            {/* <Route path="dashboard" element={<Dashboard />} /> */}
            <Route path="reports/:reportId?" element={<Reports />} />
            <Route path="schedule" element={<SchedulePage />} />
            <Route path="people" element={<People />} />
            <Route path="tags" element={<Tags />} />

            <Route path="generations" element={<Generations />} />
            <Route path="insights" element={<AdvancedAnalytics />} />
            <Route path="messages" element={<Messages />} />
            <Route path="flow" element={<Flow />} />

            <Route path="integrations" element={<AdvancedIntegrations />} />
            <Route path="integrations/new" element={<NewIntegration />} />
            <Route path="integrations/new/microsoft/outlook/callback" element={<OutlookCallback />} />
            <Route path="integrations/:integrationId" element={<IntegrationDetail />} />
            <Route path="settings" element={<SettingsLayout />}>
              <Route path="profile" element={<Profile />} />
              <Route path="billing" element={<Billing />} />
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<Navigate to={session ? "/reports" : "/password_signin"} />} />
      </Routes>
    </Router>
  );
}
