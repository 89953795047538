import { ComponentType, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSubscriptionFeatures } from '../context/SubscriptionContext';
import { Alert, AlertDescription, AlertTitle, Button, useToast } from '@attrove/ui-shadcn';
import { Lock, Loader2 } from 'lucide-react';
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { AxiosError } from 'axios';

interface FeatureGateProps {
  feature: string;
  fallback?: ComponentType;
  showUpgradePrompt?: boolean;
}

export function withFeatureGate<P extends object>(
  WrappedComponent: ComponentType<P>,
  feature: string,
  options: Omit<FeatureGateProps, 'feature'> = {}
) {
  return function FeatureGatedComponent(props: P) {
    const { checkFeatureAccess, showUpgradeDialog } = useSubscriptionFeatures();
    const [isActionLoading, setIsActionLoading] = useState(false);
    const axiosClient = useContext(AxiosClientContext);
    const { toast } = useToast();
    const hasAccess = checkFeatureAccess(feature);

    const handleUpgrade = async () => {
      try {
        setIsActionLoading(true);
        const response = await axiosClient.get<{ url: string }>("/api/stripe/billing-session");
        
        if (response.data?.url) {
          window.location.href = response.data.url;
        }
      } catch (error) {
        const axiosError = error as AxiosError<{ error: string }>;
        const errorMessage = axiosError.response?.data?.error || "Failed to access billing";
        toast({
          title: "Error",
          description: errorMessage,
          variant: "destructive",
        });
      } finally {
        setIsActionLoading(false);
      }
    };

    if (!hasAccess) {
      if (options.fallback) {
        const Fallback = options.fallback;
        return <Fallback />;
      }

      if (options.showUpgradePrompt) {
        return (
          <div className="flex items-center justify-center min-h-[400px]">
            <Alert className="max-w-lg">
              <Lock className="h-4 w-4" />
              <AlertTitle>Feature not available</AlertTitle>
              <AlertDescription>
                <p className="mb-4">
                  This feature requires a higher subscription tier. Upgrade your plan to access this and other premium features.
                </p>
                <Button 
                  onClick={handleUpgrade}
                  disabled={isActionLoading}
                >
                  {isActionLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                  ) : null}
                  Upgrade Now
                </Button>
              </AlertDescription>
            </Alert>
          </div>
        );
      }

      return <Navigate to="/reports" replace />;
    }

    return <WrappedComponent {...props} />;
  };
}

export function FeatureGate({ 
  feature, 
  children, 
  showUpgradePrompt = true 
}: { 
  feature: string; 
  children: React.ReactNode;
  showUpgradePrompt?: boolean;
}) {
  const { checkFeatureAccess, showUpgradeDialog } = useSubscriptionFeatures();
  const [isActionLoading, setIsActionLoading] = useState(false);
  const axiosClient = useContext(AxiosClientContext);
  const { toast } = useToast();
  const hasAccess = checkFeatureAccess(feature);

  const handleUpgrade = async () => {
    try {
      setIsActionLoading(true);
      const response = await axiosClient.get<{ url: string }>("/api/stripe/billing-session");
      
      if (response.data?.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      const axiosError = error as AxiosError<{ error: string }>;
      const errorMessage = axiosError.response?.data?.error || "Failed to access billing";
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsActionLoading(false);
    }
  };

  if (!hasAccess) {
    if (showUpgradePrompt) {
      return (
        <Alert>
          <Lock className="h-4 w-4" />
          <AlertTitle>Feature not available</AlertTitle>
          <AlertDescription>
            <p className="mb-4">
              This feature requires a higher subscription tier. Upgrade your plan to access this and other premium features.
            </p>
            <Button 
              onClick={handleUpgrade}
              disabled={isActionLoading}
            >
              {isActionLoading ? (
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
              ) : null}
              Upgrade Now
            </Button>
          </AlertDescription>
        </Alert>
      );
    }
    return null;
  }

  return <>{children}</>;
} 