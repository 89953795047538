import React, { useState } from "react";
import { useSubscriptionFeatures } from '../../../context/SubscriptionContext';
import { useSession } from '../../../context/SessionContext';
import { getInitials } from '../../../lib/utils';
import {
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Logo,
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@attrove/ui-shadcn";
import { Menu, X, ChevronDown } from "lucide-react";
import { useNavigate } from "react-router-dom";
import NotificationBell from "@attrove/attrove-ui/components/NotificationElements";
import { PremiumBadge } from "../../premium-badge";
import { SUBSCRIPTION_TIERS } from '@attrove/shared-types';

type MobileNavProps = {
  items: any[];
  onLogout: () => void;
  className?: string;
  notificationCount?: number;
  notifications?: any[];
  onMarkAsRead?: (id: string | null) => void;
};

export const MobileNav: React.FC<MobileNavProps> = ({
  items,
  onLogout,
  className,
  notificationCount = 0,
  notifications = [],
  onMarkAsRead = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const { isPro, isEnterprise } = useSubscriptionFeatures();
  const { user } = useSession();

  // Determine display name with fallback hierarchy
  const displayName = user.preferred_name || user.full_name || user.email;
  const secondaryText = (user.preferred_name || user.full_name) && user.email;

  const handleNavigation = (href: string) => {
    setOpen(false);
    navigate(href);
  };

  return (
    <div className={cn("md:hidden", className)}>
      <div className="fixed top-0 left-0 right-0 z-50 bg-background border-b border-border">
        <div className="flex justify-between items-center p-4">
          <Logo />
          <div className="flex items-center gap-2">
            <NotificationBell count={notificationCount} notifications={notifications} onMarkAsRead={onMarkAsRead} />
            <Button variant="ghost" size="icon" onClick={() => setOpen(!open)} aria-label={open ? "Close menu" : "Open menu"}>
              {open ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </Button>
          </div>
        </div>
      </div>

      <div
        className={cn(
          "fixed inset-0 top-[64px] z-40 bg-background transition-transform duration-300 ease-in-out transform",
          open ? "translate-x-0" : "translate-x-full",
        )}
      >
        <div className="h-full overflow-y-auto">
          <div className="px-4 py-6 flex flex-col h-full">
            <nav className="space-y-4 flex-grow">
              {items.map((item, index) => (
                <div key={index} className="pb-4">
                  <h4 className="mb-1 px-2 py-1 text-xs uppercase font-semibold">{item.title}</h4>
                  {item.items && (
                    <div className="space-y-1">
                      {item.items.map((subItem: any, subIndex: number) => (
                        <Button
                          key={subIndex}
                          variant="ghost"
                          className={cn("w-full justify-start", pathname === subItem.href ? "bg-accent" : "hover:bg-accent")}
                          onClick={() => handleNavigation(subItem.href)}
                        >
                          {subItem.title}
                        </Button>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </nav>
            <div className="mt-auto pt-6">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button 
                    variant="outline" 
                    className="w-full px-3 py-2 justify-start group min-h-[48px] hover:bg-accent/50 transition-colors"
                  >
                    <div className="flex items-center w-full">
                      <div className="relative">
                        <Avatar className="h-8 w-8 ring-2 ring-background">
                          {user.avatar_url ? (
                            <AvatarImage src={user.avatar_url} alt={displayName} />
                          ) : (
                            <AvatarFallback className="bg-primary/10 text-primary">
                              {getInitials(displayName)}
                            </AvatarFallback>
                          )}
                        </Avatar>
                        {(isPro || isEnterprise) && (
                          <div className="absolute -bottom-1.5 -right-1.5">
                            <PremiumBadge 
                              tier={isEnterprise ? SUBSCRIPTION_TIERS.ENTERPRISE : SUBSCRIPTION_TIERS.PRO} 
                              className="scale-90 origin-bottom-right shadow-sm" 
                            />
                          </div>
                        )}
                      </div>
                      <div className="ml-2 flex-1 min-w-0">
                        <div className="truncate text-sm font-medium">{displayName}</div>
                        {secondaryText && (
                          <div className="truncate text-xs text-muted-foreground">
                            {user.email}
                          </div>
                        )}
                      </div>
                      <ChevronDown className="h-4 w-4 text-muted-foreground/70 transition-transform duration-200 group-data-[state=open]:rotate-180" />
                    </div>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-[calc(100vw-2rem)] mx-4">
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={() => handleNavigation("/settings/profile")}>Settings</DropdownMenuItem>
                  <DropdownMenuItem onClick={() => handleNavigation("/settings/billing")}>Billing</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() => {
                      setOpen(false);
                      onLogout();
                    }}
                  >
                    Sign Out
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
