import {
  type AttroveSupabaseClient,
  CalendarWithEvents,
  Calendar,
  DB,
  IntegrationCredentials,
  integrationsInsertSchema,
  integrationsRowSchema,
  getEventCountsForIntegration
} from "@attrove/service-supabase";
import { logError, logInfo } from "@attrove/util-logs";
import { Logtail } from "@logtail/node";
import { z } from "zod";
import { Message } from "./messages";
import { MeetingRow } from "./meetings";
import { Conversation } from "./conversations";

export type IntegrationInsert = Omit<z.infer<typeof integrationsInsertSchema>, "id">;
export type Integration = z.infer<typeof integrationsRowSchema>;

export type IntegrationWithCredentials = Integration & { credentials: IntegrationCredentials[] };
export type IntegrationWithCalendars = Integration & { calendars: CalendarWithEvents[] };
export type IntegrationWithMessages = Integration & { messages: Message[] };


export enum GenericProvider {
  calendar = "calendar",
  meeting = "meeting",
  email = "email",
  chat = "chat",
}

export enum Provider{
  google_calendar = "google_calendar",
  google_meet = "google_meet",
  outlook = "outlook",
  slack = "slack",
  gmail = "gmail",
}

export enum ProviderOutput {
  message = "message",
  meeting = "meeting",
  event = "event",
}

export const ProviderToGenericProvider = {
  [Provider.outlook]: GenericProvider.email,
  [Provider.gmail]: GenericProvider.email,

  [Provider.slack]: GenericProvider.chat,

  [Provider.google_calendar]: GenericProvider.calendar,

  [Provider.google_meet]: GenericProvider.meeting,
}

export const ProviderToOutput = {
  [GenericProvider.email]: ProviderOutput.message,
  [GenericProvider.chat]: ProviderOutput.message,
  [Provider.outlook]: ProviderOutput.message,
  [Provider.slack]: ProviderOutput.message,
  [Provider.gmail]: ProviderOutput.message,

  [GenericProvider.calendar]: ProviderOutput.event,
  [Provider.google_calendar]: ProviderOutput.event,

  [GenericProvider.meeting]: ProviderOutput.meeting,
  [Provider.google_meet]: ProviderOutput.meeting,
};

export interface IntegrationBase {
  id: number;
  type: Provider;
  type_generic: GenericProvider;
}

export interface IntegrationData extends IntegrationBase {
  data: any[]; // messages or events
  user_name?: string;
  timezone?: string;
}

export interface IntegrationWithCalendarsAndCounts extends Integration {
  calendars: (Calendar & { event_count?: number })[];
  total_events: number;
}

export const insertIntegration = async (
  supabaseClient: AttroveSupabaseClient,
  integrationData: IntegrationInsert,
  logtail?: Logtail
): Promise<Integration> => {
  integrationsInsertSchema.parse(integrationData);

  try {
    // Check integration limit before proceeding
    const { data: canAdd } = await supabaseClient
      .rpc('check_integration_limit', {
        p_user_id: integrationData.user_id
      });

    if (!canAdd) {
      const error = new Error('Integration limit reached for your subscription tier');
      error.name = 'IntegrationLimitError';
      throw error;
    }

    // Start a transaction
    const { data: integration, error: insertIntegrationError } = await supabaseClient
      .rpc('insert_integration', {integration_data: integrationData});

    if (insertIntegrationError) {
      throw insertIntegrationError;
    }

    logInfo(
      "Successfully inserted integration",
      { integrationId: integration.id, userId: integrationData.user_id },
      logtail
    );

    return integration;
  } catch (error) {
    logError(
      "Error inserting integration",
      { error, integrationData },
      logtail
    );
    throw error;
  }
};

export const getIntegrationsWithCredByUserId = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
): Promise<IntegrationWithCredentials[]> => {
  const { data: Integrations, error: getIntegrationsError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .select("*, credentials:integrations_credentials(*)")
    .eq("user_id", userId);

  if (getIntegrationsError) {
    console.error(getIntegrationsError.message);
    throw getIntegrationsError;
  }
  return Integrations;
};

export const GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY = "getIntegrationsWithCredById";
export const getIntegrationsWithCredById = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
): Promise<IntegrationWithCredentials | null> => {
  const { data: integrations, error: getIntegrationsError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .select("*, credentials:integrations_credentials(*)")
    .eq("id", integrationId)
    .single();
  if (getIntegrationsError) {
    logError("error getting integrations with cred by id",{integrationId,error: getIntegrationsError});
    return null;
  }
  return integrations;
};

export const GET_INTEGRATIONS_WITH_CALS_BY_ID_QUERY_KEY = "getIntegrationsWithCalsById";
export const getIntegrationsWithCalsById = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
): Promise<IntegrationWithCalendars | null> => {
  const { data: integration, error: getIntegrationError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .select(
      `
    *,
    calendars:${DB.CALENDARS} (
      *,
      events:${DB.EVENTS}!calendar_id_fkey (*)
    )
  `,
    )
    .eq("id", integrationId)
    .single();

  if (getIntegrationError) {
    logError("Error getting integration with calendars and events by id",{integrationId,error: getIntegrationError});
    return null;
  }

  return integration;
};

export interface IntegrationWithConversations extends Integration {
  conversations: Array<Conversation & { message_count: number }>;
}

export const GET_CONVERSATIONS_FOR_INTEGRATION_QUERY_KEY = "getConversationsForIntegration";
export const getConversationsForIntegration = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
): Promise<Conversation[] | null> => {
  const { data: conversations, error: getConversationsError } = await supabaseClient
    .from(DB.CONVERSATIONS)
    .select('*')
    .eq("integration_id", integrationId);

  if (getConversationsError) {
    logError("Error getting conversations for integration",{integrationId,error: getConversationsError});
    return null;
  }

  return conversations;
};

// Updated function to take in a "provider" which can be used to select integrations for a specific provider
export const getIntegrationsDueForSync = async (
  supabaseClient: AttroveSupabaseClient,
  provider?: Provider,
): Promise<IntegrationWithCredentials[] | null> => {
  let query = supabaseClient.from(DB.INTEGRATIONS).select("*, credentials:integrations_credentials(*)");

  if (provider) {
    query = query.eq("provider", provider);
  }

  // Make sure to honor the is_active flag
  query = query.eq("is_active", true);

  const { data: Integrations, error: getIntegrationsError } = await query;

  if (getIntegrationsError) {
    logError( "error getting integrations due for sync",{error: getIntegrationsError, provider: provider || "all"});
    return null;
  }

  return Integrations;
};

export const getNewIntegrations = async (supabaseClient: AttroveSupabaseClient): Promise<IntegrationWithCredentials[] | null> => {
  const { data: Integrations, error: getIntegrationsError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .select("*, credentials:integrations_credentials(*)")
    .is("last_synced_at", null);

  if (getIntegrationsError) {
    logError("error getting new integrations", {error: getIntegrationsError});
    return null;
  }
  return Integrations;
};

export const INTEGRATIONS_QUERY_KEY = "integrations";

export const getIntegrations = async (supabaseClient: AttroveSupabaseClient, logtail?: Logtail): Promise<Integration[] | null> => {
  const { data: Integrations, error: getIntegrationsError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .select("*")
    .order("created_at", { ascending: false });

  if (getIntegrationsError) {
    logError(
      "error getting integrations",
      {
        error: getIntegrationsError,
      },
      logtail,
    );
    return null;
  }
  return Integrations;
};

export const getIntegrationsWithMessages = async (
  supabaseClient: AttroveSupabaseClient,
  logtail?: Logtail,
): Promise<IntegrationWithMessages[] | null> => {
  const { data: Integrations, error: getIntegrationsError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .select("*, messages(*)")
    .is("pending_sync", false)
    .order("created_at", { ascending: false });

  if (getIntegrationsError) {
    logError(
      "error getting integrations with messages",
      {
        error: getIntegrationsError,
      },
      logtail,
    );
    return null;
  }
  return Integrations;
};

export const getIntegrationsWithMessagesByUserIds = async (
  userIds: string[],
  supabaseClient: AttroveSupabaseClient,
  logtail?: Logtail,
): Promise<IntegrationWithMessages[] | null> => {
  const { data: Integrations, error: getIntegrationsError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .select("*, messages(*)")
    .is("pending_sync", false)
    .in("user_id", userIds)
    .order("created_at", { ascending: false });

  if (getIntegrationsError) {
    logError(
      "error getting integrations with messages by user ids",
      {
        error: getIntegrationsError,
        userIds,
      },
      logtail,
    );
    return null;
  }
  return Integrations;
};

export const GET_INTEGRATION_BY_USERID_QUERY_KEY = "getIntegrationsByUserId";
export const getIntegrationsByUserId = async (supabaseClient: AttroveSupabaseClient, userId: string): Promise<Integration[]> => {
  const { data: Integrations, error: getIntegrationsError } = await supabaseClient.from(DB.INTEGRATIONS).select("*").eq("user_id", userId);

  if (getIntegrationsError) {
    console.error(getIntegrationsError.message);
    throw getIntegrationsError;
  }
  return Integrations;
};

export const updateIntegration = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  integrationData: Partial<IntegrationInsert>,
): Promise<Integration | null> => {
  const { data: Integration, error: updateIntegrationError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .update(integrationData)
    .eq("id", integrationId)
    .select()
    .single();

  if (updateIntegrationError) {
    logError("error updating integration", { integrationId, error: updateIntegrationError });
    return null;
  }
  return Integration;
};

export const toggleActive = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  active: boolean,
  logtail?: Logtail,
): Promise<Integration | null> => {
  const { data: Integration, error: updateIntegrationError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .update({ is_active: active })
    .eq("id", integrationId)
    .select()
    .single();

  if (updateIntegrationError) {
    logError(
      "error updating integration",
      {
        integrationId,
        error: updateIntegrationError,
      },
      logtail,
    );
    return null;
  }
  return Integration;
};

export const deleteIntegration = async (supabaseClient: AttroveSupabaseClient, integrationId: number): Promise<void> => {
  // First, call an RPC function to terminate any active connections for this integration
  await supabaseClient.rpc('terminate_integration_connections', { integration_id: integrationId });

  // Then attempt the delete
  const { error: deleteIntegrationError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .delete()
    .eq("id", integrationId);

  if (deleteIntegrationError) {
    console.error(deleteIntegrationError.message);
    throw deleteIntegrationError;
  }
};

export async function getUserIntegrations(supabaseClient: AttroveSupabaseClient): Promise<Map<string, number[]>> {
  try {
    const { data: integrations, error } = await supabaseClient.from(DB.INTEGRATIONS).select("id, user_id").eq("is_active", true);

    if (error) {
      throw new Error(`Error fetching integrations: ${error.message}`);
    }

    const userIntegrations = new Map<string, number[]>();
    integrations.forEach((integration: { id: number; user_id: string }) => {
      const integrationIds = userIntegrations.get(integration.user_id) || [];
      integrationIds.push(integration.id);
      userIntegrations.set(integration.user_id, integrationIds);
    });

    return userIntegrations;
  } catch (error) {
    logError("Error fetching user integrations", { error: error instanceof Error ? error.message : "Unknown error" });
    throw error;
  }
}

export async function getIntegrationsForUser(supabaseClient: AttroveSupabaseClient, userId: string): Promise<IntegrationData[]> {
  logInfo(`Fetching integrations for user ${userId}`);

  try {
    // Fetch user data
    const { data: userData, error: userError } = await supabaseClient
      .from(DB.USERS)
      .select('full_name, timezone')
      .eq('id', userId)
      .single();

    if (userError) throw new Error(`Error fetching user data: ${userError.message}`);

    // Fetch basic integration data
    const { data: integrationsData, error: integrationsError } = await supabaseClient
      .from(DB.INTEGRATIONS)
      .select('id, type, type_generic')
      .eq('user_id', userId)
      .eq('is_active', true) as {
        data: IntegrationBase[] | null;
        error: any;
      };

    if (integrationsError) throw new Error(`Error fetching integrations: ${integrationsError.message}`);

    if (!integrationsData || integrationsData.length === 0) {
      logInfo(`No active integrations found for user ${userId}`);
      return [];
    }

    // Fetch additional data for each integration type
    const integrationsWithData = await Promise.all(integrationsData.map(async (integration) => {
      let data: any[] = [];
      if (integration.type_generic === GenericProvider.email || integration.type_generic === GenericProvider.chat) {
        const { data: messages, error: messagesError } = await supabaseClient
          .from(DB.MESSAGES)
          .select('id')
          .eq('integration_id', integration.id);
        if (messagesError) throw new Error(`Error fetching messages: ${messagesError.message}`);
        data = messages || [];
      } else if (integration.type_generic === GenericProvider.calendar) {
        const { data: calendars, error: calendarsError } = await supabaseClient
          .from(DB.CALENDARS)
          .select('id')
          .eq('integration_id', integration.id);
        if (calendarsError) throw new Error(`Error fetching calendars: ${calendarsError.message}`);

        const eventsPromises = calendars.map(calendar =>
          supabaseClient
            .from(DB.EVENTS)
            .select('id')
            .eq('calendar_id', calendar.id)
        );
        const eventsResults = await Promise.all(eventsPromises);
        data = eventsResults.flatMap(result => result.data || []);
      }

      return {
        ...integration,
        data,
        user_name: userData.full_name || undefined,
        timezone: userData.timezone || undefined
      } as IntegrationData;
    }));

    logInfo(`Successfully fetched ${integrationsWithData.length} integrations for user ${userId}`);

    return integrationsWithData;
  } catch (error) {
    logError(`Error fetching integrations for user ${userId}`, { error: error instanceof Error ? error.message : 'Unknown error' });
    throw error;
  }
}

export const updateIntegrationHistoryId = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  historyId: string,
): Promise<Integration | null> => {
  logInfo(`Updating Gmail history ID`, { integrationId, historyId });

  const { data: updatedIntegration, error: updateError } = await supabaseClient
    .from(DB.INTEGRATIONS)
    .update({ history_id: historyId })
    .eq("id", integrationId)
    .select()
    .single();

  if (updateError) {
    logError(`Error updating Gmail history ID`, { integrationId, historyId, error: updateError });
    return null;
  }

  logInfo(`Successfully updated Gmail history ID`, { integrationId, historyId });
  return updatedIntegration;
};

export const getLastSyncTimeForIntegration = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
): Promise<string | null> => {
  try {
    logInfo(`Fetching last sync time for integration ${integrationId}`);

    const { data, error } = await supabaseClient
      .from(DB.INTEGRATIONS)
      .select('last_synced_at')
      .eq('id', integrationId)
      .single();

    if (error) {
      throw new Error(`Error fetching last sync time: ${error.message}`);
    }

    if (!data || !data.last_synced_at) {
      logInfo(`No last sync time found for integration ${integrationId}`);
      return null;
    }

    logInfo(`Successfully fetched last sync time for integration ${integrationId}`, { lastSyncTime: data.last_synced_at });
    return data.last_synced_at;
  } catch (error) {
    logError(`Error fetching last sync time for integration ${integrationId}`, { error: error instanceof Error ? error.message : "Unknown error" });
    throw error;
  }
};

export const updateLastSyncTimeForIntegration = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  lastSyncTime: string,
): Promise<void> => {
  try {
    logInfo(`Updating last sync time for integration ${integrationId}`, { lastSyncTime });

    const { error } = await supabaseClient
      .from(DB.INTEGRATIONS)
      .update({ last_synced_at: lastSyncTime })
      .eq('id', integrationId);

    if (error) {
      throw new Error(`Error updating last sync time: ${error.message}`);
    }

    logInfo(`Successfully updated last sync time for integration ${integrationId}`, { lastSyncTime });
  } catch (error) {
    logError(`Error updating last sync time for integration ${integrationId}`, { error: error instanceof Error ? error.message : "Unknown error", lastSyncTime });
    throw error;
  }
};

export type IntegrationWithMeetings = Integration & { meetings: MeetingRow[] };

export const GET_MEETINGS_BY_INTEGRATION_ID_QUERY_KEY = "getMeetingsByIntegrationId";

export const getMeetingsByIntegrationId = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
): Promise<MeetingRow[] | null> => {

  try {
    const { data: meetings, error: getMeetingsError } = await supabaseClient
      .from(DB.MEETINGS)
      .select("*")
      .eq("integration_id", integrationId);

    if (getMeetingsError) {
      throw new Error(`Error fetching meetings: ${getMeetingsError.message}`);
    }

    if (!meetings || meetings.length === 0) {
      return [];
    }


    return meetings;
  } catch (error) {
    logError(`Error fetching meetings for integration ${integrationId}`, { error: error instanceof Error ? error.message : "Unknown error" });
    throw error;
  }
};

export const getActiveIntegrationsForUsers = async (
  supabaseClient: AttroveSupabaseClient,
  userIds: string[],
): Promise<Integration[]> => {
  try {
    const { data: integrations, error } = await supabaseClient
      .from(DB.INTEGRATIONS)
      .select('*')
      .eq('is_active', true)
      .in('user_id', userIds);

    if (error) {
      logError("Error fetching active integrations for users", { error, userIds });
      return [];
    }

    return integrations || [];
  } catch (error) {
    logError("Unexpected error fetching active integrations", { error, userIds });
    return [];
  }
};

export const getInactiveIntegrationsForUsers = async (
  supabaseClient: AttroveSupabaseClient,
  userIds: string[],
  logtail?: Logtail,
): Promise<Integration[]> => {
  try {
    const { data: integrations, error } = await supabaseClient
      .from(DB.INTEGRATIONS)
      .select('*')
      .eq('is_active', false)
      .in('user_id', userIds);

    if (error) {
      logError("Error fetching inactive integrations for users", { error, userIds }, logtail);
      return [];
    }

    return integrations || [];
  } catch (error) {
    logError("Unexpected error fetching inactive integrations", { error, userIds }, logtail);
    return [];
  }
};

export const deactivateIntegrationsBatch = async (
  supabaseClient: AttroveSupabaseClient,
  integrationIds: number[],
  logtail?: Logtail,
): Promise<boolean> => {
  try {
    const { error } = await supabaseClient
      .from(DB.INTEGRATIONS)
      .update({ is_active: false })
      .in('id', integrationIds);

    if (error) {
      logError("Error deactivating integrations batch", { error, integrationIds }, logtail);
      return false;
    }

    return true;
  } catch (error) {
    logError("Unexpected error deactivating integrations", { error, integrationIds }, logtail);
    return false;
  }
};

export const deleteIntegrationsBatch = async (
  supabaseClient: AttroveSupabaseClient,
  integrationIds: number[],
  logtail?: Logtail,
): Promise<boolean> => {
  try {
    const { error } = await supabaseClient
      .from(DB.INTEGRATIONS)
      .delete()
      .in('id', integrationIds);

    if (error) {
      logError("Error deleting integrations batch", { error, integrationIds }, logtail);
      return false;
    }

    return true;
  } catch (error) {
    logError("Unexpected error deleting integrations", { error, integrationIds }, logtail);
    return false;
  }
};

export const getIntegrationsWithCalendarCounts = async (
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  logtail?: Logtail,
): Promise<IntegrationWithCalendarsAndCounts | null> => {
  try {
    // Get integration with calendars (but no events)
    const { data: integration, error: integrationError } = await supabaseClient
      .from(DB.INTEGRATIONS)
      .select(`
        *,
        calendars:${DB.CALENDARS}(*)
      `)
      .eq("id", integrationId)
      .single();

    if (integrationError) {
      logError(
        "Error getting integration with calendars",
        {
          integrationId,
          error: integrationError,
        },
        logtail,
      );
      return null;
    }

    // Get event counts
    const eventCounts = await getEventCountsForIntegration(supabaseClient, integrationId, {
      includeHistorical: false, // Only get current/future events
      logtail
    });

    if (!eventCounts) {
      return {
        ...integration,
        calendars: integration.calendars,
        total_events: 0
      };
    }

    // Map counts to calendars
    const calendarsWithCounts = integration.calendars.map((calendar: Calendar) => ({
      ...calendar,
      event_count: eventCounts.calendar_counts.find(
        cc => cc.calendar_id === calendar.id
      )?.event_count || 0
    }));

    return {
      ...integration,
      calendars: calendarsWithCounts,
      total_events: eventCounts.total_events
    };
  } catch (error) {
    logError(
      "Error getting integration with calendar counts",
      {
        error: error instanceof Error ? error.message : "Unknown error",
        integrationId,
      },
      logtail,
    );
    return null;
  }
};