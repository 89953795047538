import { supabase } from "@attrove/attrove-ui/app/supabase";
import { toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import {
  GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY,
  getIntegrationsWithCredById,
  getMessageCountForIntegration,
  MESSAGES_COUNT_QUERY_KEY
} from "@attrove/service-supabase";
import { Icons, Card, Skeleton } from "@attrove/ui-shadcn";
import { useQuery } from "@tanstack/react-query";
import { Loader2, Mail, Calendar, Clock } from "lucide-react";
import { IntegrationActions } from "../integration-actions";

type EmailProvider = 'gmail' | 'outlook';

const EMAIL_PROVIDER_CONFIG: Record<EmailProvider, { title: string; icon: keyof typeof Icons }> = {
  gmail: { title: 'Gmail', icon: 'gmail' },
  outlook: { title: 'Outlook', icon: 'outlook' },
};

export const EmailDetail: React.FC<{ integrationId: number }> = ({ integrationId }) => {
  const { data: integration, isLoading } = useQuery({
    queryKey: [GET_INTEGRATIONS_WITH_CREDS_BY_ID_QUERY_KEY, integrationId],
    queryFn: () => getIntegrationsWithCredById(supabase, Number(integrationId)),
  });

  const { data: messageCount, isLoading: messagesLoading } = useQuery({
    queryKey: [MESSAGES_COUNT_QUERY_KEY, integrationId],
    queryFn: () => getMessageCountForIntegration(supabase, integrationId),
  });

  if (isLoading || messagesLoading) {
    return (
      <div className="pt-6">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <Skeleton className="h-8 w-48 mb-4" />
          <Skeleton className="h-32 w-full" />
        </div>
      </div>
    );
  }

  if (!integration || !messageCount) return null;

  const emailProvider = (integration.type?.toLowerCase() as EmailProvider) || 'gmail';
  const config = EMAIL_PROVIDER_CONFIG[emailProvider] || EMAIL_PROVIDER_CONFIG.gmail;
  const IconComponent = Icons[config.icon];

  return (
    <div className="pt-6">
      {/* Header Section */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 mb-8">
        <div className="flex items-center gap-4 mb-6">
          <div className="h-12 w-12 rounded-lg bg-primary/5 flex items-center justify-center">
            <IconComponent className="w-8 h-8 text-primary" />
          </div>
          <div>
            <h1 className="text-2xl font-semibold">{config.title} Integration</h1>
            <p className="text-muted-foreground">
              Connected as {integration.name}
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="grid grid-cols-1 gap-6">
          {/* Integration Status Card */}
          <Card className="p-6">
            <div className="flex justify-between items-start mb-6">
              <h2 className="text-lg font-medium">Integration Status</h2>
              <div className={`px-2 py-1 rounded-full text-xs ${integration.is_active ? 'bg-green-500/10 text-green-500' : 'bg-yellow-500/10 text-yellow-500'}`}>
                {integration.is_active ? 'Active' : 'Inactive'}
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center gap-2 text-muted-foreground">
                <Mail className="w-4 h-4" />
                <span>Email: {integration.name}</span>
              </div>
              <div className="flex items-center gap-2 text-muted-foreground">
                <Calendar className="w-4 h-4" />
                <span>Syncing since {new Date(integration.created_at).toLocaleDateString()}</span>
              </div>
              <div className="flex items-center gap-2 text-muted-foreground">
                <Mail className="w-4 h-4" />
                <span>{messageCount.toLocaleString()} messages imported</span>
              </div>
              
              {integration.last_synced_at && (
                <div className="flex items-center gap-2 text-muted-foreground">
                  {integration.pending_sync ? (
                    <div className="flex items-center gap-2">
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Currently syncing...</span>
                    </div>
                  ) : (
                    <>
                      <Clock className="w-4 h-4" />
                      <span>Last synced {toTimeAgo(integration.last_synced_at)}</span>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="mt-6">
              <IntegrationActions integration={integration} />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};