import { useContext, useState, useEffect } from "react";
import { 
  Alert, 
  AlertDescription, 
  Button, 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle, 
  Progress, 
  useToast,
  Separator
} from "@attrove/ui-shadcn";
import { formatStripeDate } from "@attrove/util-formatting";
import { 
  CreditCard, 
  Loader2, 
  RefreshCw, 
  CheckCircle,
  Infinity,
  Zap,
  Users,
  Lock,
  Building2,
  ShieldCheck,
  Headphones,
  Gauge,
  Mail
} from "lucide-react";
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { useSubscription } from "../../../../hooks/useSubscription";
import type { SubscriptionTier } from '@attrove/shared-types';
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import axios, { AxiosError } from 'axios';

type TierFeatures = {
  features: string[];
  cta: {
    text: string;
    variant: "default" | "outline";
  };
};

const TIER_FEATURES: Record<SubscriptionTier, TierFeatures> = {
  free: {
    features: [
      "Sync up to 5 communication channels",
      "Basic integrations only",
      "Daily rundown generation",
      "Email support (response within 48 hours)",
    ],
    cta: {
      text: "Current Plan",
      variant: "outline"
    }
  },
  pro: {
    features: [
      "Connect unlimited integrations",
      "Access to premium integrations",
      "Team collaboration features (up to 10 members)",
      "Priority email support (response within 24 hours)",
    ],
    cta: {
      text: "Manage Subscription",
      variant: "outline"
    }
  },
  enterprise: {
    features: [
      "All Pro features",
      "Custom integrations",
      "Unlimited team members",
      "Custom deployment options",
      "Enterprise-grade security",
      "24/7 priority support"
    ],
    cta: {
      text: "Contact Sales",
      variant: "outline"
    }
  }
} as const;

// Updated PRO_BENEFITS with Lucide icons
const PRO_BENEFITS = [
  {
    title: "Unlimited Integrations",
    description: "Connect all your communication channels without limits",
    icon: Infinity
  },
  {
    title: "Priority Support",
    description: "Get help when you need it with 24-hour response time",
    icon: Zap
  },
  {
    title: "Team Features",
    description: "Collaborate seamlessly with your entire team",
    icon: Users
  },
  {
    title: "Premium Integrations",
    description: "Access advanced integration options and customizations",
    icon: Lock
  }
];

// Updated Enterprise benefits
const ENTERPRISE_BENEFITS = [
  {
    title: "Unlimited Team Size",
    description: "Add unlimited team members to your workspace",
    icon: Users
  },
  {
    title: "Custom Deployment",
    description: "Deploy in your own infrastructure with full control",
    icon: Building2
  },
  {
    title: "Enterprise Security",
    description: "Advanced security features and compliance controls",
    icon: ShieldCheck
  },
  {
    title: "24/7 Support",
    description: "Round-the-clock dedicated support team",
    icon: Headphones
  }
];

export const Billing: React.FC = () => {
  const { 
    subscription,
    usage,
    isLoading: isSubscriptionLoading,
    isPro,
    isEnterprise,
    refetch: refetchSubscription,
    currentTier,
    error: subscriptionError
  } = useSubscription();
  
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [upgradeStatus, setUpgradeStatus] = useState<'success' | 'cancelled' | null>(null);
  const { toast } = useToast();
  const axiosClient = useContext(AxiosClientContext);

  // Add query for integrations
  const { data: integrations } = useQuery({
    queryKey: ['integrations'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('integrations')
        .select('*');
      
      if (error) throw error;
      return data;
    }
  });

  const integrationCount = integrations?.length || 0;
  const integrationLimit: number = currentTier === 'free' ? 5 : Number.POSITIVE_INFINITY;
  const integrationUsagePercentage = Math.min((integrationCount / (integrationLimit === Number.POSITIVE_INFINITY ? 100 : integrationLimit)) * 100, 100);

  // Check URL parameters for upgrade status
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const upgrade = params.get('upgrade');
    if (upgrade === 'success' || upgrade === 'cancelled') {
      setUpgradeStatus(upgrade);
      window.history.replaceState({}, '', window.location.pathname);
      
      if (upgrade === 'success') {
        toast({
          title: "Upgrade Successful!",
          description: "Welcome to Pro! Your new features are now available.",
          duration: 5000,
        });
        refetchSubscription();
      }
    }
  }, [toast, refetchSubscription]);

  const handleBillingAccess = async () => {
    try {
      setIsActionLoading(true);
      const response = await axiosClient.get<{ url: string }>("/api/stripe/billing-session");
      
      if (response.data?.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      const axiosError = error as AxiosError<{ error: string }>;
      const errorMessage = axiosError.response?.data?.error || "Failed to access billing";
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsActionLoading(false);
    }
  };

  const handleEnterpriseSupport = () => {
    const subject = encodeURIComponent("Enterprise Support Request");
    const body = encodeURIComponent(
      "Hi Support Team,\n\n" +
      "I need assistance with:\n\n" +
      "[Please describe your issue here]\n\n" +
      "Best regards"
    );
    window.location.href = `mailto:support@attrove.com?subject=${subject}&body=${body}`;
  };

  if (isSubscriptionLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  const handleEnterpriseContact = () => {
    const subject = encodeURIComponent("Enterprise Plan Inquiry");
    const body = encodeURIComponent(
      "Hi,\n\nI'm interested in learning more about Attrove's Enterprise plan. " +
      "Please provide information about:\n\n" +
      "- Custom deployment options\n" +
      "- Enterprise security features\n" +
      "- SLA requirements\n" +
      "- Pricing for our team size\n\n" +
      "Best regards"
    );
    window.location.href = `mailto:sales@attrove.com?subject=${subject}&body=${body}`;
  };

  if (isSubscriptionLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  // Show success message if upgrade just completed
  if (upgradeStatus === 'success') {
    return (
      <Card className="max-w-2xl mx-auto mt-8">
        <CardHeader>
          <div className="flex items-center space-x-2">
            <CheckCircle className="h-6 w-6 text-green-500" />
            <CardTitle>Welcome to Pro!</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <p className="text-lg">Your upgrade was successful! You now have access to:</p>
            <ul className="list-disc pl-5 space-y-2 text-sm text-muted-foreground">
              {TIER_FEATURES.pro.features.map((feature: string, index: number) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <Button 
              onClick={() => setUpgradeStatus(null)}
              className="mt-4"
            >
              View Billing Dashboard
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Main billing content - now handles both subscription and no-subscription cases
  const tierFeatures = TIER_FEATURES[currentTier];
  // Safely handle missing usage data
  const usagePercentage = usage ? Math.min((usage.used / usage.limit) * 100, 100) : 0;

  return (
    <div className="flex flex-col gap-4">
      <div className="max-w-2xl w-full">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-xl font-bold">Billing</h1>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={() => refetchSubscription()}
            disabled={isSubscriptionLoading}
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${isSubscriptionLoading ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
        </div>
      </div>

      {subscriptionError && (
        <Alert className="max-w-2xl">
          <AlertDescription>
            There was an issue loading your subscription details. Please try refreshing the page.
          </AlertDescription>
        </Alert>
      )}

      <Card className="max-w-2xl">
        <CardHeader>
          <div className="flex items-center justify-between">
            <div>
              <CardTitle>{currentTier === 'enterprise' ? "Enterprise Plan" : isPro ? "Pro Plan" : "Free Plan"}</CardTitle>
              <p className="text-sm text-muted-foreground mt-1">
                {currentTier === 'enterprise' ? "Custom enterprise solution" : 
                 isPro ? `Renews on ${formatStripeDate(subscription?.currentPeriodEnd)}` : 
                 "Limited features"}
              </p>
            </div>
            {subscription?.prices?.unit_amount && !isEnterprise && (
              <div className="text-right">
                <p className="text-2xl font-bold">
                  ${(subscription.prices.unit_amount / 100).toFixed(2)}
                </p>
                <p className="text-sm text-muted-foreground">
                  per {subscription.prices.interval}
                </p>
              </div>
            )}
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <ul className="list-disc pl-5 space-y-2 text-sm text-muted-foreground">
              {tierFeatures.features.map((feature: string, index: number) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>

            {subscription?.cancelAtPeriodEnd && (
              <Alert>
                <AlertDescription>
                  Your subscription will be canceled on {formatStripeDate(subscription.currentPeriodEnd)}
                </AlertDescription>
              </Alert>
            )}

            {(isPro || isEnterprise) && (
              <div className="flex justify-end">
                <Button
                  onClick={isEnterprise ? handleEnterpriseSupport : handleBillingAccess}
                  variant="outline"
                  disabled={isActionLoading}
                >
                  {isActionLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                  ) : isEnterprise ? (
                    <Mail className="h-4 w-4 mr-2" />
                  ) : (
                    <CreditCard className="h-4 w-4 mr-2" />
                  )}
                  {isEnterprise ? "Contact Support" : "Manage Subscription"}
                </Button>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      {!isSubscriptionLoading && !subscriptionError && (
        <Card className="max-w-2xl">
          <CardHeader>
            <CardTitle>Integration Usage</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <div className="space-y-1">
                  <div className="text-2xl font-bold">{integrationCount}</div>
                  <p className="text-sm text-muted-foreground">Active integrations</p>
                </div>
                {currentTier === 'free' ? (
                  <div className="text-sm text-muted-foreground text-right">
                    <p>{5 - integrationCount} remaining</p>
                    <p>5 total allowed</p>
                  </div>
                ) : (
                  <div className="flex items-center text-sm text-muted-foreground">
                    <Infinity className="h-4 w-4 mr-2" />
                    <span>Unlimited integrations available</span>
                  </div>
                )}
              </div>
              
              {currentTier === 'free' && (
                <>
                  <Progress value={integrationUsagePercentage} />
                  {integrationCount >= 4 && (
                    <Alert className="mt-2 border-yellow-200 bg-yellow-50 text-yellow-800">
                      <AlertDescription>
                        {integrationCount === 5 ? 
                          "You've reached your integration limit. " :
                          "You're approaching your integration limit. "}
                        <Button 
                          variant="link" 
                          className="h-auto p-0 ml-1 text-yellow-800 hover:text-yellow-900"
                          onClick={handleBillingAccess}
                        >
                          Upgrade to Pro
                        </Button>
                      </AlertDescription>
                    </Alert>
                  )}
                </>
              )}
            </div>
          </CardContent>
        </Card>
      )}

      {/* Pro Benefits Card - Only show for free users */}
      {!isPro && !isEnterprise && (
        <Card className="max-w-2xl border-2 border-primary/20">
          <CardHeader>
            <div className="space-y-2">
              <CardTitle className="text-primary">Upgrade to Pro</CardTitle>
              <div className="flex items-baseline space-x-2">
                <span className="text-3xl font-bold">$20</span>
                <span className="text-muted-foreground">/month</span>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4 md:grid-cols-2">
              {PRO_BENEFITS.map((benefit, index) => (
                <div key={index} className="flex items-start space-x-3 p-4 rounded-lg bg-primary/5">
                  <benefit.icon className="h-5 w-5 text-primary mt-0.5" />
                  <div>
                    <h3 className="font-medium">{benefit.title}</h3>
                    <p className="text-sm text-muted-foreground">{benefit.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <Button
              onClick={handleBillingAccess}
              variant="default"
              className="w-full mt-6"
              disabled={isActionLoading}
            >
              {isActionLoading ? (
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
              ) : (
                <Zap className="h-4 w-4 mr-2" />
              )}
              Upgrade Now
            </Button>
          </CardContent>
        </Card>
      )}

      {/* Enterprise Section - Show for free and pro users */}
      {!isEnterprise && (
        <Card className="max-w-2xl">
          <CardHeader>
            <div className="space-y-2">
              <CardTitle className="flex items-center space-x-2">
                <Building2 className="h-5 w-5" />
                <span>Enterprise</span>
              </CardTitle>
              <p className="text-sm text-muted-foreground">
                Custom solutions for larger teams and organizations
              </p>
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid gap-4 md:grid-cols-2">
              {ENTERPRISE_BENEFITS.map((benefit, index) => (
                <div key={index} className="flex items-start space-x-3 p-4 rounded-lg bg-muted/10">
                  <benefit.icon className="h-5 w-5 text-muted-foreground mt-0.5" />
                  <div>
                    <h3 className="font-medium">{benefit.title}</h3>
                    <p className="text-sm text-muted-foreground">{benefit.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <Button
              onClick={handleEnterpriseContact}
              variant="outline"
              className="w-full mt-6"
            >
              <Mail className="h-4 w-4 mr-2" />
              Contact Sales
            </Button>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Billing;