// Generated by ts-to-zod
import { z } from "zod";
import { Json } from "./../types/supabase-schema-public-types";

export const jsonSchema: z.ZodSchema<Json> = z.lazy(() =>
  z.union([z.string(), z.number(), z.boolean(), z.record(z.union([jsonSchema, z.undefined()])), z.array(jsonSchema)]).nullable(),
);

export const calendarsRowSchema = z.object({
  active: z.boolean(),
  calendar_id_3p: z.string(),
  created_at: z.string(),
  description: z.string().nullable(),
  id: z.number(),
  integration_id: z.number(),
  last_checked: z.string().nullable(),
  sync_token: z.string().nullable(),
  timezone: z.string(),
  title: z.string(),
});

export const calendarsInsertSchema = z.object({
  active: z.boolean().optional(),
  calendar_id_3p: z.string(),
  created_at: z.string().optional(),
  description: z.string().optional().nullable(),
  id: z.never().optional(),
  integration_id: z.number(),
  last_checked: z.string().optional().nullable(),
  sync_token: z.string().optional().nullable(),
  timezone: z.string(),
  title: z.string(),
});

export const calendarsUpdateSchema = z.object({
  active: z.boolean().optional(),
  calendar_id_3p: z.string().optional(),
  created_at: z.string().optional(),
  description: z.string().optional().nullable(),
  id: z.never().optional(),
  integration_id: z.number().optional(),
  last_checked: z.string().optional().nullable(),
  sync_token: z.string().optional().nullable(),
  timezone: z.string().optional(),
  title: z.string().optional(),
});

export const calendarsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("calendars_integration_id_fkey"),
    columns: z.tuple([z.literal("integration_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("integrations"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const conversationsRowSchema = z.object({
  active: z.boolean(),
  conversation_id_3p: z.string(),
  conversation_type: z.string(),
  created_at: z.string(),
  id: z.number(),
  import_messages: z.boolean(),
  integration_id: z.number(),
  last_checked: z.string(),
  title: z.string(),
});

export const conversationsInsertSchema = z.object({
  active: z.boolean().optional(),
  conversation_id_3p: z.string(),
  conversation_type: z.string(),
  created_at: z.string().optional(),
  id: z.never().optional(),
  import_messages: z.boolean().optional(),
  integration_id: z.number(),
  last_checked: z.string().optional(),
  title: z.string(),
});

export const conversationsUpdateSchema = z.object({
  active: z.boolean().optional(),
  conversation_id_3p: z.string().optional(),
  conversation_type: z.string().optional(),
  created_at: z.string().optional(),
  id: z.never().optional(),
  import_messages: z.boolean().optional(),
  integration_id: z.number().optional(),
  last_checked: z.string().optional(),
  title: z.string().optional(),
});

export const conversationsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("conversations_integration_id_fkey"),
    columns: z.tuple([z.literal("integration_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("integrations"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const customersRowSchema = z.object({
  created_at: z.string(),
  id: z.string(),
  invoice_prefix: z.string().nullable(),
  stripe_customer_id: z.string().nullable(),
  updated_at: z.string().nullable(),
});

export const customersInsertSchema = z.object({
  created_at: z.string().optional(),
  id: z.string(),
  invoice_prefix: z.string().optional().nullable(),
  stripe_customer_id: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
});

export const customersUpdateSchema = z.object({
  created_at: z.string().optional(),
  id: z.string().optional(),
  invoice_prefix: z.string().optional().nullable(),
  stripe_customer_id: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
});

export const customersRelationshipsSchema = z.tuple([]);

export const entityRowSchema = z.object({
  created_at: z.string(),
  entity_type: z.string(),
  external_ids: z.array(z.string()).nullable(),
  id: z.string(),
  metadata: jsonSchema.nullable(),
  name: z.string(),
  updated_at: z.string(),
  user_id: z.string(),
});

export const entityInsertSchema = z.object({
  created_at: z.string().optional(),
  entity_type: z.string(),
  external_ids: z.array(z.string()).optional().nullable(),
  id: z.string().optional(),
  metadata: jsonSchema.optional().nullable(),
  name: z.string(),
  updated_at: z.string().optional(),
  user_id: z.string(),
});

export const entityUpdateSchema = z.object({
  created_at: z.string().optional(),
  entity_type: z.string().optional(),
  external_ids: z.array(z.string()).optional().nullable(),
  id: z.string().optional(),
  metadata: jsonSchema.optional().nullable(),
  name: z.string().optional(),
  updated_at: z.string().optional(),
  user_id: z.string().optional(),
});

export const entityRelationshipsSchema = z.tuple([]);

export const entityRelationRowSchema = z.object({
  created_at: z.string(),
  entity_id_1: z.string(),
  entity_id_2: z.string(),
  id: z.string(),
  metadata: jsonSchema.nullable(),
  relation_type: z.string(),
  updated_at: z.string(),
  user_id: z.string(),
});

export const entityRelationInsertSchema = z.object({
  created_at: z.string().optional(),
  entity_id_1: z.string(),
  entity_id_2: z.string(),
  id: z.string().optional(),
  metadata: jsonSchema.optional().nullable(),
  relation_type: z.string(),
  updated_at: z.string().optional(),
  user_id: z.string(),
});

export const entityRelationUpdateSchema = z.object({
  created_at: z.string().optional(),
  entity_id_1: z.string().optional(),
  entity_id_2: z.string().optional(),
  id: z.string().optional(),
  metadata: jsonSchema.optional().nullable(),
  relation_type: z.string().optional(),
  updated_at: z.string().optional(),
  user_id: z.string().optional(),
});

export const entityRelationRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("entity_relation_entity_id_1_fkey"),
    columns: z.tuple([z.literal("entity_id_1")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("entity"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("entity_relation_entity_id_2_fkey"),
    columns: z.tuple([z.literal("entity_id_2")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("entity"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const eventMessageAssociationsRowSchema = z.object({
  created_at: z.string(),
  event_id: z.number(),
  message_id: z.number(),
  relevance_score: z.number(),
  user_id: z.string().nullable(),
});

export const eventMessageAssociationsInsertSchema = z.object({
  created_at: z.string().optional(),
  event_id: z.number(),
  message_id: z.number(),
  relevance_score: z.number(),
  user_id: z.string().optional().nullable(),
});

export const eventMessageAssociationsUpdateSchema = z.object({
  created_at: z.string().optional(),
  event_id: z.number().optional(),
  message_id: z.number().optional(),
  relevance_score: z.number().optional(),
  user_id: z.string().optional().nullable(),
});

export const eventMessageAssociationsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("event_message_associations_event_id_fkey"),
    columns: z.tuple([z.literal("event_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("events"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("event_message_associations_message_id_fkey"),
    columns: z.tuple([z.literal("message_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("messages"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("event_message_associations_message_id_fkey"),
    columns: z.tuple([z.literal("message_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("messages_with_integration"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("event_message_associations_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("user_profiles"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("event_message_associations_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("users"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const eventsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("calendar_id_fkey"),
    columns: z.tuple([z.literal("calendar_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("calendars"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("events_calendar_id_fkey"),
    columns: z.tuple([z.literal("calendar_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("calendars"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("events_report_id_fkey"),
    columns: z.tuple([z.literal("report_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("reports"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const integrationsRowSchema = z.object({
  created_at: z.string(),
  history_id: z.string().nullable(),
  id: z.number(),
  is_active: z.boolean(),
  last_synced_at: z.string().nullable(),
  name: z.string(),
  pending_sync: z.boolean().nullable(),
  provider: z.string(),
  required_tier: z.string().nullable(),
  type: z.string().nullable(),
  type_generic: z.string(),
  updated_at: z.string().nullable(),
  user_id: z.string(),
});

export const integrationsInsertSchema = z.object({
  created_at: z.string().optional(),
  history_id: z.string().optional().nullable(),
  id: z.never().optional(),
  is_active: z.boolean().optional(),
  last_synced_at: z.string().optional().nullable(),
  name: z.string(),
  pending_sync: z.boolean().optional().nullable(),
  provider: z.string(),
  required_tier: z.string().optional().nullable(),
  type: z.string().optional().nullable(),
  type_generic: z.string().optional(),
  updated_at: z.string().optional().nullable(),
  user_id: z.string(),
});

export const integrationsUpdateSchema = z.object({
  created_at: z.string().optional(),
  history_id: z.string().optional().nullable(),
  id: z.never().optional(),
  is_active: z.boolean().optional(),
  last_synced_at: z.string().optional().nullable(),
  name: z.string().optional(),
  pending_sync: z.boolean().optional().nullable(),
  provider: z.string().optional(),
  required_tier: z.string().optional().nullable(),
  type: z.string().optional().nullable(),
  type_generic: z.string().optional(),
  updated_at: z.string().optional().nullable(),
  user_id: z.string().optional(),
});

export const integrationsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("integrations_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("user_profiles"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("integrations_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("users"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const integrationsCredentialsRowSchema = z.object({
  created_at: z.string(),
  credentials: jsonSchema,
  id: z.number(),
  integration_id: z.number(),
  updated_at: z.string().nullable(),
});

export const integrationsCredentialsInsertSchema = z.object({
  created_at: z.string().optional(),
  credentials: jsonSchema,
  id: z.never().optional(),
  integration_id: z.number(),
  updated_at: z.string().optional().nullable(),
});

export const integrationsCredentialsUpdateSchema = z.object({
  created_at: z.string().optional(),
  credentials: jsonSchema.optional(),
  id: z.never().optional(),
  integration_id: z.number().optional(),
  updated_at: z.string().optional().nullable(),
});

export const integrationsCredentialsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("integrations_credentials_integration_id_fkey"),
    columns: z.tuple([z.literal("integration_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("integrations"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const meetingsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("meetings_event_id_fkey"),
    columns: z.tuple([z.literal("event_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("events"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("meetings_integration_id_fkey"),
    columns: z.tuple([z.literal("integration_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("integrations"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("meetings_report_id_fkey"),
    columns: z.tuple([z.literal("report_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("reports"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const messagesRowSchema = z.object({
  action_items: z.array(z.string()).nullable(),
  body_html: z.string(),
  body_text: z.string(),
  brief: z.string().nullable(),
  costs: jsonSchema.nullable(),
  created_at: z.string(),
  entity_ids: z.array(z.string()).nullable(),
  global_id_3p: z.string(),
  goal_id: z.number().nullable(),
  id: z.number(),
  id_3p: z.string(),
  integration_id: z.number(),
  links: z.array(z.string()).nullable(),
  meta_data: jsonSchema.nullable(),
  parent_message_id: z.number().nullable(),
  participants: z.array(z.string()).nullable(),
  primary_entity_id: z.string().nullable(),
  priority: z.string().nullable(),
  questions: z.array(z.string()).nullable(),
  received_at: z.string(),
  report_id: z.number().nullable(),
  sent_by: z.string(),
  sentiment: z.string().nullable(),
  snippet: z.string().nullable(),
  subject: z.string(),
  tags: z.array(z.string()).nullable(),
  thread_id: z.number().nullable(),
  thread_position: z.number().nullable(),
  topics: z.array(z.string()).nullable(),
});

export const messagesInsertSchema = z.object({
  action_items: z.array(z.string()).optional().nullable(),
  body_html: z.string(),
  body_text: z.string(),
  brief: z.string().optional().nullable(),
  costs: jsonSchema.optional().nullable(),
  created_at: z.string().optional(),
  entity_ids: z.array(z.string()).optional().nullable(),
  global_id_3p: z.string(),
  goal_id: z.number().optional().nullable(),
  id: z.never().optional(),
  id_3p: z.string(),
  integration_id: z.number(),
  links: z.array(z.string()).optional().nullable(),
  meta_data: jsonSchema.optional().nullable(),
  parent_message_id: z.number().optional().nullable(),
  participants: z.array(z.string()).optional().nullable(),
  primary_entity_id: z.string().optional().nullable(),
  priority: z.string().optional().nullable(),
  questions: z.array(z.string()).optional().nullable(),
  received_at: z.string(),
  report_id: z.number().optional().nullable(),
  sent_by: z.string(),
  sentiment: z.string().optional().nullable(),
  snippet: z.string().optional().nullable(),
  subject: z.string(),
  tags: z.array(z.string()).optional().nullable(),
  thread_id: z.number().optional().nullable(),
  thread_position: z.number().optional().nullable(),
  topics: z.array(z.string()).optional().nullable(),
});

export const messagesUpdateSchema = z.object({
  action_items: z.array(z.string()).optional().nullable(),
  body_html: z.string().optional(),
  body_text: z.string().optional(),
  brief: z.string().optional().nullable(),
  costs: jsonSchema.optional().nullable(),
  created_at: z.string().optional(),
  entity_ids: z.array(z.string()).optional().nullable(),
  global_id_3p: z.string().optional(),
  goal_id: z.number().optional().nullable(),
  id: z.never().optional(),
  id_3p: z.string().optional(),
  integration_id: z.number().optional(),
  links: z.array(z.string()).optional().nullable(),
  meta_data: jsonSchema.optional().nullable(),
  parent_message_id: z.number().optional().nullable(),
  participants: z.array(z.string()).optional().nullable(),
  primary_entity_id: z.string().optional().nullable(),
  priority: z.string().optional().nullable(),
  questions: z.array(z.string()).optional().nullable(),
  received_at: z.string().optional(),
  report_id: z.number().optional().nullable(),
  sent_by: z.string().optional(),
  sentiment: z.string().optional().nullable(),
  snippet: z.string().optional().nullable(),
  subject: z.string().optional(),
  tags: z.array(z.string()).optional().nullable(),
  thread_id: z.number().optional().nullable(),
  thread_position: z.number().optional().nullable(),
  topics: z.array(z.string()).optional().nullable(),
});

export const messagesRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("inbox_integration_id_fkey"),
    columns: z.tuple([z.literal("integration_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("integrations"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("messages_goal_id_fkey"),
    columns: z.tuple([z.literal("goal_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("user_goals"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("messages_parent_message_id_fkey"),
    columns: z.tuple([z.literal("parent_message_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("messages"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("messages_parent_message_id_fkey"),
    columns: z.tuple([z.literal("parent_message_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("messages_with_integration"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("messages_report_id_fkey"),
    columns: z.tuple([z.literal("report_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("reports"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("messages_thread_id_fkey"),
    columns: z.tuple([z.literal("thread_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("threads"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const namingRetryRowSchema = z.object({
  final_name: z.string(),
  id: z.number(),
  retry_count: z.number(),
  subtext: z.string(),
  tag_ids: z.array(z.string()),
  tag_names: z.array(z.string()),
  user_id: z.string(),
});

export const namingRetryInsertSchema = z.object({
  final_name: z.string(),
  id: z.number().optional(),
  retry_count: z.number(),
  subtext: z.string(),
  tag_ids: z.array(z.string()),
  tag_names: z.array(z.string()),
  user_id: z.string(),
});

export const namingRetryUpdateSchema = z.object({
  final_name: z.string().optional(),
  id: z.number().optional(),
  retry_count: z.number().optional(),
  subtext: z.string().optional(),
  tag_ids: z.array(z.string()).optional(),
  tag_names: z.array(z.string()).optional(),
  user_id: z.string().optional(),
});

export const namingRetryRelationshipsSchema = z.tuple([]);

export const notificationChannelsRowSchema = z.object({
  code: z.string(),
  created_at: z.string(),
  description: z.string().nullable(),
  id: z.string(),
  integration_type: z.string().nullable(),
  is_active: z.boolean(),
  name: z.string(),
  updated_at: z.string().nullable(),
});

export const notificationChannelsInsertSchema = z.object({
  code: z.string(),
  created_at: z.string().optional(),
  description: z.string().optional().nullable(),
  id: z.string().optional(),
  integration_type: z.string().optional().nullable(),
  is_active: z.boolean().optional(),
  name: z.string(),
  updated_at: z.string().optional().nullable(),
});

export const notificationChannelsUpdateSchema = z.object({
  code: z.string().optional(),
  created_at: z.string().optional(),
  description: z.string().optional().nullable(),
  id: z.string().optional(),
  integration_type: z.string().optional().nullable(),
  is_active: z.boolean().optional(),
  name: z.string().optional(),
  updated_at: z.string().optional().nullable(),
});

export const notificationChannelsRelationshipsSchema = z.tuple([]);

export const notificationDeliveriesRowSchema = z.object({
  attempt_count: z.number(),
  created_at: z.string(),
  delivered_at: z.string().nullable(),
  error_message: z.string().nullable(),
  id: z.string(),
  integration_id: z.number().nullable(),
  last_attempt_at: z.string().nullable(),
  notification_channel_id: z.string(),
  notification_id: z.string(),
  status: z.string(),
  updated_at: z.string().nullable(),
});

export const notificationDeliveriesInsertSchema = z.object({
  attempt_count: z.number().optional(),
  created_at: z.string().optional(),
  delivered_at: z.string().optional().nullable(),
  error_message: z.string().optional().nullable(),
  id: z.string().optional(),
  integration_id: z.number().optional().nullable(),
  last_attempt_at: z.string().optional().nullable(),
  notification_channel_id: z.string(),
  notification_id: z.string(),
  status: z.string(),
  updated_at: z.string().optional().nullable(),
});

export const notificationDeliveriesUpdateSchema = z.object({
  attempt_count: z.number().optional(),
  created_at: z.string().optional(),
  delivered_at: z.string().optional().nullable(),
  error_message: z.string().optional().nullable(),
  id: z.string().optional(),
  integration_id: z.number().optional().nullable(),
  last_attempt_at: z.string().optional().nullable(),
  notification_channel_id: z.string().optional(),
  notification_id: z.string().optional(),
  status: z.string().optional(),
  updated_at: z.string().optional().nullable(),
});

export const notificationDeliveriesRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("notification_deliveries_integration_id_fkey"),
    columns: z.tuple([z.literal("integration_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("integrations"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("notification_deliveries_notification_channel_id_fkey"),
    columns: z.tuple([z.literal("notification_channel_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("notification_channels"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("notification_deliveries_notification_id_fkey"),
    columns: z.tuple([z.literal("notification_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("notifications"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const notificationTypesRowSchema = z.object({
  code: z.string(),
  created_at: z.string(),
  description: z.string().nullable(),
  id: z.string(),
  name: z.string(),
  updated_at: z.string().nullable(),
});

export const notificationTypesInsertSchema = z.object({
  code: z.string(),
  created_at: z.string().optional(),
  description: z.string().optional().nullable(),
  id: z.string().optional(),
  name: z.string(),
  updated_at: z.string().optional().nullable(),
});

export const notificationTypesUpdateSchema = z.object({
  code: z.string().optional(),
  created_at: z.string().optional(),
  description: z.string().optional().nullable(),
  id: z.string().optional(),
  name: z.string().optional(),
  updated_at: z.string().optional().nullable(),
});

export const notificationTypesRelationshipsSchema = z.tuple([]);

export const notificationsRowSchema = z.object({
  content: z.string(),
  created_at: z.string(),
  expires_at: z.string().nullable(),
  id: z.string(),
  is_dismissed: z.boolean().nullable(),
  is_read: z.boolean(),
  metadata: jsonSchema,
  notification_type_id: z.string(),
  scheduled_for: z.string().nullable(),
  title: z.string(),
  updated_at: z.string().nullable(),
  user_id: z.string(),
});

export const notificationsInsertSchema = z.object({
  content: z.string(),
  created_at: z.string().optional(),
  expires_at: z.string().optional().nullable(),
  id: z.string().optional(),
  is_dismissed: z.boolean().optional().nullable(),
  is_read: z.boolean().optional(),
  metadata: jsonSchema.optional(),
  notification_type_id: z.string(),
  scheduled_for: z.string().optional().nullable(),
  title: z.string(),
  updated_at: z.string().optional().nullable(),
  user_id: z.string(),
});

export const notificationsUpdateSchema = z.object({
  content: z.string().optional(),
  created_at: z.string().optional(),
  expires_at: z.string().optional().nullable(),
  id: z.string().optional(),
  is_dismissed: z.boolean().optional().nullable(),
  is_read: z.boolean().optional(),
  metadata: jsonSchema.optional(),
  notification_type_id: z.string().optional(),
  scheduled_for: z.string().optional().nullable(),
  title: z.string().optional(),
  updated_at: z.string().optional().nullable(),
  user_id: z.string().optional(),
});

export const notificationsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("notifications_notification_type_id_fkey"),
    columns: z.tuple([z.literal("notification_type_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("notification_types"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("notifications_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("user_profiles"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("notifications_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("users"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const organizationMembersRowSchema = z.object({
  id: z.string(),
  invited_by: z.string().nullable(),
  is_licensed: z.boolean().nullable(),
  joined_at: z.string().nullable(),
  license_assigned_at: z.string().nullable(),
  license_assigned_by: z.string().nullable(),
  organization_id: z.string().nullable(),
  role: z.string(),
  user_id: z.string().nullable(),
});

export const organizationMembersInsertSchema = z.object({
  id: z.string().optional(),
  invited_by: z.string().optional().nullable(),
  is_licensed: z.boolean().optional().nullable(),
  joined_at: z.string().optional().nullable(),
  license_assigned_at: z.string().optional().nullable(),
  license_assigned_by: z.string().optional().nullable(),
  organization_id: z.string().optional().nullable(),
  role: z.string().optional(),
  user_id: z.string().optional().nullable(),
});

export const organizationMembersUpdateSchema = z.object({
  id: z.string().optional(),
  invited_by: z.string().optional().nullable(),
  is_licensed: z.boolean().optional().nullable(),
  joined_at: z.string().optional().nullable(),
  license_assigned_at: z.string().optional().nullable(),
  license_assigned_by: z.string().optional().nullable(),
  organization_id: z.string().optional().nullable(),
  role: z.string().optional(),
  user_id: z.string().optional().nullable(),
});

export const organizationMembersRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("organization_members_organization_id_fkey"),
    columns: z.tuple([z.literal("organization_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("organization_subscriptions"),
    referencedColumns: z.tuple([z.literal("organization_id")]),
  }),
  z.object({
    foreignKeyName: z.literal("organization_members_organization_id_fkey"),
    columns: z.tuple([z.literal("organization_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("organizations"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const organizationsRowSchema = z.object({
  account_manager_id: z.string().nullable(),
  address: jsonSchema.nullable(),
  billing_email: z.string().nullable(),
  contract_renewal_date: z.string().nullable(),
  contract_start_date: z.string().nullable(),
  created_at: z.string().nullable(),
  domain: z.string().nullable(),
  employee_count_range: z.string().nullable(),
  first_contact_date: z.string().nullable(),
  id: z.string(),
  industry: z.string().nullable(),
  metadata: jsonSchema.nullable(),
  name: z.string(),
  primary_contact_email: z.string().nullable(),
  primary_contact_name: z.string().nullable(),
  primary_contact_phone: z.string().nullable(),
  stripe_customer_id: z.string().nullable(),
  updated_at: z.string().nullable(),
  website: z.string().nullable(),
});

export const organizationsInsertSchema = z.object({
  account_manager_id: z.string().optional().nullable(),
  address: jsonSchema.optional().nullable(),
  billing_email: z.string().optional().nullable(),
  contract_renewal_date: z.string().optional().nullable(),
  contract_start_date: z.string().optional().nullable(),
  created_at: z.string().optional().nullable(),
  domain: z.string().optional().nullable(),
  employee_count_range: z.string().optional().nullable(),
  first_contact_date: z.string().optional().nullable(),
  id: z.string().optional(),
  industry: z.string().optional().nullable(),
  metadata: jsonSchema.optional().nullable(),
  name: z.string(),
  primary_contact_email: z.string().optional().nullable(),
  primary_contact_name: z.string().optional().nullable(),
  primary_contact_phone: z.string().optional().nullable(),
  stripe_customer_id: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
});

export const organizationsUpdateSchema = z.object({
  account_manager_id: z.string().optional().nullable(),
  address: jsonSchema.optional().nullable(),
  billing_email: z.string().optional().nullable(),
  contract_renewal_date: z.string().optional().nullable(),
  contract_start_date: z.string().optional().nullable(),
  created_at: z.string().optional().nullable(),
  domain: z.string().optional().nullable(),
  employee_count_range: z.string().optional().nullable(),
  first_contact_date: z.string().optional().nullable(),
  id: z.string().optional(),
  industry: z.string().optional().nullable(),
  metadata: jsonSchema.optional().nullable(),
  name: z.string().optional(),
  primary_contact_email: z.string().optional().nullable(),
  primary_contact_name: z.string().optional().nullable(),
  primary_contact_phone: z.string().optional().nullable(),
  stripe_customer_id: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
});

export const organizationsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("organizations_stripe_customer_id_fkey"),
    columns: z.tuple([z.literal("stripe_customer_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("customers"),
    referencedColumns: z.tuple([z.literal("stripe_customer_id")]),
  }),
]);

export const pricesRowSchema = z.object({
  active: z.boolean().nullable(),
  created_at: z.string(),
  currency: z.string().nullable(),
  description: z.string().nullable(),
  id: z.string(),
  interval: z.string().nullable(),
  interval_count: z.number().nullable(),
  lookup_key: z.string().nullable(),
  metadata: jsonSchema.nullable(),
  nickname: z.string().nullable(),
  product_id: z.string().nullable(),
  trial_period_days: z.number().nullable(),
  type: z.string().nullable(),
  unit_amount: z.number().nullable(),
  updated_at: z.string().nullable(),
});

export const pricesInsertSchema = z.object({
  active: z.boolean().optional().nullable(),
  created_at: z.string().optional(),
  currency: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  id: z.string(),
  interval: z.string().optional().nullable(),
  interval_count: z.number().optional().nullable(),
  lookup_key: z.string().optional().nullable(),
  metadata: jsonSchema.optional().nullable(),
  nickname: z.string().optional().nullable(),
  product_id: z.string().optional().nullable(),
  trial_period_days: z.number().optional().nullable(),
  type: z.string().optional().nullable(),
  unit_amount: z.number().optional().nullable(),
  updated_at: z.string().optional().nullable(),
});

export const pricesUpdateSchema = z.object({
  active: z.boolean().optional().nullable(),
  created_at: z.string().optional(),
  currency: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  id: z.string().optional(),
  interval: z.string().optional().nullable(),
  interval_count: z.number().optional().nullable(),
  lookup_key: z.string().optional().nullable(),
  metadata: jsonSchema.optional().nullable(),
  nickname: z.string().optional().nullable(),
  product_id: z.string().optional().nullable(),
  trial_period_days: z.number().optional().nullable(),
  type: z.string().optional().nullable(),
  unit_amount: z.number().optional().nullable(),
  updated_at: z.string().optional().nullable(),
});

export const pricesRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("prices_product_id_fkey"),
    columns: z.tuple([z.literal("product_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("products"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const productsRowSchema = z.object({
  active: z.boolean().nullable(),
  created_at: z.string(),
  description: z.string().nullable(),
  id: z.string(),
  image: z.string().nullable(),
  metadata: jsonSchema.nullable(),
  name: z.string().nullable(),
  updated_at: z.string().nullable(),
});

export const productsInsertSchema = z.object({
  active: z.boolean().optional().nullable(),
  created_at: z.string().optional(),
  description: z.string().optional().nullable(),
  id: z.string(),
  image: z.string().optional().nullable(),
  metadata: jsonSchema.optional().nullable(),
  name: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
});

export const productsUpdateSchema = z.object({
  active: z.boolean().optional().nullable(),
  created_at: z.string().optional(),
  description: z.string().optional().nullable(),
  id: z.string().optional(),
  image: z.string().optional().nullable(),
  metadata: jsonSchema.optional().nullable(),
  name: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
});

export const productsRelationshipsSchema = z.tuple([]);

export const reportsRowSchema = z.object({
  content: jsonSchema,
  costs: jsonSchema,
  created_at: z.string(),
  id: z.number(),
  updated_at: z.string(),
  user_id: z.string(),
});

export const reportsInsertSchema = z.object({
  content: jsonSchema,
  costs: jsonSchema,
  created_at: z.string().optional(),
  id: z.never().optional(),
  updated_at: z.string().optional(),
  user_id: z.string(),
});

export const reportsUpdateSchema = z.object({
  content: jsonSchema.optional(),
  costs: jsonSchema.optional(),
  created_at: z.string().optional(),
  id: z.never().optional(),
  updated_at: z.string().optional(),
  user_id: z.string().optional(),
});

export const reportsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("reports_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("user_profiles"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("reports_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("users"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const subscriptionsRowSchema = z.object({
  automatic_tax: z.boolean().nullable(),
  cancel_at: z.string().nullable(),
  cancel_at_period_end: z.boolean().nullable(),
  canceled_at: z.string().nullable(),
  collection_method: z.string().nullable(),
  created: z.string(),
  created_at: z.string(),
  current_period_end: z.string(),
  current_period_start: z.string(),
  days_until_due: z.number().nullable(),
  ended_at: z.string().nullable(),
  id: z.string(),
  metadata: jsonSchema.nullable(),
  price_id: z.string().nullable(),
  quantity: z.number().nullable(),
  status: z.string().nullable(),
  trial_end: z.string().nullable(),
  trial_settings: jsonSchema.nullable(),
  trial_start: z.string().nullable(),
  updated_at: z.string().nullable(),
  user_id: z.string(),
});

export const subscriptionsInsertSchema = z.object({
  automatic_tax: z.boolean().optional().nullable(),
  cancel_at: z.string().optional().nullable(),
  cancel_at_period_end: z.boolean().optional().nullable(),
  canceled_at: z.string().optional().nullable(),
  collection_method: z.string().optional().nullable(),
  created: z.string().optional(),
  created_at: z.string().optional(),
  current_period_end: z.string().optional(),
  current_period_start: z.string().optional(),
  days_until_due: z.number().optional().nullable(),
  ended_at: z.string().optional().nullable(),
  id: z.string(),
  metadata: jsonSchema.optional().nullable(),
  price_id: z.string().optional().nullable(),
  quantity: z.number().optional().nullable(),
  status: z.string().optional().nullable(),
  trial_end: z.string().optional().nullable(),
  trial_settings: jsonSchema.optional().nullable(),
  trial_start: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
  user_id: z.string(),
});

export const subscriptionsUpdateSchema = z.object({
  automatic_tax: z.boolean().optional().nullable(),
  cancel_at: z.string().optional().nullable(),
  cancel_at_period_end: z.boolean().optional().nullable(),
  canceled_at: z.string().optional().nullable(),
  collection_method: z.string().optional().nullable(),
  created: z.string().optional(),
  created_at: z.string().optional(),
  current_period_end: z.string().optional(),
  current_period_start: z.string().optional(),
  days_until_due: z.number().optional().nullable(),
  ended_at: z.string().optional().nullable(),
  id: z.string().optional(),
  metadata: jsonSchema.optional().nullable(),
  price_id: z.string().optional().nullable(),
  quantity: z.number().optional().nullable(),
  status: z.string().optional().nullable(),
  trial_end: z.string().optional().nullable(),
  trial_settings: jsonSchema.optional().nullable(),
  trial_start: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
  user_id: z.string().optional(),
});

export const subscriptionsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("subscriptions_price_id_fkey"),
    columns: z.tuple([z.literal("price_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("prices"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const tagRowSchema = z.object({
  color: z.string().nullable(),
  created_at: z.string(),
  embedding: z.string().nullable(),
  emoji: z.string(),
  id: z.string(),
  importance: z.number().nullable(),
  importance_updated: z.string().nullable(),
  is_discrete: z.boolean(),
  is_muted: z.boolean(),
  is_special_case: z.boolean(),
  merged: z.number(),
  rejected: z.number(),
  subtext: z.string(),
  tag_name: z.string(),
  updated_at: z.string(),
  user_id: z.string(),
});

export const tagInsertSchema = z.object({
  color: z.string().optional().nullable(),
  created_at: z.string().optional(),
  embedding: z.string().optional().nullable(),
  emoji: z.string(),
  id: z.string().optional(),
  importance: z.number().optional().nullable(),
  importance_updated: z.string().optional().nullable(),
  is_discrete: z.boolean().optional(),
  is_muted: z.boolean().optional(),
  is_special_case: z.boolean().optional(),
  merged: z.number().optional(),
  rejected: z.number().optional(),
  subtext: z.string(),
  tag_name: z.string(),
  updated_at: z.string().optional(),
  user_id: z.string(),
});

export const tagUpdateSchema = z.object({
  color: z.string().optional().nullable(),
  created_at: z.string().optional(),
  embedding: z.string().optional().nullable(),
  emoji: z.string().optional(),
  id: z.string().optional(),
  importance: z.number().optional().nullable(),
  importance_updated: z.string().optional().nullable(),
  is_discrete: z.boolean().optional(),
  is_muted: z.boolean().optional(),
  is_special_case: z.boolean().optional(),
  merged: z.number().optional(),
  rejected: z.number().optional(),
  subtext: z.string().optional(),
  tag_name: z.string().optional(),
  updated_at: z.string().optional(),
  user_id: z.string().optional(),
});

export const tagRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("tag_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("user_profiles"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("tag_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("users"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const tagCollisionMetricsRowSchema = z.object({
  created_at: z.string().nullable(),
  id: z.string(),
  left_embedding: z.string(),
  left_subtext: z.string(),
  merged_embedding: z.string(),
  merged_subtext: z.string(),
  right_embedding: z.string(),
  right_subtext: z.string(),
  tag_id: z.string().nullable(),
});

export const tagCollisionMetricsInsertSchema = z.object({
  created_at: z.string().optional().nullable(),
  id: z.string().optional(),
  left_embedding: z.string(),
  left_subtext: z.string(),
  merged_embedding: z.string(),
  merged_subtext: z.string(),
  right_embedding: z.string(),
  right_subtext: z.string(),
  tag_id: z.string().optional().nullable(),
});

export const tagCollisionMetricsUpdateSchema = z.object({
  created_at: z.string().optional().nullable(),
  id: z.string().optional(),
  left_embedding: z.string().optional(),
  left_subtext: z.string().optional(),
  merged_embedding: z.string().optional(),
  merged_subtext: z.string().optional(),
  right_embedding: z.string().optional(),
  right_subtext: z.string().optional(),
  tag_id: z.string().optional().nullable(),
});

export const tagCollisionMetricsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("tag_collision_metrics_tag_id_fkey"),
    columns: z.tuple([z.literal("tag_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("tag"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("tag_collision_metrics_tag_id_fkey"),
    columns: z.tuple([z.literal("tag_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("tag_with_decayed_importance"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const teamMembersRowSchema = z.object({
  id: z.string(),
  invited_by: z.string().nullable(),
  joined_at: z.string().nullable(),
  role: z.string(),
  team_id: z.string().nullable(),
  user_id: z.string().nullable(),
});

export const teamMembersInsertSchema = z.object({
  id: z.string().optional(),
  invited_by: z.string().optional().nullable(),
  joined_at: z.string().optional().nullable(),
  role: z.string().optional(),
  team_id: z.string().optional().nullable(),
  user_id: z.string().optional().nullable(),
});

export const teamMembersUpdateSchema = z.object({
  id: z.string().optional(),
  invited_by: z.string().optional().nullable(),
  joined_at: z.string().optional().nullable(),
  role: z.string().optional(),
  team_id: z.string().optional().nullable(),
  user_id: z.string().optional().nullable(),
});

export const teamMembersRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("team_members_team_id_fkey"),
    columns: z.tuple([z.literal("team_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("teams"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const teamsRowSchema = z.object({
  created_at: z.string().nullable(),
  created_by: z.string().nullable(),
  description: z.string().nullable(),
  id: z.string(),
  name: z.string(),
  updated_at: z.string().nullable(),
});

export const teamsInsertSchema = z.object({
  created_at: z.string().optional().nullable(),
  created_by: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  id: z.string().optional(),
  name: z.string(),
  updated_at: z.string().optional().nullable(),
});

export const teamsUpdateSchema = z.object({
  created_at: z.string().optional().nullable(),
  created_by: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  id: z.string().optional(),
  name: z.string().optional(),
  updated_at: z.string().optional().nullable(),
});

export const teamsRelationshipsSchema = z.tuple([]);

export const threadsRowSchema = z.object({
  brief: z.string().nullable(),
  created_at: z.string().nullable(),
  id: z.number(),
  integration_id: z.number(),
  thread_id_3p: z.string(),
  title: z.string().nullable(),
  updated_at: z.string().nullable(),
});

export const threadsInsertSchema = z.object({
  brief: z.string().optional().nullable(),
  created_at: z.string().optional().nullable(),
  id: z.number().optional(),
  integration_id: z.number(),
  thread_id_3p: z.string(),
  title: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
});

export const threadsUpdateSchema = z.object({
  brief: z.string().optional().nullable(),
  created_at: z.string().optional().nullable(),
  id: z.number().optional(),
  integration_id: z.number().optional(),
  thread_id_3p: z.string().optional(),
  title: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
});

export const threadsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("threads_integration_id_fkey"),
    columns: z.tuple([z.literal("integration_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("integrations"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const userGoalsRowSchema = z.object({
  created_at: z.string(),
  end_date: z.string().nullable(),
  goal_text: z.string(),
  id: z.number(),
  start_date: z.string(),
  user_id: z.string(),
});

export const userGoalsInsertSchema = z.object({
  created_at: z.string().optional(),
  end_date: z.string().optional().nullable(),
  goal_text: z.string(),
  id: z.number().optional(),
  start_date: z.string(),
  user_id: z.string(),
});

export const userGoalsUpdateSchema = z.object({
  created_at: z.string().optional(),
  end_date: z.string().optional().nullable(),
  goal_text: z.string().optional(),
  id: z.number().optional(),
  start_date: z.string().optional(),
  user_id: z.string().optional(),
});

export const userGoalsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("user_goals_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("user_profiles"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("user_goals_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("users"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const userNotificationPreferencesRowSchema = z.object({
  created_at: z.string(),
  id: z.string(),
  integration_id: z.number().nullable(),
  is_enabled: z.boolean(),
  notification_channel_id: z.string(),
  notification_type_id: z.string(),
  updated_at: z.string().nullable(),
  user_id: z.string(),
});

export const userNotificationPreferencesInsertSchema = z.object({
  created_at: z.string().optional(),
  id: z.string().optional(),
  integration_id: z.number().optional().nullable(),
  is_enabled: z.boolean().optional(),
  notification_channel_id: z.string(),
  notification_type_id: z.string(),
  updated_at: z.string().optional().nullable(),
  user_id: z.string(),
});

export const userNotificationPreferencesUpdateSchema = z.object({
  created_at: z.string().optional(),
  id: z.string().optional(),
  integration_id: z.number().optional().nullable(),
  is_enabled: z.boolean().optional(),
  notification_channel_id: z.string().optional(),
  notification_type_id: z.string().optional(),
  updated_at: z.string().optional().nullable(),
  user_id: z.string().optional(),
});

export const userNotificationPreferencesRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("user_notification_preferences_integration_id_fkey"),
    columns: z.tuple([z.literal("integration_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("integrations"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("user_notification_preferences_notification_channel_id_fkey"),
    columns: z.tuple([z.literal("notification_channel_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("notification_channels"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("user_notification_preferences_notification_type_id_fkey"),
    columns: z.tuple([z.literal("notification_type_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("notification_types"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("user_notification_preferences_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("user_profiles"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("user_notification_preferences_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("users"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const inactivityStatusSchema = z.union([
  z.literal("active"),
  z.literal("warning_sent"),
  z.literal("deactivated"),
  z.literal("pending_deletion"),
]);

export const usersInsertSchema = z.object({
  auth_completed: z.boolean().optional(),
  avatar_url: z.string().optional().nullable(),
  billing_address: jsonSchema.optional().nullable(),
  consolidated_weekend: z.boolean().optional(),
  created_at: z.string().optional(),
  full_name: z.string().optional().nullable(),
  id: z.string(),
  inactivity_notified_at: z.string().optional().nullable(),
  inactivity_status: inactivityStatusSchema.optional(),
  last_activity_at: z.string().optional().nullable(),
  last_report_date: z.string().optional().nullable(),
  onboard_report_once: z.boolean().optional(),
  onboarded: z.boolean().optional(),
  onboarding_step: z.number().optional(),
  payment_method: jsonSchema.optional().nullable(),
  preferred_name: z.string().optional().nullable(),
  report_pending: z.boolean().optional(),
  report_time: z.string().optional(),
  role: z.string().optional().nullable(),
  timezone: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
  voice_id: z.string().optional(),
});

export const usersUpdateSchema = z.object({
  auth_completed: z.boolean().optional(),
  avatar_url: z.string().optional().nullable(),
  billing_address: jsonSchema.optional().nullable(),
  consolidated_weekend: z.boolean().optional(),
  created_at: z.string().optional(),
  full_name: z.string().optional().nullable(),
  id: z.string().optional(),
  inactivity_notified_at: z.string().optional().nullable(),
  inactivity_status: inactivityStatusSchema.optional(),
  last_activity_at: z.string().optional().nullable(),
  last_report_date: z.string().optional().nullable(),
  onboard_report_once: z.boolean().optional(),
  onboarded: z.boolean().optional(),
  onboarding_step: z.number().optional(),
  payment_method: jsonSchema.optional().nullable(),
  preferred_name: z.string().optional().nullable(),
  report_pending: z.boolean().optional(),
  report_time: z.string().optional(),
  role: z.string().optional().nullable(),
  timezone: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
  voice_id: z.string().optional(),
});

export const usersRelationshipsSchema = z.tuple([]);

export const messagesWithIntegrationRowSchema = z.object({
  action_items: z.array(z.string()).nullable(),
  body_html: z.string().nullable(),
  body_text: z.string().nullable(),
  brief: z.string().nullable(),
  costs: jsonSchema.nullable(),
  created_at: z.string().nullable(),
  entity_ids: z.array(z.string()).nullable(),
  global_id_3p: z.string().nullable(),
  id: z.number().nullable(),
  id_3p: z.string().nullable(),
  integration_id: z.number().nullable(),
  links: z.array(z.string()).nullable(),
  meta_data: jsonSchema.nullable(),
  parent_message_id: z.number().nullable(),
  participants: z.array(z.string()).nullable(),
  primary_entity_id: z.string().nullable(),
  priority: z.string().nullable(),
  questions: z.array(z.string()).nullable(),
  received_at: z.string().nullable(),
  report_id: z.number().nullable(),
  sent_by: z.string().nullable(),
  sentiment: z.string().nullable(),
  snippet: z.string().nullable(),
  subject: z.string().nullable(),
  tags: z.array(z.string()).nullable(),
  thread_id: z.number().nullable(),
  thread_position: z.number().nullable(),
  topics: z.array(z.string()).nullable(),
  type: z.string().nullable(),
  type_generic: z.string().nullable(),
  user_id: z.string().nullable(),
});

export const messagesWithIntegrationRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("inbox_integration_id_fkey"),
    columns: z.tuple([z.literal("integration_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("integrations"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("integrations_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("user_profiles"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("integrations_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("users"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("messages_parent_message_id_fkey"),
    columns: z.tuple([z.literal("parent_message_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("messages"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("messages_parent_message_id_fkey"),
    columns: z.tuple([z.literal("parent_message_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("messages_with_integration"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("messages_report_id_fkey"),
    columns: z.tuple([z.literal("report_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("reports"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("messages_thread_id_fkey"),
    columns: z.tuple([z.literal("thread_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("threads"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const organizationSubscriptionsRowSchema = z.object({
  current_period_end: z.string().nullable(),
  current_period_start: z.string().nullable(),
  interval: z.string().nullable(),
  organization_id: z.string().nullable(),
  organization_name: z.string().nullable(),
  price_id: z.string().nullable(),
  price_nickname: z.string().nullable(),
  price_type: z.string().nullable(),
  product_name: z.string().nullable(),
  subscription_created_at: z.string().nullable(),
  subscription_id: z.string().nullable(),
  subscription_status: z.string().nullable(),
  subscription_tier: z.string().nullable(),
  total_seats: z.number().nullable(),
  unit_amount: z.number().nullable(),
  used_seats: z.number().nullable(),
});

export const organizationSubscriptionsRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("subscriptions_price_id_fkey"),
    columns: z.tuple([z.literal("price_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("prices"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const tagWithDecayedImportanceRowSchema = z.object({
  color: z.string().nullable(),
  created_at: z.string().nullable(),
  decayed_importance: z.number().nullable(),
  embedding: z.string().nullable(),
  emoji: z.string().nullable(),
  id: z.string().nullable(),
  importance: z.number().nullable(),
  importance_updated: z.string().nullable(),
  is_discrete: z.boolean().nullable(),
  is_special_case: z.boolean().nullable(),
  subtext: z.string().nullable(),
  tag_name: z.string().nullable(),
  updated_at: z.string().nullable(),
  user_id: z.string().nullable(),
});

export const tagWithDecayedImportanceInsertSchema = z.object({
  color: z.string().optional().nullable(),
  created_at: z.string().optional().nullable(),
  decayed_importance: z.never().optional(),
  embedding: z.string().optional().nullable(),
  emoji: z.string().optional().nullable(),
  id: z.string().optional().nullable(),
  importance: z.number().optional().nullable(),
  importance_updated: z.string().optional().nullable(),
  is_discrete: z.boolean().optional().nullable(),
  is_special_case: z.boolean().optional().nullable(),
  subtext: z.string().optional().nullable(),
  tag_name: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
  user_id: z.string().optional().nullable(),
});

export const tagWithDecayedImportanceUpdateSchema = z.object({
  color: z.string().optional().nullable(),
  created_at: z.string().optional().nullable(),
  decayed_importance: z.never().optional(),
  embedding: z.string().optional().nullable(),
  emoji: z.string().optional().nullable(),
  id: z.string().optional().nullable(),
  importance: z.number().optional().nullable(),
  importance_updated: z.string().optional().nullable(),
  is_discrete: z.boolean().optional().nullable(),
  is_special_case: z.boolean().optional().nullable(),
  subtext: z.string().optional().nullable(),
  tag_name: z.string().optional().nullable(),
  updated_at: z.string().optional().nullable(),
  user_id: z.string().optional().nullable(),
});

export const tagWithDecayedImportanceRelationshipsSchema = z.tuple([
  z.object({
    foreignKeyName: z.literal("tag_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("user_profiles"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
  z.object({
    foreignKeyName: z.literal("tag_user_id_fkey"),
    columns: z.tuple([z.literal("user_id")]),
    isOneToOne: z.literal(false),
    referencedRelation: z.literal("users"),
    referencedColumns: z.tuple([z.literal("id")]),
  }),
]);

export const userProfilesRowSchema = z.object({
  email: z.string().nullable(),
  full_name: z.string().nullable(),
  id: z.string().nullable(),
});

export const userProfilesRelationshipsSchema = z.tuple([]);

export const userRoleSchema = z.union([
  z.literal("product management"),
  z.literal("engineering"),
  z.literal("hr"),
  z.literal("finance"),
  z.literal("marketing"),
  z.literal("sales"),
  z.literal("operations"),
  z.literal("design"),
  z.literal("legal"),
  z.literal("other"),
]);

export const attendeeSchema = z.object({
  id: z.number().nullable(),
  email: z.string().nullable(),
  name: z.string().nullable(),
  organizer: z.boolean().nullable(),
  response_status: z.string().nullable(),
  comment: z.string().nullable(),
});

export const eventsRowSchema = z.object({
  all_day: z.boolean(),
  attendee_entity_ids: z.array(z.string()).nullable(),
  attendees: z.array(attendeeSchema).nullable(),
  calendar_id: z.number(),
  created_at: z.string(),
  description: z.string().nullable(),
  end_time: z.string(),
  event_id_3p: z.string(),
  event_link: z.string().nullable(),
  html_link: z.string().nullable(),
  id: z.number(),
  organizer_entity_id: z.string().nullable(),
  phone_number: z.string().nullable(),
  recurring: z.boolean(),
  report_id: z.number().nullable(),
  rundown: z.string().nullable(),
  start_time: z.string(),
  status: z.string(),
  title: z.string(),
  updated_at: z.string(),
});

export const eventsInsertSchema = z.object({
  all_day: z.boolean().optional(),
  attendee_entity_ids: z.array(z.string()).optional().nullable(),
  attendees: z.array(attendeeSchema).optional().nullable(),
  calendar_id: z.number(),
  created_at: z.string().optional(),
  description: z.string().optional().nullable(),
  end_time: z.string(),
  event_id_3p: z.string(),
  event_link: z.string().optional().nullable(),
  html_link: z.string().optional().nullable(),
  id: z.never().optional(),
  organizer_entity_id: z.string().optional().nullable(),
  phone_number: z.string().optional().nullable(),
  recurring: z.boolean().optional(),
  report_id: z.number().optional().nullable(),
  rundown: z.string().optional().nullable(),
  start_time: z.string(),
  status: z.string(),
  title: z.string(),
  updated_at: z.string().optional(),
});

export const eventsUpdateSchema = z.object({
  all_day: z.boolean().optional(),
  attendee_entity_ids: z.array(z.string()).optional().nullable(),
  attendees: z.array(attendeeSchema).optional().nullable(),
  calendar_id: z.number().optional(),
  created_at: z.string().optional(),
  description: z.string().optional().nullable(),
  end_time: z.string().optional(),
  event_id_3p: z.string().optional(),
  event_link: z.string().optional().nullable(),
  html_link: z.string().optional().nullable(),
  id: z.never().optional(),
  organizer_entity_id: z.string().optional().nullable(),
  phone_number: z.string().optional().nullable(),
  recurring: z.boolean().optional(),
  report_id: z.number().optional().nullable(),
  rundown: z.string().optional().nullable(),
  start_time: z.string().optional(),
  status: z.string().optional(),
  title: z.string().optional(),
  updated_at: z.string().optional(),
});

export const meetingsRowSchema = z.object({
  attendees: z.array(attendeeSchema).nullable(),
  content: z.string().nullable(),
  created_at: z.string(),
  created_at_system: z.string(),
  drive_url: z.string(),
  event_id: z.number().nullable(),
  event_id_3p: z.string().nullable(),
  file_id_3p: z.string(),
  id: z.number(),
  integration_id: z.number(),
  mime_type: z.string(),
  modified_at: z.string(),
  name: z.string(),
  report_id: z.number().nullable(),
  rundown: z.string().nullable(),
  updated_at: z.string(),
});

export const meetingsInsertSchema = z.object({
  attendees: z.array(attendeeSchema).optional().nullable(),
  content: z.string().optional().nullable(),
  created_at: z.string(),
  created_at_system: z.string().optional(),
  drive_url: z.string(),
  event_id: z.number().optional().nullable(),
  event_id_3p: z.string().optional().nullable(),
  file_id_3p: z.string(),
  id: z.never().optional(),
  integration_id: z.number(),
  mime_type: z.string(),
  modified_at: z.string(),
  name: z.string(),
  report_id: z.number().optional().nullable(),
  rundown: z.string().optional().nullable(),
  updated_at: z.string().optional(),
});

export const meetingsUpdateSchema = z.object({
  attendees: z.array(attendeeSchema).optional().nullable(),
  content: z.string().optional().nullable(),
  created_at: z.string().optional(),
  created_at_system: z.string().optional(),
  drive_url: z.string().optional(),
  event_id: z.number().optional().nullable(),
  event_id_3p: z.string().optional().nullable(),
  file_id_3p: z.string().optional(),
  id: z.never().optional(),
  integration_id: z.number().optional(),
  mime_type: z.string().optional(),
  modified_at: z.string().optional(),
  name: z.string().optional(),
  report_id: z.number().optional().nullable(),
  rundown: z.string().optional().nullable(),
  updated_at: z.string().optional(),
});

export const usersRowSchema = z.object({
  auth_completed: z.boolean(),
  avatar_url: z.string().nullable(),
  billing_address: jsonSchema.nullable(),
  consolidated_weekend: z.boolean(),
  created_at: z.string(),
  full_name: z.string().nullable(),
  id: z.string(),
  inactivity_notified_at: z.string().nullable(),
  inactivity_status: inactivityStatusSchema,
  last_activity_at: z.string().nullable(),
  last_report_date: z.string().nullable(),
  onboard_report_once: z.boolean(),
  onboarded: z.boolean(),
  onboarding_step: z.number(),
  payment_method: jsonSchema.nullable(),
  preferred_name: z.string().nullable(),
  report_pending: z.boolean(),
  report_time: z.string(),
  role: z.string().nullable(),
  timezone: z.string().nullable(),
  updated_at: z.string().nullable(),
  voice_id: z.string(),
});
