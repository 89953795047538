import React from "react";
import { Link } from "react-router-dom";
import { getEmailFromString, toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import { Integration } from "@attrove/service-supabase";
import { Card, Icons, Button, Badge } from "@attrove/ui-shadcn";
import { IntegrationActions } from "./integration-actions";
import { ArrowRight } from "lucide-react";
import { useSubscriptionFeatures } from '../../context/SubscriptionContext';

const PROVIDER_DISPLAY_NAMES: Record<string, string> = {
  gmail: "Gmail",
  google: "Gmail",
  google_calendar: "Google Calendar",
  google_meet: "Google Meet",
  slack: "Slack",
  // Add more mappings as needed
};

const getProviderDisplayName = (provider: string): string =>
  PROVIDER_DISPLAY_NAMES[provider] || provider.charAt(0).toUpperCase() + provider.slice(1);

// Helper to get display name based on provider type
const getIntegrationDisplayName = (integration: Integration): string => {
  switch (integration.provider) {
    case 'slack':
      return integration.name; // Already formatted nicely from backend
    case 'gmail':
    case 'google':
    case 'outlook':
      return getEmailFromString(integration.name) || integration.name;
    default:
      return integration.name;
  }
};

interface IntegrationCardProps {
  integration: Integration;
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({ integration }) => {
  const Icon = Icons[integration.provider as keyof typeof Icons];
  const title = getProviderDisplayName(integration.provider);
  const displayName = getIntegrationDisplayName(integration);
  const { currentTier, isPro, isEnterprise } = useSubscriptionFeatures();
  
  const hasAccess = !integration.required_tier || 
    integration.required_tier === 'free' ||
    (integration.required_tier === 'pro' && (isPro || isEnterprise)) ||
    (integration.required_tier === 'enterprise' && isEnterprise);

  return (
    <Card className="bg-card hover:bg-accent/50 transition-colors">
      {/* Row 1: Icon and Title */}
      <div className="p-4 flex items-center gap-4">
        <div className="h-10 w-10 shrink-0 rounded-lg bg-primary/5 flex items-center justify-center">
          <Icon className="w-6 h-6 text-primary" />
        </div>
        <div className="min-w-0 flex-1">
          <div className="flex items-center gap-2 mb-0.5">
            <h3 className="text-base font-medium truncate">{title}</h3>
            {integration.is_active ? (
              <Badge variant="secondary" className="h-5 bg-green-500/10 text-green-500 border-0 text-xs font-normal">
                Connected
              </Badge>
            ) : (
              <Badge variant="secondary" className="h-5 bg-yellow-500/10 text-yellow-500 border-0 text-xs font-normal">
                Disconnected
              </Badge>
            )}
          </div>
          <p className="text-sm text-muted-foreground truncate">{displayName}</p>
        </div>
      </div>

      {/* Row 2: Action Buttons */}
      <div className="px-4 py-2 flex items-center gap-2 border-t border-border/40">
        <div className="flex items-center gap-2">
          <Link to={`/integrations/${integration.id}`} className="flex-none">
            <Button variant="secondary" size="sm" className="h-8">
              View Details
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </Link>
          <IntegrationActions 
            integration={integration}
          />
        </div>
      </div>

      {/* Row 3: Sync Status */}
      <div className="px-4 py-2 flex items-center gap-2 border-t border-border/40">
        <div className="w-1.5 h-1.5 rounded-full bg-green-500" />
        <span className="text-xs text-muted-foreground">
          Last synced {integration.last_synced_at ? toTimeAgo(integration.last_synced_at) : 'Never'}
        </span>
      </div>
    </Card>
  );
};

export default IntegrationCard;
