import React from 'react';
import { queryClient } from "@attrove/attrove-ui/app/react-query";
import { supabase } from "@attrove/attrove-ui/app/supabase";
import { toTimeAgo } from "@attrove/attrove-ui/lib/utils";
import {
  getIntegrationsWithCalendarCounts,
  updateCalendar,
} from "@attrove/service-supabase";
import { Checkbox, Icons, ScrollArea, Card, Skeleton } from "@attrove/ui-shadcn";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Loader2, Calendar as CalendarIcon, Clock, Users } from "lucide-react";
import { IntegrationActions } from "../integration-actions";

export const GET_INTEGRATION_WITH_CALENDAR_COUNTS = "getIntegrationWithCalendarCounts";

interface CalendarItemProps {
  calendar: any;
  onToggle: (checked: boolean) => void;
}

const CalendarItem: React.FC<CalendarItemProps> = ({ calendar, onToggle }) => (
  <div className="group grid grid-cols-[auto_1fr] gap-4 items-start p-4 bg-card rounded-lg border transition-all duration-200 hover:bg-accent/5 hover:shadow-sm">
    <Checkbox
      id={`calendar-${calendar.id}`}
      checked={calendar.active}
      onCheckedChange={onToggle}
      className="mt-1"
    />
    <div className="min-w-0">
      <div className="flex items-center gap-3 mb-2">
        <div className="flex-shrink-0 p-2 rounded-full bg-primary/10">
          <CalendarIcon className="w-4 h-4 text-primary" />
        </div>
        <div>
          <h3 className="font-semibold text-foreground group-hover:text-primary transition-colors">
            {calendar.title}
          </h3>
          <p className="text-sm text-muted-foreground">
            {calendar.event_count.toLocaleString()} {calendar.event_count === 1 ? 'event' : 'events'}
          </p>
        </div>
      </div>
      {calendar.description && (
        <p className="text-sm text-muted-foreground">
          {calendar.description}
        </p>
      )}
    </div>
  </div>
);

export const CalendarDetail: React.FC<{ integrationId: number }> = ({ integrationId }) => {
  const { data: integration, isLoading, error } = useQuery({
    queryKey: [GET_INTEGRATION_WITH_CALENDAR_COUNTS, integrationId],
    queryFn: () => getIntegrationsWithCalendarCounts(supabase, integrationId),
  });

  const toggleActiveCalendar = useMutation({
    mutationFn: async ({ calendarId, active }: { calendarId: number; active: boolean }) => {
      return await updateCalendar(supabase, calendarId, { active });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ 
        queryKey: [GET_INTEGRATION_WITH_CALENDAR_COUNTS, integrationId] 
      });
    },
  });

  if (isLoading) {
    return (
      <div className="pt-6">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <Skeleton className="h-8 w-48 mb-4" />
          <Skeleton className="h-32 w-full" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-64 text-destructive">
        Error loading calendar details. Please try again.
      </div>
    );
  }

  if (!integration?.calendars) {
    return (
      <div className="flex items-center justify-center h-64 text-muted-foreground">
        No calendar data available.
      </div>
    );
  }

  function removeCalendars<T extends { calendars?: any }>(obj: T): Omit<T, "calendars"> {
    const { calendars, ...rest } = obj;
    return rest;
  }

  const integrationWithoutCals = removeCalendars(integration);
  const Gcal = Icons["google_calendar"];
  const activeCalendars = integration.calendars.filter(cal => cal.active).length;

  return (
    <div className="pt-6">
      {/* Header Section */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 mb-8">
        <div className="flex items-center gap-4 mb-6">
          <div className="h-12 w-12 rounded-lg bg-primary/5 flex items-center justify-center">
            <Gcal className="w-8 h-8 text-primary" />
          </div>
          <div>
            <h1 className="text-2xl font-semibold">Google Calendar Integration</h1>
            <p className="text-muted-foreground">
              Connected as {integration.name}
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="grid grid-cols-1 gap-6">
          {/* Integration Status Card */}
          <Card className="p-6">
            <div className="flex justify-between items-start mb-6">
              <h2 className="text-lg font-medium">Integration Status</h2>
              <div className={`px-2 py-1 rounded-full text-xs ${integration.is_active ? 'bg-green-500/10 text-green-500' : 'bg-yellow-500/10 text-yellow-500'}`}>
                {integration.is_active ? 'Active' : 'Inactive'}
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center gap-2 text-muted-foreground">
                <Users className="w-4 h-4" />
                <span>Account: {integration.name}</span>
              </div>
              <div className="flex items-center gap-2 text-muted-foreground">
                <CalendarIcon className="w-4 h-4" />
                <span>{activeCalendars} of {integration.calendars.length} calendars enabled</span>
              </div>
              <div className="flex items-center gap-2 text-muted-foreground">
                <CalendarIcon className="w-4 h-4" />
                <span>{integration.total_events.toLocaleString()} total events</span>
              </div>
              
              {integration.last_synced_at && (
                <div className="flex items-center gap-2 text-muted-foreground">
                  {integration.pending_sync ? (
                    <div className="flex items-center gap-2">
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Currently syncing...</span>
                    </div>
                  ) : (
                    <>
                      <Clock className="w-4 h-4" />
                      <span>Last synced {toTimeAgo(integration.last_synced_at)}</span>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="mt-6">
              <IntegrationActions integration={integrationWithoutCals} />
            </div>
          </Card>

          {/* Calendars Card */}
          <Card className="p-6">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-lg font-medium">Connected Calendars</h2>
              <span className="text-sm text-muted-foreground">
                {activeCalendars} of {integration.calendars.length} enabled
              </span>
            </div>

            <ScrollArea className="h-[calc(100vh-460px)]">
              <div className="space-y-3">
                {integration.calendars.length > 0 ? (
                  integration.calendars.map((calendar) => (
                    <CalendarItem
                      key={calendar.id}
                      calendar={calendar}
                      onToggle={(checked) => 
                        toggleActiveCalendar.mutate({ 
                          calendarId: calendar.id, 
                          active: checked 
                        })
                      }
                    />
                  ))
                ) : (
                  <div className="flex flex-col items-center justify-center py-12 text-muted-foreground">
                    <div className="bg-muted/30 p-4 rounded-full mb-4">
                      <CalendarIcon className="w-8 h-8" />
                    </div>
                    <p className="font-medium mb-1">No calendars found</p>
                    <p className="text-sm">Connect calendars to start syncing events</p>
                  </div>
                )}
              </div>
            </ScrollArea>
          </Card>
        </div>
      </div>
    </div>
  );
};