import { cn } from "@attrove/ui-shadcn";
import { Sparkle } from "lucide-react";

type PremiumBadgeProps = {
  tier: 'pro' | 'enterprise';
  className?: string;
};

export const PremiumBadge = ({ tier, className }: PremiumBadgeProps) => {
  const baseClasses = "inline-flex items-center px-1.5 py-0.5 text-xs font-medium rounded-full tracking-tight text-foreground";
  const variants = {
    pro: "bg-gradient-to-r from-brand-primary-500/20 to-purple-500/20 relative overflow-hidden shadow-sm",
    enterprise: "bg-gradient-to-r from-brand-primary-600/20 to-purple-600/20 relative overflow-hidden shadow-sm"
  };
  
  return (
    <span className={cn(baseClasses, variants[tier], "group", className)}>
      <Sparkle className="w-3 h-3 mr-0.5 animate-pulse text-brand-primary-500" />
      <span>{tier === 'pro' ? 'Pro' : 'Ent'}</span>
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent group-hover:translate-x-full -translate-x-full transition-transform duration-1000 ease-in-out" />
    </span>
  );
}; 