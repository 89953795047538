import React, { useState, useMemo } from 'react';
import { MeetingDetail } from "@attrove/attrove-ui/features/schedule/MeetingDetail";
import { toTime, toFullDate } from "@attrove/attrove-ui/lib/utils";
import { EventRow, EventMessagesResponse, GET_MESSAGES_FOR_EVENTS_QUERY_KEY, getMessagesForEvents } from "@attrove/service-supabase";
import {
  Card,
  Progress,
  ScrollArea,
  cn,
} from "@attrove/ui-shadcn";
import { Calendar, Clock, ArrowLeft, ChevronRight, ChevronDown } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { supabase } from "@attrove/attrove-ui/app/supabase";

export enum MeetingType {
  SUMMARY = "summary",
  PARTICIPANTS = "participants",
}

export interface SideScreen {
  type: MeetingType;
  event: EventRow | undefined;
}

const LoadingState = () => (
  <div className="flex flex-col items-center justify-center h-screen gap-4">
    <div className="w-64">
      <Progress className="h-2 bg-accent/20" value={null} />
    </div>
    <p className="text-muted-foreground">Loading schedule...</p>
  </div>
);

// Helper to group events by month
const groupEventsByMonth = (events: EventRow[]) => {
  return events.reduce((acc, event) => {
    const date = new Date(event.start_time);
    const monthKey = `${date.getFullYear()}-${date.getMonth()}`;
    if (!acc[monthKey]) {
      acc[monthKey] = {
        title: date.toLocaleString("default", { month: "long", year: "numeric" }),
        year: date.getFullYear(),
        events: [],
      };
    }
    acc[monthKey].events.push(event);
    return acc;
  }, {} as Record<string, { title: string; year: number; events: EventRow[] }>);
};

const TimelineSection = ({ 
  title, 
  events, 
  isExpanded, 
  onToggle, 
  selectedEvent,
  setSearchParams,
}: { 
  title: string;
  events: EventRow[];
  isExpanded: boolean;
  onToggle: () => void;
  selectedEvent?: EventRow;
  setSearchParams: (params: string) => void;
}) => {
  return (
    <div className="mb-2">
      <button
        onClick={onToggle}
        className="w-full flex items-center justify-between p-2 hover:bg-accent/10 rounded-lg text-sm transition-all duration-200 group"
      >
        <div className="flex items-center gap-2 min-w-0">
          {isExpanded ? 
            <ChevronDown className="h-4 w-4 text-primary flex-shrink-0" /> : 
            <ChevronRight className="h-4 w-4 text-primary flex-shrink-0" />
          }
          <span className="font-semibold text-foreground truncate">{title}</span>
        </div>
      </button>

      {isExpanded && (
        <div className="space-y-1 ml-6">
          {events.map((event) => {
            const isSelected = event.id === selectedEvent?.id;

            return (
              <div
                key={event.id}
                onClick={() => setSearchParams(`?eventId=${event.id}`)}
                className={cn(
                  "p-2 rounded-lg transition-all duration-200 cursor-pointer group",
                  isSelected ? "bg-primary text-primary-foreground" : "hover:bg-accent/10"
                )}
              >
                <div className="min-w-0">
                  <div className="font-medium truncate">{event.title}</div>
                  <div className={cn(
                    "flex items-center gap-2 text-xs mt-1",
                    isSelected ? "text-primary-foreground/70" : "text-muted-foreground"
                  )}>
                    <div className="flex items-center gap-1.5 min-w-0">
                      <Calendar className="h-3 w-3 flex-shrink-0" />
                      <span className="truncate">{dayjs(event.start_time).format('dddd, MMM D')}</span>
                    </div>
                    <div className="flex items-center gap-1.5 min-w-0">
                      <Clock className="h-3 w-3 flex-shrink-0" />
                      <span className="truncate">{toTime(event.start_time)}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const Schedule: React.FC<{ events: EventRow[] }> = React.memo(({ events }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const eventIds = useMemo(() => events.map((event) => event.id), [events]);

  const { data: eventMessages, isLoading: messagesLoading } = useQuery<EventMessagesResponse>({
    queryKey: [GET_MESSAGES_FOR_EVENTS_QUERY_KEY, eventIds],
    queryFn: () => getMessagesForEvents(supabase, eventIds),
    enabled: eventIds.length > 0,
  });

  // Find next upcoming event
  const nextUpcomingEvent = useMemo(() => {
    const now = new Date();
    return events.find(event => new Date(event.end_time) > now);
  }, [events]);

  // Group events by month
  const groupedEvents = useMemo(() => groupEventsByMonth(events), [events]);

  // Set default expanded months (current and next month)
  const [expandedMonths, setExpandedMonths] = useState(() => {
    const currentDate = new Date();
    const currentKey = `${currentDate.getFullYear()}-${currentDate.getMonth()}`;
    const nextMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    const nextKey = `${nextMonth.getFullYear()}-${nextMonth.getMonth()}`;
    return { [currentKey]: true, [nextKey]: true };
  });

  const eventId = searchParams.get("eventId");
  const selectedEvent = eventId ? events.find(e => String(e.id) === eventId) : nextUpcomingEvent;
  const showEventList = !eventId;

  const toggleMonth = (monthKey: string) => {
    setExpandedMonths(prev => ({
      ...prev,
      [monthKey]: !prev[monthKey]
    }));
  };

  if (!events.length) {
    return (
      <Card className="max-w-md mx-auto mt-12">
        <div className="flex flex-col items-center justify-center p-6 text-center">
          <Calendar className="h-12 w-12 text-muted-foreground mb-4" />
          <h3 className="text-lg font-semibold mb-2">No upcoming meetings</h3>
          <p className="text-sm text-muted-foreground">Enjoy your free time!</p>
        </div>
      </Card>
    );
  }

  if (messagesLoading) {
    return <LoadingState />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4">
      {/* Header Section */}
      <div className="border-b">
        <div className="py-6">
          <div className="mb-6">
            <div className="flex items-center gap-4 min-w-0">
              {!showEventList && (
                <button 
                  onClick={() => navigate("/schedule")}
                  className="lg:hidden p-2 hover:bg-accent/10 rounded-lg flex-shrink-0"
                >
                  <ArrowLeft className="h-5 w-5" />
                </button>
              )}
              <div className="min-w-0">
                <h1 className="text-3xl font-bold truncate">Schedule</h1>
                {nextUpcomingEvent && (
                  <div className="flex flex-wrap items-center gap-3 mt-2 text-muted-foreground text-sm">
                    <span className="font-bold">Next Meeting:</span>
                    <div className="flex items-center gap-1.5 min-w-0">
                      <Calendar className="h-4 w-4 text-primary flex-shrink-0" />
                      <span className="truncate">{toFullDate(nextUpcomingEvent.start_time)}</span>
                    </div>
                    <div className="flex items-center gap-1.5 min-w-0">
                      <Clock className="h-4 w-4 text-primary flex-shrink-0" />
                      <span className="truncate">{toTime(nextUpcomingEvent.start_time)}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="lg:hidden">
        {showEventList ? (
          <div className="space-y-4 py-4">
            {Object.entries(groupedEvents).map(([monthKey, { title, events }]) => (
              <div key={monthKey}>
                <div className="text-sm font-semibold text-muted-foreground mb-2 truncate">
                  {title}
                </div>
                {events.map((event) => (
                  <Card
                    key={event.id}
                    onClick={() => setSearchParams(`?eventId=${event.id}`)}
                    className="p-4 cursor-pointer hover:bg-accent/50 transition-colors mb-2"
                  >
                    <div className="min-w-0">
                      <p className="font-medium truncate">{event.title}</p>
                      <div className="flex items-center gap-2 text-sm text-muted-foreground mt-1">
                        <div className="flex items-center gap-1.5 min-w-0">
                          <Calendar className="h-3 w-3 flex-shrink-0" />
                          <span className="truncate">{dayjs(event.start_time).format('dddd, MMM D')}</span>
                        </div>
                        <div className="flex items-center gap-1.5 min-w-0">
                          <Clock className="h-3 w-3 flex-shrink-0" />
                          <span className="truncate">{toTime(event.start_time)}</span>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            ))}
          </div>
        ) : selectedEvent ? (
          <div className="py-4">
            <MeetingDetail 
              screen={{ type: MeetingType.SUMMARY, event: selectedEvent }}
              messages={eventMessages}
            />
          </div>
        ) : null}
      </div>

      {/* Desktop View */}
      <div className="hidden lg:grid lg:grid-cols-12 gap-6 py-6">
        {/* Timeline - 4 columns */}
        <Card className="h-[calc(100vh-300px)] col-span-4 flex flex-col">
          <div className="p-4 border-b flex-shrink-0">
            <h2 className="text-lg font-semibold truncate">Timeline</h2>
          </div>
          <ScrollArea className="flex-1">
            <div className="p-2">
              {Object.entries(groupedEvents).map(([monthKey, { title, events }]) => (
                <TimelineSection
                  key={monthKey}
                  title={title}
                  events={events}
                  selectedEvent={selectedEvent}
                  setSearchParams={setSearchParams}
                  isExpanded={expandedMonths[monthKey]}
                  onToggle={() => toggleMonth(monthKey)}
                />
              ))}
            </div>
          </ScrollArea>
        </Card>

        {/* Meeting Detail - 8 columns */}
        <div className="col-span-8">
          {selectedEvent && (
            <MeetingDetail
              screen={{ type: MeetingType.SUMMARY, event: selectedEvent }}
              messages={eventMessages}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default Schedule;