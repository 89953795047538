import { type AttroveSupabaseClient, DB } from "@attrove/service-supabase";
import { logError, logInfo } from "@attrove/util-logs";
import { Logtail } from "@logtail/node";

export interface Tag {
  id: string;
  user_id: string;
  tag_name: string;
  importance: number;
  importance_updated: string;
  is_muted: boolean;
  created_at: string;
  updated_at: string;
}

export const muteTag = async (
  supabaseClient: AttroveSupabaseClient,
  tagId: string,
  logtail?: Logtail
): Promise<boolean> => {
  try {
    const { error } = await supabaseClient.rpc('mute_tag', { p_tag_id: tagId });

    if (error) {
      logError(
        '[muteTag] Error muting tag',
        { error, tagId },
        logtail
      );
      return false;
    }

    logInfo(
      '[muteTag] Successfully muted tag',
      { tagId },
      logtail
    );
    return true;
  } catch (error) {
    logError(
      '[muteTag] Unexpected error',
      { error: error instanceof Error ? error.message : String(error), tagId },
      logtail
    );
    return false;
  }
};

export const unmuteTag = async (
  supabaseClient: AttroveSupabaseClient,
  tagId: string,
  logtail?: Logtail
): Promise<boolean> => {
  try {
    const { error } = await supabaseClient.rpc('unmute_tag', { p_tag_id: tagId });

    if (error) {
      logError(
        '[unmuteTag] Error unmuting tag',
        { error, tagId },
        logtail
      );
      return false;
    }

    logInfo(
      '[unmuteTag] Successfully unmuted tag',
      { tagId },
      logtail
    );
    return true;
  } catch (error) {
    logError(
      '[unmuteTag] Unexpected error',
      { error: error instanceof Error ? error.message : String(error), tagId },
      logtail
    );
    return false;
  }
};

export const batchUnmuteTags = async (
  supabaseClient: AttroveSupabaseClient,
  tagIds: string[],
  logtail?: Logtail
): Promise<boolean> => {
  try {
    // Use Promise.all to run unmute operations in parallel
    const results = await Promise.all(
      tagIds.map(tagId => unmuteTag(supabaseClient, tagId, logtail))
    );

    // Check if all operations were successful
    const allSuccessful = results.every(result => result === true);

    if (!allSuccessful) {
      logError(
        '[batchUnmuteTags] Some tags failed to unmute',
        { tagIds, results },
        logtail
      );
    }

    return allSuccessful;
  } catch (error) {
    logError(
      '[batchUnmuteTags] Unexpected error',
      { error: error instanceof Error ? error.message : String(error), tagIds },
      logtail
    );
    return false;
  }
};

export const getTag = async (
  supabaseClient: AttroveSupabaseClient,
  tagId: string,
  logtail?: Logtail
): Promise<Tag | null> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.TAGS)
      .select(`
        id,
        user_id,
        tag_name,
        importance,
        importance_updated,
        is_muted,
        created_at,
        updated_at
      `)
      .eq('id', tagId)
      .single();

    if (error) {
      logError(
        '[getTag] Error fetching tag',
        { error, tagId },
        logtail
      );
      return null;
    }

    return data as Tag;
  } catch (error) {
    logError(
      '[getTag] Unexpected error',
      { error: error instanceof Error ? error.message : String(error), tagId },
      logtail
    );
    return null;
  }
}; 