import { Textarea, Label } from "@attrove/ui-shadcn";

const EXAMPLE_GOALS = [
  "I want to improve team collaboration and reduce communication bottlenecks",
  "I need to stay on top of critical project deadlines and dependencies",
  "I want to identify and resolve team blockers more efficiently",
  "I aim to better understand my team's progress and challenges"
];

// Select a random goal once at module level, not on each render
const DEFAULT_PLACEHOLDER = EXAMPLE_GOALS[Math.floor(Math.random() * EXAMPLE_GOALS.length)];

type InputProps = {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
};

export const CurrentGoalInput: React.FC<InputProps> = ({ 
  value, 
  onChange,
  onBlur,
  isLoading,
  disabled
}) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="space-y-2">
        <h2 className="text-lg font-semibold">Current Goal</h2>
        <p className="text-sm text-muted-foreground">
          Your goal helps us prioritize information and tailor insights to what matters most to you.
        </p>
      </div>
      <div className="space-y-2">
        <Label className="font-medium">What's your current goal?</Label>
        <Textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          placeholder={DEFAULT_PLACEHOLDER}
          disabled={isLoading || disabled}
          className="min-h-[120px] resize-none text-sm"
        />
      </div>
    </div>
  );
};
