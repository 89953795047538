import { type AttroveSupabaseClient, DB, eventsInsertSchema, eventsRowSchema, attendeeSchema } from "@attrove/service-supabase";
import { logError, logWarn, logInfo } from "@attrove/util-logs";
import { Logtail } from "@logtail/node";
import dayjs from "dayjs";
import { z } from "zod";

export type EventsInsert = z.infer<typeof eventsInsertSchema>;
export type EventRow = z.infer<typeof eventsRowSchema>;
export type eventAttendee = z.infer<typeof attendeeSchema>;

export interface CalendarEventCount {
  calendar_id: number;
  event_count: number;
}

export interface IntegrationEventCounts {
  total_events: number;
  calendar_counts: CalendarEventCount[];
}

export interface EventSummary {
  id: number;
  title: string;
  start_time: Date;
  end_time: Date;
}

// TODO: review this function
export const upsertEventsMany = async (
  supabaseClient: AttroveSupabaseClient,
  eventsData: EventsInsert[],
  logtail?: Logtail,
): Promise<{ id: number; event_id_3p: string }[] | null> => {
  try {
    if (!Array.isArray(eventsData)) {
      throw new Error(`eventsData is not an array. Received: ${typeof eventsData}`);
    }

    logInfo("[upsertEventsMany] Starting to process events", { count: eventsData.length }, logtail);

    const validatedEventsData = eventsData
      .map((event, index) => {
        try {
          if (typeof event !== "object" || event === null) {
            throw new Error(`Invalid event object at index ${index}`);
          }

          const { ...cleanedEvent } = event;

          // Parse start and end times
          const start_time = parseJsonDate(cleanedEvent.start_time);
          const end_time = parseJsonDate(cleanedEvent.end_time);

          if (!start_time || !end_time) {
            throw new Error(`Invalid start_time or end_time for event at index ${index}`);
          }

          // Parse created and updated times
          const created_at = parseJsonDate(cleanedEvent.created_at);
          // const updated_at = parseJsonDate(cleanedEvent.updated_at); // Always update the updated_at field

          // Check for required fields
          const requiredFields = ["id", "calendar_id", "start", "end", "title"];
          for (const field of requiredFields) {
            if (!cleanedEvent[field as keyof typeof cleanedEvent]) {
              throw new Error(`Missing required field "${field}" for event at index ${index}`);
            }
          }

          // Validate and transform each field
          const validatedEvent = {
            all_day: Boolean(cleanedEvent.all_day),
            attendees: Array.isArray(cleanedEvent.attendees)
              ? cleanedEvent.attendees.map((attendee) => ({
                  id: Number(attendee.id) || 0,
                  email: String(attendee.email || ""),
                  name: String(attendee.name || ""),
                  organizer: Boolean(attendee.organizer),
                  response_status: String(attendee.response_status || ""),
                }))
              : [],
            calendar_id: Number(cleanedEvent.calendar_id),
            created_at: created_at ? created_at : new Date().toISOString(),
            description: String(cleanedEvent.description || ""),
            end_time: end_time,
            event_id_3p: String(cleanedEvent.id),
            html_link: String(cleanedEvent.html_link || ""),
            event_link: String(cleanedEvent.event_link || ""),
            phone_number: String(cleanedEvent.phone_number || ""),
            recurring: Boolean(cleanedEvent.recurring),
            start_time: start_time,
            status: String(cleanedEvent.status || "confirmed"),
            title: String(cleanedEvent.title),
            updated_at: new Date().toISOString(),
            organizer_entity_id: cleanedEvent.organizer_entity_id,
            attendee_entity_ids: cleanedEvent.attendee_entity_ids,
            };

          return validatedEvent;
        } catch (error) {
          logError(
            "[upsertEventsMany] Error processing event",
            {
              error: error instanceof Error ? error.message : "Unknown error",
              stack: error instanceof Error ? error.stack : undefined,
              eventIndex: index,
              eventData: JSON.stringify(event),
            },
            logtail,
          );
          return null;
        }
      })
      .filter((event): event is NonNullable<typeof event> => event !== null);

    // logInfo(
    //   "[upsertEventsMany] Processed events",
    //   {
    //     totalEvents: eventsData.length,
    //     validEvents: validatedEventsData.length,
    //   },
    //   logtail,
    // );

    if (validatedEventsData.length === 0) {
      logWarn("[upsertEventsMany] No valid events to upsert", {}, logtail);
      return [];
    }
    // });

    // logInfo(
    //   "[upsertEventsMany] Processed events",
    //   {
    //     totalEvents: eventsData.length,
    //     validEvents: validatedEventsData.length,
    //   },
    //   logtail,
    // );

    if (validatedEventsData.length === 0) {
      logWarn("[upsertEventsMany] No valid events to upsert", {}, logtail);
      return [];
    }
    // TODO: fix this type error
    const { data: events, error: upsertEventError } = await supabaseClient
      .from(DB.EVENTS)
      .upsert(validatedEventsData as any, {
        onConflict: "event_id_3p,calendar_id",
        ignoreDuplicates: false,
      })
      .select("id, event_id_3p");

    if (upsertEventError) {
      logError(
        "[upsertEventsMany] Error upserting events",
        {
          error: upsertEventError,
          details: upsertEventError.details,
          message: upsertEventError.message,
          eventIds: validatedEventsData.map((e) => e.event_id_3p),
        },
        logtail,
      );
      return null;
    }

    if (!events || events.length === 0) {
      logWarn("[upsertEventsMany] No events returned after upsert", {}, logtail);
      return [];
    }

    logInfo("[upsertEventsMany] Successfully upserted events", { count: events.length }, logtail);
    return events;
  } catch (error) {
    logError(
      "[upsertEventsMany] Unexpected error",
      {
        error,
        message: error instanceof Error ? error.message : "Unknown error",
        stack: error instanceof Error ? error.stack : undefined,
      },
      logtail,
    );
    return [];
  }

  function parseJsonDate(dateInput: string | { dateTime: string } | { date: string } | undefined): string | null {
    if (!dateInput) {
      return null;
    }

    let dateString: string;

    if (typeof dateInput === "object") {
      if ("dateTime" in dateInput) {
        dateString = dateInput.dateTime;
      } else if ("date" in dateInput) {
        // Handle all-day events
        dateString = dateInput.date;
      } else {
        console.error(`Invalid date input object: ${JSON.stringify(dateInput)}`);
        return null;
      }
    } else if (typeof dateInput === "string") {
      dateString = dateInput;
    } else {
      console.error(`Invalid date input type: ${typeof dateInput}`);
      return null;
    }

    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }
      return date.toISOString();
    } catch (error) {
      console.error(`Error parsing date: ${dateString}`, error);
      return null;
    }
  }
};

export const GET_EVENTS_BY_USER = "getEventsByUser";
export const getEventsByUser = async (supabaseClient: AttroveSupabaseClient): Promise<EventRow[]> => {
  const today = dayjs().startOf("day").toISOString();

  const { data: events, error: getEventsError } = await supabaseClient
    .from(DB.EVENTS)
    .select("*")
    .gte("start_time", today)
    .order("start_time", { ascending: true })
    .limit(100); // TODO:// Limit this for now otherwise the UI becomes less responsive; eventually add infinite scroll -tmonzures 10/15/24

  if (getEventsError) {
    console.error(getEventsError.message);
    throw getEventsError;
  }

  return events;
};

export async function getEventsForIntegration(
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  startDate: string,
  endDate: string,
  limit = 100,
): Promise<EventRow[]> {
  logInfo(`[getEventsForIntegration] Fetching events for integration ${integrationId} between ${startDate} and ${endDate}`);

  try {
    // First, fetch calendar IDs associated with the integration
    const { data: calendars, error: calendarError } = await supabaseClient
      .from(DB.CALENDARS)
      .select("id")
      .eq("integration_id", integrationId);

    if (calendarError) {
      throw new Error(`Error fetching calendars: ${calendarError.message}`);
    }

    if (!calendars || calendars.length === 0) {
      logInfo(`[getEventsForIntegration] No calendars found for integration ${integrationId}`);
      return [];
    }

    const calendarIds = calendars.map((calendar) => calendar.id);

    // Now fetch events for these calendar IDs
    const {
      data: events,
      error: eventError,
      count,
    } = await supabaseClient
      .from(DB.EVENTS)
      .select("*", { count: "exact" })
      .in("calendar_id", calendarIds)
      .gte("start_time", startDate)
      .lte("end_time", endDate)
      .order("start_time", { ascending: true })
      .limit(limit);

    if (eventError) {
      throw new Error(`Error fetching events: ${eventError.message}`);
    }

    if (!events || events.length === 0) {
      logInfo(`[getEventsForIntegration] No events found for integration ${integrationId} in the specified date range`);
      return [];
    }

    logInfo(`[getEventsForIntegration] Successfully fetched ${events.length} events for integration ${integrationId}`,{ eventCount: events.length, totalCount: count, calendarCount: calendars.length });

    return events;
  } catch (error) {
    logError(`[getEventsForIntegration] Error fetching events for integration ${integrationId}`,{ error: error instanceof Error ? error.message : "Unknown error" });
    throw error;
  }
}

export async function updateEventsReportId(
  supabaseClient: AttroveSupabaseClient,
  eventIds: number[],
  reportId: number,
  logtail?: Logtail,
): Promise<void> {
  logInfo(`[updateEventsReportId] Updating report ID for ${eventIds.length} events`, {}, logtail);

  try {
    // const { error } = await supabaseClient
    //   .from(DB.EVENTS)
    //   .update({ report_id: reportId })
    //   .in('id', eventIds);

    // if (error) {
    //   throw new Error(`Error updating events: ${error.message}`);
    // }

    // logInfo(`[updateEventsReportId] Successfully updated report ID for ${eventIds.length} events`, {}, logtail);

    logWarn(`[updateEventsReportId] Function not implemented; must add report_id to the Events table first.`, {}, logtail);
  } catch (error) {
    logError(
      `[updateEventsReportId] Error updating report ID for events`,
      { error: error instanceof Error ? error.message : "Unknown error", eventIds, reportId },
      logtail,
    );
    throw error;
  }
}

export async function getEventCountsForIntegration(
  supabaseClient: AttroveSupabaseClient,
  integrationId: number,
  options?: {
    startDate?: string;  // ISO string
    includeHistorical?: boolean;
    logtail?: Logtail;
  }
): Promise<IntegrationEventCounts | null> {
  const { startDate, includeHistorical = false, logtail } = options || {};
  
  try {
    // First, get the calendar IDs for this integration
    const { data: calendars, error: calendarError } = await supabaseClient
      .from(DB.CALENDARS)
      .select('id')
      .eq('integration_id', integrationId)
      .eq('active', true);

    if (calendarError) {
      throw new Error(`Error fetching calendars: ${calendarError.message}`);
    }

    if (!calendars || calendars.length === 0) {
      return { total_events: 0, calendar_counts: [] };
    }

    const calendarIds = calendars.map(cal => cal.id);
    
    // Build the query for counting events
    let query = supabaseClient
      .from(DB.EVENTS)
      .select('calendar_id', { count: 'exact', head: true })
      .in('calendar_id', calendarIds);

    // Add date filtering if needed
    if (!includeHistorical) {
      const filterDate = startDate || new Date().toISOString();
      query = query.gte('end_time', filterDate);
    }

    // Get total count across all calendars
    const { count: totalCount, error: countError } = await query;

    if (countError) {
      throw new Error(`Error getting total count: ${countError.message}`);
    }

    // Get individual calendar counts
    const calendarCounts = await Promise.all(
      calendarIds.map(async (calendarId) => {
        let calQuery = supabaseClient
          .from(DB.EVENTS)
          .select('*', { count: 'exact', head: true })
          .eq('calendar_id', calendarId);

        if (!includeHistorical) {
          const filterDate = startDate || new Date().toISOString();
          calQuery = calQuery.gte('end_time', filterDate);
        }

        const { count, error } = await calQuery;

        if (error) {
          logError(
            'Error getting calendar count',
            { calendarId, error },
            logtail
          );
          return { calendar_id: calendarId, event_count: 0 };
        }

        return { calendar_id: calendarId, event_count: count || 0 };
      })
    );

    return {
      total_events: totalCount || 0,
      calendar_counts: calendarCounts
    };
  } catch (error) {
    logError(
      'Error getting event counts',
      { 
        error: error instanceof Error ? error.message : 'Unknown error',
        integrationId 
      },
      logtail
    );
    return null;
  }
}

export async function getEventsByEntity(
  supabaseClient: AttroveSupabaseClient,
  entityId: string,
  startDate: Date,
  limit: number,
  logtail?: Logtail
): Promise<EventSummary[]> {
  try {
    const { data, error } = await supabaseClient.rpc('get_events_by_entity', { 
      p_entity_id: entityId,
      p_start_date: startDate.toISOString(),
      p_limit: limit
    });

    if (error) throw error;

    if (!Array.isArray(data)) {
      throw new Error('Unexpected data format returned from get_events_by_entity');
    }

    // logInfo("[getEventsByEntity] Successfully fetched events", { 
    //   entityId,
    //   count: data.length,
    //   startDate: startDate.toISOString()
    // }, logtail);

    return data.map(event => ({
      id: event.id,
      title: event.title,
      start_time: new Date(event.start_time),
      end_time: new Date(event.end_time)
    }));
  } catch (error) {
    logError("[getEventsByEntity] Error fetching events", { 
      error: error instanceof Error ? error.message : String(error),
      entityId,
      startDate: startDate.toISOString()
    }, logtail);
    throw error;
  }
}