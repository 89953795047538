import { type AttroveSupabaseClient, DB } from "@attrove/service-supabase";
import { logError, log } from "@attrove/util-logs";
import type { StripeService } from "@attrove/util-stripe";
import { 
  SUBSCRIPTION_TIERS,
  type SubscriptionTier,
  type SubscriptionStatus,
  type UserSubscriptionStatus,
  type SubscriptionLimits,
  type SubscriptionPrice,
  isSubscriptionTier
} from "@attrove/shared-types";
import type {
  CustomerRecord,
  SubscriptionRecord,
  ProductRecord,
  PriceRecord,
  SubscriptionWithPrice,
  SubscriptionChange,
  PriceMetadata,
  PriceType,
  PriceInterval,
  CreateCustomerInput,
  CreateSubscriptionInput,
  CreateProductInput,
  CreatePriceInput,
} from "../types/stripe";
import { Json } from "./../types/supabase-schema-public-types";
import type { AuthUser } from '@supabase/supabase-js';

// Add type for valid active subscription statuses
export const ACTIVE_SUBSCRIPTION_STATUSES = ["active", "trialing"] as const;
export type ActiveSubscriptionStatus = (typeof ACTIVE_SUBSCRIPTION_STATUSES)[number];

// Customer syncing and creation
export interface SyncCustomersOptions {
  batchSize?: number;
  dryRun?: boolean;
}

export interface SyncResults {
  processed: number;
  created: number;
  errors: number;
}

export interface StripeUserWithEmail {
  id: string;
  email: string;
}

// Type guard for SubscriptionStatus
const isSubscriptionStatus = (status: string | null): status is SubscriptionStatus => {
  if (!status) return false;
  return ["trialing", "active", "canceled", "incomplete", "incomplete_expired", "past_due", "unpaid", "paused"].includes(status);
};

const isPriceType = (type: string | null): type is PriceType => {
  if (!type) return false;
  return ["one_time", "recurring"].includes(type);
};

// Move type guard into the file since we need it as a value
const isPriceInterval = (interval: string | null): interval is PriceInterval => {
  if (!interval) return false;
  return ["day", "week", "month", "year"].includes(interval as PriceInterval);
};

// Helper function to check if status is active
export const isActiveSubscriptionStatus = (status: string | null): status is ActiveSubscriptionStatus => {
  return status !== null && ACTIVE_SUBSCRIPTION_STATUSES.includes(status as ActiveSubscriptionStatus);
};

export const getSubscriptionDetails = async (
  supabaseClient: AttroveSupabaseClient,
  stripeService: StripeService,
  userId: string,
): Promise<UserSubscriptionStatus | null> => {
  try {
    const { data: subscriptions, error } = await supabaseClient
      .from("subscriptions")
      .select(
        `
          *,
          prices (
            id,
            unit_amount,
            interval,
            interval_count,
            metadata
          )
        `,
      )
      .eq("user_id", userId)
      .order("created_at", { ascending: false });

    if (error) {
      logError("Database error fetching subscriptions", { error, userId });
      throw error;
    }

    if (!subscriptions || subscriptions.length === 0) {
      return null;
    }

    // Find the most recent active subscription
    const activeSubscription = subscriptions.find((sub) => (sub.status === "active" || sub.status === "trialing") && sub.prices !== null);

    if (!activeSubscription) {
      return null;
    }

    // Determine tier from price metadata
    const priceMetadata = activeSubscription.prices?.metadata as PriceMetadata | null;
    const tier: SubscriptionTier = priceMetadata?.["tier"] as SubscriptionTier || SUBSCRIPTION_TIERS.FREE;

    // Validate the tier is a known value
    const validatedTier = isSubscriptionTier(tier) ? tier : SUBSCRIPTION_TIERS.FREE;

    // Validate interval before creating the response
    const interval = activeSubscription.prices?.interval;
    const validatedInterval = interval && isPriceInterval(interval) ? interval : "month"; // Default to month if invalid

    // Create formatted response
    const formattedSubscription: UserSubscriptionStatus = {
      tier: validatedTier,
      isActive: true,
      currentPeriodEnd: activeSubscription.current_period_end,
      cancelAtPeriodEnd: activeSubscription.cancel_at_period_end || false,
      prices: activeSubscription.prices
        ? {
            unit_amount: activeSubscription.prices.unit_amount,
            interval: validatedInterval,
            interval_count: activeSubscription.prices.interval_count,
          }
        : null,
    };

    return formattedSubscription;
  } catch (error) {
    logError("Error in getSubscriptionDetails", { error, userId });
    return null;
  }
};

export const getUsageMetrics = async (
  supabaseClient: AttroveSupabaseClient,
  stripeService: StripeService,
  userId: string,
) => {
  try {
    const subscription = await getSubscriptionDetails(supabaseClient, stripeService, userId);
    if (!subscription) {
      throw new Error("No active subscription found");
    }

    // Count messages in current period
    const { count } = await supabaseClient
      .from("messages")
      .select("*", { count: "exact", head: true })
      .eq("user_id", userId)
      .gte("created_at", subscription.currentPeriodEnd);

    const limits: Record<SubscriptionTier, number> = {
      [SUBSCRIPTION_TIERS.FREE]: 100,
      [SUBSCRIPTION_TIERS.PRO]: 1000,
      [SUBSCRIPTION_TIERS.ENTERPRISE]: Infinity
    };

    return {
      used: count || 0,
      limit: limits[subscription.tier],
      periodEnd: subscription.currentPeriodEnd,
      tier: subscription.tier,
    };
  } catch (error) {
    logError("Error fetching usage metrics", { error, userId });
    return null;
  }
};

export const upsertCustomerRecord = async (
  supabaseClient: AttroveSupabaseClient,
  customerData: CreateCustomerInput,
): Promise<CustomerRecord | null> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.CUSTOMERS)
      .upsert({
        ...customerData,
        updated_at: new Date().toISOString(),
      })
      .select()
      .single();

    if (error) throw error;
    
    if (!data) return null;

    // Transform the data to match CustomerRecord type
    const customerRecord: CustomerRecord = {
      id: data.id,
      stripe_customer_id: data.stripe_customer_id,
      user_id: data.id,
      email: customerData.email, // Use the email from input data
      created_at: data.created_at,
      updated_at: data.updated_at
    };

    return customerRecord;
  } catch (error) {
    logError("Failed to upsert customer record", { error });
    return null;
  }
};

export const upsertSubscriptionRecord = async (
  supabaseClient: AttroveSupabaseClient,
  subscriptionData: CreateSubscriptionInput,
): Promise<SubscriptionRecord | null> => {
  try {
    if (subscriptionData.status && !isSubscriptionStatus(subscriptionData.status)) {
      throw new Error(`Invalid subscription status: ${subscriptionData.status}`);
    }

    const { data, error } = await supabaseClient
      .from(DB.SUBSCRIPTIONS)
      .upsert({
        ...subscriptionData,
        updated_at: new Date().toISOString(),
      })
      .select()
      .single();

    if (error) throw error;
    if (!data || !isSubscriptionStatus(data.status)) return null;

    // Transform the data to match SubscriptionRecord type
    const subscriptionRecord: SubscriptionRecord = {
      ...data,
      status: data.status,
      metadata: data.metadata as Json
    };

    return subscriptionRecord;
  } catch (error) {
    logError("Failed to upsert subscription record", { error });
    return null;
  }
};

export const updateSubscriptionStatus = async (
  supabaseClient: AttroveSupabaseClient,
  subscriptionId: string,
  status: SubscriptionStatus,
): Promise<void> => {
  try {
    if (!isSubscriptionStatus(status)) {
      throw new Error(`Invalid subscription status: ${status}`);
    }

    const { error } = await supabaseClient
      .from(DB.SUBSCRIPTIONS)
      .update({
        status,
        updated_at: new Date().toISOString(),
      })
      .eq("id", subscriptionId);

    if (error) throw error;
    log("Subscription status updated", { subscriptionId, status });
  } catch (error) {
    logError("Failed to update subscription status", { error });
    throw error;
  }
};

export const getSubscriptionByUserId = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
): Promise<SubscriptionWithPrice | null> => {
  try {
    log("Fetching active subscription for user", { userId });

    const { data, error } = await supabaseClient
      .from(DB.SUBSCRIPTIONS)
      .select(
        `
          *,
          prices!inner (
            unit_amount,
            interval,
            interval_count
          )
        `,
      )
      .eq("user_id", userId)
      // Only get active or trialing subscriptions
      .in("status", ACTIVE_SUBSCRIPTION_STATUSES)
      .order("created_at", { ascending: false })
      .limit(1)
      .single();

    if (error) {
      if (error.code === "PGRST116") {
        log("No active subscription found", { userId });
        return null;
      }
      throw error;
    }

    if (!data) {
      log("No subscription data returned", { userId });
      return null;
    }

    // Validate status before proceeding
    if (!isSubscriptionStatus(data.status)) {
      logError("Invalid subscription status", { status: data.status, userId });
      return null;
    }

    const interval = data.prices?.interval;
    const validatedInterval = interval && isPriceInterval(interval) ? interval : null;

    // Transform the data to match SubscriptionWithPrice type
    const subscription: SubscriptionWithPrice = {
      ...data,
      status: data.status, // Now safe because we validated above
      metadata: data.metadata as Json,
      prices: data.prices
        ? {
            unit_amount: data.prices.unit_amount,
            interval: validatedInterval,
            interval_count: data.prices.interval_count,
          }
        : null,
    };

    return subscription;
  } catch (error) {
    logError("Failed to get subscription by user ID", { error, userId });
    return null;
  }
};

// Add a utility function to get all subscriptions (for auditing/history)
export const getAllSubscriptionsByUserId = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
): Promise<SubscriptionWithPrice[]> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.SUBSCRIPTIONS)
      .select(
        `
            *,
            prices!inner (
              unit_amount,
              interval,
              interval_count
            )
          `,
      )
      .eq("user_id", userId)
      .order("created_at", { ascending: false });

    if (error) throw error;

    if (!data) return [];

    return data.map((subscription) => {
      const interval = subscription.prices?.interval;
      const validatedInterval = interval && isPriceInterval(interval) ? interval : null;

      // Skip invalid subscriptions
      if (!isSubscriptionStatus(subscription.status)) {
        logError("Invalid subscription status", { status: subscription.status });
        return null;
      }

      // Construct a proper SubscriptionRecord first
      const subscriptionRecord: SubscriptionRecord = {
        id: subscription.id,
        user_id: subscription.user_id,
        status: subscription.status,
        price_id: subscription.price_id || '',
        quantity: subscription.quantity || 0,
        cancel_at_period_end: subscription.cancel_at_period_end || false,
        created_at: subscription.created_at,
        current_period_start: subscription.current_period_start,
        current_period_end: subscription.current_period_end,
        ended_at: subscription.ended_at,
        cancel_at: subscription.cancel_at,
        canceled_at: subscription.canceled_at,
        trial_start: subscription.trial_start,
        trial_end: subscription.trial_end,
        metadata: subscription.metadata as Json,
        automatic_tax: subscription.automatic_tax || false,
        collection_method: subscription.collection_method || 'charge_automatically',
        days_until_due: subscription.days_until_due,
        updated_at: subscription.updated_at
      };

      // Then construct the SubscriptionWithPrice
      const subscriptionWithPrice: SubscriptionWithPrice = {
        ...subscriptionRecord,
        metadata: subscription.metadata as Json,
        prices: subscription.prices
          ? {
              unit_amount: subscription.prices.unit_amount,
              interval: validatedInterval,
              interval_count: subscription.prices.interval_count,
            }
          : null,
      };

      return subscriptionWithPrice;
    }).filter((sub): sub is SubscriptionWithPrice => sub !== null);
  } catch (error) {
    logError("Failed to get all subscriptions by user ID", { error, userId });
    return [];
  }
};

export const getCustomerByUserId = async (
  supabaseClient: AttroveSupabaseClient,
  userId: string,
): Promise<CustomerRecord | null> => {
  try {
    // First get the customer record
    const { data: customerData, error: customerError } = await supabaseClient
      .from(DB.CUSTOMERS)
      .select("*")
      .eq("id", userId)
      .single();

    if (customerError) {
      if (customerError.code === "PGRST116") return null;
      throw customerError;
    }

    if (!customerData) return null;

    // Get the user's email from auth.users
    const { data: userData, error: userError } = await supabaseClient.auth.admin.getUserById(userId);
    
    if (userError) {
      throw userError;
    }

    if (!userData?.user?.email) {
      throw new Error(`No email found for user ${userId}`);
    }

    // Transform the data to match CustomerRecord type
    const customerRecord: CustomerRecord = {
      id: customerData.id,
      stripe_customer_id: customerData.stripe_customer_id,
      user_id: customerData.id, // user_id is the same as id for customers
      email: userData.user.email,
      created_at: customerData.created_at,
      updated_at: customerData.updated_at
    };

    return customerRecord;
  } catch (error) {
    logError("Failed to get customer by user ID", { error, userId });
    return null;
  }
};

export const upsertProductRecord = async (
  supabaseClient: AttroveSupabaseClient,
  productData: CreateProductInput,
): Promise<ProductRecord | null> => {
  try {
    const { data, error } = await supabaseClient
      .from(DB.PRODUCTS)
      .upsert({
        ...productData,
        updated_at: new Date().toISOString(),
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    logError("Failed to upsert product record", { error });
    return null;
  }
};

export const upsertPriceRecord = async (
  supabaseClient: AttroveSupabaseClient,
  priceData: CreatePriceInput,
): Promise<PriceRecord | null> => {
  try {
    if (priceData["type"] && !isPriceType(priceData["type"])) {
      throw new Error(`Invalid price type: ${priceData["type"]}`);
    }

    const { data, error } = await supabaseClient
      .from(DB.PRICES)
      .upsert({
        ...priceData,
        updated_at: new Date().toISOString(),
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    logError("Failed to upsert price record", { error });
    return null;
  }
};

export const getSubscriptionUserId = async (
  supabaseClient: AttroveSupabaseClient,
  subscriptionId: string,
): Promise<string | null> => {
  try {
    const { data, error } = await supabaseClient.from(DB.SUBSCRIPTIONS).select("user_id").eq("id", subscriptionId).single();

    if (error) {
      if (error.code === "PGRST116") return null;
      throw error;
    }

    return data?.user_id ?? null;
  } catch (error) {
    logError("Failed to get subscription user_id", { error, subscriptionId });
    return null;
  }
};

export const deactivateSubscription = async (
  supabaseClient: AttroveSupabaseClient,
  subscriptionId: string,
  endDate: string,
): Promise<void> => {
  try {
    await supabaseClient
      .from(DB.SUBSCRIPTIONS)
      .update({
        status: "canceled",
        ended_at: endDate,
        updated_at: new Date().toISOString(),
      })
      .eq("id", subscriptionId);
  } catch (error) {
    logError("Failed to deactivate subscription", { error, subscriptionId });
    throw error;
  }
};

export const handleSubscriptionChange = async (
  supabaseClient: AttroveSupabaseClient,
  changeData: SubscriptionChange,
): Promise<SubscriptionRecord | null> => {
  try {
    if (!isSubscriptionStatus(changeData.status)) {
      throw new Error(`Invalid subscription status: ${changeData.status}`);
    }

    const { data, error } = await supabaseClient
      .from(DB.SUBSCRIPTIONS)
      .upsert({
        id: changeData.newSubscriptionId,
        user_id: changeData.userId,
        price_id: changeData.priceId,
        status: changeData.status,
        quantity: changeData.quantity,
        cancel_at_period_end: changeData.cancel_at_period_end,
        created: changeData.created,
        current_period_start: changeData.current_period_start,
        current_period_end: changeData.current_period_end,
        ended_at: changeData.ended_at,
        cancel_at: changeData.cancel_at,
        canceled_at: changeData.cancelled_at,
        trial_start: changeData.trial_start,
        trial_end: changeData.trial_end,
        collection_method: changeData.collection_method,
        days_until_due: changeData.days_until_due,
        metadata: changeData.metadata as Json,
        updated_at: new Date().toISOString(),
      })
      .select()
      .single();

    if (error) throw error;
    if (!data || !isSubscriptionStatus(data.status)) return null;

    // Transform the data to match SubscriptionRecord type
    const subscriptionRecord: SubscriptionRecord = {
      ...data,
      status: data.status,
      metadata: data.metadata as Json
    };

    return subscriptionRecord;
  } catch (error) {
    logError("Failed to handle subscription change", { error });
    return null;
  }
};

export const getUserSubscriptionStatus = async (
  supabaseClient: AttroveSupabaseClient,
  stripeService: StripeService,
  userId: string,
): Promise<UserSubscriptionStatus | null> => {
  try {
    const { data: subscriptions, error } = await supabaseClient
      .from("subscriptions")
      .select(
        `
          *,
          prices!inner (
            unit_amount,
            interval,
            interval_count
          )
        `,
      )
      .eq("user_id", userId)
      .order("created_at", { ascending: false })
      .limit(2); // Get latest 2 to handle upgrade/downgrade transitions

    if (error) throw error;

    // No subscriptions found
    if (!subscriptions || subscriptions.length === 0) {
      return null;
    }

    // Get the most recent subscription that's not canceled
    const activeSubscription = subscriptions.find((sub) => sub.status !== "canceled" && sub.status !== "incomplete_expired");

    if (!activeSubscription) {
      return null;
    }

    const priceIdPro = stripeService.getPriceIdForTier("pro");
    const isPro = activeSubscription.price_id === priceIdPro;

    return {
      tier: isPro ? "pro" : "free",
      isActive: activeSubscription.status === "active" || activeSubscription.status === "trialing",
      currentPeriodEnd: activeSubscription.current_period_end,
      cancelAtPeriodEnd: activeSubscription.cancel_at_period_end || false,
    };
  } catch (error) {
    logError("Failed to get user subscription status", { error, userId });
    return null;
  }
};

// Helper for checking subscription features/limits
export const getSubscriptionLimits = (tier: SubscriptionTier): SubscriptionLimits => {
  const limits: Record<SubscriptionTier, SubscriptionLimits> = {
    free: {
      maxIntegrations: 2,
      maxMessages: 100,
      // retentionDays: 7
    },
    pro: {
      maxIntegrations: Infinity,
      maxMessages: Infinity,
      // retentionDays: 30
    },
    enterprise: {
      maxIntegrations: Infinity,
      maxMessages: Infinity,
      // retentionDays: 365
    }
  };

  return limits[tier] || limits.free;
};

export const findUsersWithoutStripeCustomers = async (
  supabaseClient: AttroveSupabaseClient,
  lastProcessedId: string | null,
  batchSize: number,
): Promise<StripeUserWithEmail[] | null> => {
  try {
    // First get the auth user list
    const { data: authData, error: authError } = await supabaseClient
      .auth.admin.listUsers({
        page: 1,
        perPage: batchSize
      });

    if (authError) {
      logError('Error fetching auth users', { error: authError });
      throw authError;
    }

    if (!authData?.users || authData.users.length === 0) {
      return null;
    }

    // Type assertion for auth users
    const authUsers = authData.users as unknown as AuthUser[];

    // Get existing customers
    const { data: existingCustomers, error: customerError } = await supabaseClient
      .from(DB.CUSTOMERS)
      .select('id');

    if (customerError) {
      logError('Error fetching existing customers', { error: customerError });
      throw customerError;
    }

    // Create a Set of existing customer IDs for efficient lookup
    const existingCustomerIds = new Set(existingCustomers?.map(c => c.id) || []);

    // Filter users who don't have customer records
    const usersWithoutCustomers = authUsers
      .filter(user => {
        // Ensure required fields exist
        if (!user?.id || !user?.email) {
          log('Skipping user with missing required fields', { userId: user?.id, hasEmail: !!user?.email });
          return false;
        }
        return !existingCustomerIds.has(user.id);
      })
      .map(user => ({
        id: user.id,
        email: user.email!
      }));

    // Handle pagination
    if (lastProcessedId) {
      const lastProcessedIndex = usersWithoutCustomers.findIndex(u => u.id === lastProcessedId);
      if (lastProcessedIndex !== -1) {
        usersWithoutCustomers.splice(0, lastProcessedIndex + 1);
      }
    }

    log('Found users without Stripe customers', {
      count: usersWithoutCustomers.length,
      totalExistingCustomers: existingCustomerIds.size
    });

    return usersWithoutCustomers;

  } catch (error) {
    logError('Failed to fetch users without Stripe customers', { error });
    throw error;
  }
};

export const upsertCustomerRecordsBatch = async (
  supabaseClient: AttroveSupabaseClient,
  customerRecords: Array<{ userId: string; stripeCustomerId: string }>,
): Promise<void> => {
  try {
    const { error } = await supabaseClient
      .from(DB.CUSTOMERS)
      .upsert(
        customerRecords.map(record => ({
          id: record.userId,
          stripe_customer_id: record.stripeCustomerId,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }))
      );

    if (error) throw error;
    log('Upserted customer records batch', { count: customerRecords.length });
  } catch (error) {
    logError('Failed to upsert customer records batch', { error });
    throw error;
  }
};

export const syncStripeCustomers = async (
  supabaseClient: AttroveSupabaseClient,
  stripeService: StripeService,
  options: SyncCustomersOptions = {},
): Promise<SyncResults> => {
  const { batchSize = 100, dryRun = false } = options;
  let processedCount = 0;
  let createdCount = 0;
  let errorCount = 0;
  let lastProcessedId: string | null = null;

  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // Get batch of users without Stripe customers
      const users = await findUsersWithoutStripeCustomers(
        supabaseClient, 
        lastProcessedId, 
        batchSize
      );
      
      if (!users || users.length === 0) break;

      log(
        `Processing batch of ${users.length} users${dryRun ? ' (DRY RUN)' : ''}`,
        { batchSize, processedCount }
      );

      if (!dryRun) {
        // Create Stripe customers
        const results = await Promise.all(
          users.map(async (user) => {
            try {
              if (!user.email) {
                throw new Error('Email is required for Stripe customer creation');
              }

              const customer = await stripeService.createCustomer({
                email: user.email,
                metadata: {
                  user_id: user.id
                }
              });

              return {
                userId: user.id,
                stripeCustomerId: customer.id,
                success: true as const
              };
            } catch (error) {
              logError(
                'Failed to create Stripe customer',
                { userId: user.id, error }
              );
              return {
                userId: user.id,
                stripeCustomerId: '',
                success: false as const
              };
            }
          })
        );

        // Filter successful creations and update database
        const successfulCreations = results.filter((r): r is typeof r & { success: true } => r.success);
        if (successfulCreations.length > 0) {
          await upsertCustomerRecordsBatch(
            supabaseClient,
            successfulCreations.map(r => ({
              userId: r.userId,
              stripeCustomerId: r.stripeCustomerId
            })),
          );
          createdCount += successfulCreations.length;
        }

        errorCount += results.filter(r => !r.success).length;
      }

      processedCount += users.length;
      lastProcessedId = users[users.length - 1].id;

      log(
        'Batch processing complete',
        {
          batchProcessed: users.length,
          totalProcessed: processedCount,
          created: createdCount,
          errors: errorCount,
          dryRun
        }
      );
    }

    log(
      'Customer sync complete',
      {
        totalProcessed: processedCount,
        totalCreated: createdCount,
        totalErrors: errorCount,
        dryRun
      }
    );

    return { processed: processedCount, created: createdCount, errors: errorCount };

  } catch (error) {
    logError('Failed to sync customers', { error });
    throw error;
  }
};