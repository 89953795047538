import { EventRow } from "@attrove/service-supabase";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { parseOneAddress } from "email-addresses";
import { z } from "zod";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

// Extend dayjs with the advanced format plugin
dayjs.extend(advancedFormat);

export const toTimeAgo = (date: string): string => {
  const now = dayjs();
  const inputDate = dayjs(date);

  const diffInDays = now.diff(inputDate, "day");

  if (diffInDays === 0) {
    return inputDate.fromNow(); // Returns relative time for today (e.g., "2 hours ago", "5 minutes ago")
  } else if (diffInDays === 1) {
    return "Yesterday";
  } else {
    return inputDate.format("MMMM D"); // Returns date in "Month Day" format, no year
  }
};

export const toMonthDay = (dateString: string): string => {
  return dayjs(dateString).format("MMMM Do");
};

export const toFullDate = (dateString: string): string => {
  return dayjs(dateString).format("dddd, MMMM Do YYYY");
};

export const toMonthDayNumeric = (dateString: string): string => {
  return dayjs(dateString).format("M/D");
};

export const toTime = (dateTime: string): string => {
  return dayjs(dateTime).format("h:mm A");
};

export const parseName = (input: string) => {
  return parseOneAddress(input);
};

export const parseTag = (tag: string): string => {
  return tag
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getInitials = (name: string) => {
  // Check for null or empty string
  if (!name) return "";
  
  const words = name.trim().split(/\s+/);
  if (words.length === 0) return "";
  if (words.length === 1) return words[0].slice(0, 2).toUpperCase();
  return (words[0][0] + words[words.length - 1][0]).toUpperCase();
};

export function getOrganizer(attendees: EventRow["attendees"], currentEmail: string): string {
  const organizer = attendees?.find((attendee) => attendee.organizer);
  return !organizer?.email ? currentEmail : organizer.email;
}

const emailSchema = z.string().email();

export function getEmailFromString(input: string): string | null {
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  const matches = input.match(emailRegex);

  if (matches) {
    for (const match of matches) {
      try {
        const validatedEmail = emailSchema.parse(match);
        return validatedEmail;
      } catch (error) {
        continue;
      }
    }
  }

  return null;
}
