// import { SidebarNavItem } from '../index';
import { cn, Icons, Logo, Avatar, AvatarFallback, AvatarImage } from "@attrove/ui-shadcn";
import { useSubscriptionFeatures } from '../../../context/SubscriptionContext';
import { useSession } from '../../../context/SessionContext';
import { getInitials } from '../../../lib/utils';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@attrove/ui-shadcn";
import { CircleUser, ChevronDown } from "lucide-react";
// import { SignOut } from '../config/auth-helpers/server';
import { Link } from "react-router-dom";
import { PremiumBadge } from "../../premium-badge";
import { SUBSCRIPTION_TIERS } from '@attrove/shared-types';

type DashboardNavProps = {
  items: any[];
  onLogout: () => void;
  className?: string;
};

export function DashboardNav({ items, onLogout }: DashboardNavProps) {
  const pathname = window.location.pathname;
  const { isPro, isEnterprise } = useSubscriptionFeatures();
  const { user } = useSession();

  // Determine display name with fallback hierarchy
  const displayName = user.preferred_name || user.full_name || user.email;
  const secondaryText = (user.preferred_name || user.full_name) && user.email;

  return (
    <aside className="hidden min-w-[220px] flex-col md:flex relative">
      <div className="p-5 flex mb-10">
        <Logo />
      </div>
      <div className="px-2 flex-grow">
        <div className="w-full px-1">
          {items.map((item, index) => (
            <div key={index} className={cn("pb-8")}>
              <h4 className="mb-1 rounded-md px-2 py-1 text-s  uppercase">{item.title}</h4>
              {item.items ? <DashboardSidebarNavItems items={item.items} pathname={pathname} /> : null}
            </div>
          ))}
      <div className="absolute bottom-3 left-0 w-full px-3">
      {/* <ThemeToggle className="rounded-full" /> */}
            <div className="ml-2">
            <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button 
              variant="secondary" 
              size="sm" 
              className="w-full px-3 py-2 justify-start text-left group min-h-[48px] hover:bg-accent transition-colors"
              childrenClassName="min-w-0"
            >
              <div className="flex items-center w-full">
                <div className="relative">
                  <Avatar className="h-8 w-8 ring-2 ring-background">
                    {user.avatar_url ? (
                      <AvatarImage src={user.avatar_url} alt={displayName} />
                    ) : (
                      <AvatarFallback className="bg-primary/10 text-primary">
                        {getInitials(displayName)}
                      </AvatarFallback>
                    )}
                  </Avatar>
                  {(isPro || isEnterprise) && (
                    <div className="absolute -bottom-1.5 -right-1.5">
                      <PremiumBadge 
                        tier={isEnterprise ? SUBSCRIPTION_TIERS.ENTERPRISE : SUBSCRIPTION_TIERS.PRO} 
                        className="scale-90 origin-bottom-right shadow-sm" 
                      />
                    </div>
                  )}
                </div>
                <div className="ml-2 flex-1 min-w-0">
                  <div className="truncate text-sm font-medium">{displayName}</div>
                  {secondaryText && (
                    <div className="truncate text-xs text-muted-foreground">
                      {user.email}
                    </div>
                  )}
                </div>
                <ChevronDown className="h-4 w-4 text-muted-foreground/70 transition-transform duration-200 group-data-[state=open]:rotate-180" />
              </div>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem asChild>
              <Link to="/settings/profile">Settings</Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
            <Link to="/settings/billing">Billing</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={onLogout}>Sign Out</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

type DashboardSidebarNavItemsProps = {
  items: any[];
  pathname: string | null;
};

export function DashboardSidebarNavItems({ items, pathname }: DashboardSidebarNavItemsProps) {
  return (
    <nav className="grid items-start gap-2">
      {items.map((item: any, index) => {
        const iconKey = item.icon || "arrowRight";
        const Icon = Icons[iconKey] || Icons.arrowRight;
        return (
          item.href && (
            <a
              key={index}
              href={item.disabled ? "/" : item.href}
              className={cn(
                "group flex items-center rounded-md px-3 py-2 text-sm font-medium hover:bg-accent hover:text-accent-foreground",
                pathname === item.href ? "bg-accent" : "transparent",
                item.disabled && "cursor-not-allowed opacity-80",
              )}
            >
              <Icon className="mr-2 h-4 w-4" />
              <span>{item.title}</span>
            </a>
          )
        );
      })}
    </nav>
  );
}
