// useSubscription.tsx
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { AxiosClientContext } from "@attrove/attrove-ui/main";
import { 
  SUBSCRIPTION_TIERS,
  type SubscriptionTier,
  type UserSubscriptionStatus
} from '@attrove/shared-types';
import { getSubscriptionLimits } from '@attrove/service-supabase';
import axios from 'axios';

const DEFAULT_SUBSCRIPTION: UserSubscriptionStatus = {
  tier: SUBSCRIPTION_TIERS.FREE,
  isActive: true,
  currentPeriodEnd: null,
  cancelAtPeriodEnd: false,
  prices: null
};

const DEFAULT_USAGE = {
  used: 0,
  limit: getSubscriptionLimits(SUBSCRIPTION_TIERS.FREE).maxIntegrations,
  periodEnd: null,
  tier: SUBSCRIPTION_TIERS.FREE
};

export interface SubscriptionResponse {
  subscription: UserSubscriptionStatus | null;
  usage: {
    used: number;
    limit: number;
    periodEnd: string;
    tier: SubscriptionTier;
  } | null;
}

export const useSubscription = () => {
  const axiosClient = useContext(AxiosClientContext);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['subscription'],
    queryFn: async () => {
      try {
        const { data } = await axiosClient.get<SubscriptionResponse>('/api/stripe/subscription');
        return data;
      } catch (error) {
        // If API is down, return default free tier data instead of failing
        if (axios.isAxiosError(error) && (!error.response || error.response.status >= 500)) {
          return {
            subscription: DEFAULT_SUBSCRIPTION,
            usage: DEFAULT_USAGE
          };
        }
        throw error;
      }
    },
    staleTime: 1000 * 60 * 15, // Cache for 15 minutes
    gcTime: 1000 * 60 * 60, // Keep in cache for 1 hour
    refetchOnWindowFocus: false,
    refetchOnMount: false, // Don't refetch on component mount
    refetchOnReconnect: true, // But do refetch when internet connection is restored
    retry: (failureCount, error) => {
      // Only retry on network/server errors, not on 4xx client errors
      if (axios.isAxiosError(error) && error.response?.status && error.response.status < 500) {
        return false;
      }
      return failureCount < 3;
    },
    // Fallback to free tier if query fails
    placeholderData: {
      subscription: DEFAULT_SUBSCRIPTION,
      usage: DEFAULT_USAGE
    }
  });

  const subscription = data?.subscription ?? DEFAULT_SUBSCRIPTION;
  const usage = data?.usage ?? DEFAULT_USAGE;
  
  // Consider a user on Pro tier only if they have an active subscription with pro tier
  const isPro = subscription?.tier === SUBSCRIPTION_TIERS.PRO && subscription?.isActive;
  const isEnterprise = subscription?.tier === (SUBSCRIPTION_TIERS.ENTERPRISE as SubscriptionTier) && subscription?.isActive;
  
  const limits = getSubscriptionLimits(subscription?.tier ?? SUBSCRIPTION_TIERS.FREE);

  return {
    subscription,
    usage,
    isLoading,
    error,
    isPro,
    isEnterprise,
    limits,
    refetch,
    currentTier: subscription?.tier ?? SUBSCRIPTION_TIERS.FREE,
    isFeatureAvailable: (feature: string) => {
      switch (feature) {
        case 'prioritySupport':
          return isPro || isEnterprise;
        case 'customIntegrations':
          return isEnterprise;
        default:
          return true; // Free features
      }
    }
  };
};